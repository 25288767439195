import React, { Component } from 'react';

class Numb extends Component {
  constructor(props) {
    super(props);
    this.state = { realValue: null };
  }
  formatter(e) {
    const { num_multiplier } = this.props.config;
    this.setState({
      realValue: parseInt(e.target.value) * (1 / (num_multiplier || 1)),
    });
  }
  render() {
    const { config, data } = this.props;
    const { realValue } = this.state;
    const {
      type,
      label,
      key,
      required,
      className,
      num_multiplier,
      default_value,
    } = config;
    const value = data != null && data[key] != null ? data[key] : 0;
    return [
      <label key="1">{label}</label>,
      <input
        key="2"
        name={key}
        type={type}
        step={num_multiplier || 1}
        defaultValue={value * (num_multiplier || 1) || default_value}
        required={required}
        className={'form-control ' + className}
        onChange={e => this.formatter(e)}
      />,
      <input
        key="3"
        type="hidden"
        name={key}
        defaultValue={(realValue !== null ? realValue : value) || default_value}
      />,
    ];
  }
}
export default Numb;
