import React, { Component } from 'react';
import { Api, FormToObject } from '../modules/Service';
import Cache from '../modules/Cache';
import { GetArgs } from '../modules/Helper';
import Alert from '../components/Alert';
import Logo from '../components/Logo';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: '',
      submitLoading: false,
    };
  }
  submitForm(ev) {
    ev.preventDefault();
    this.setState({
      submitLoading: true,
    });
    const formData = FormToObject($(ev.target).serializeArray());
    Api('/auth/login', JSON.stringify(formData), 'POST', 'text')
      .done(() => {
        const autoClick = GetArgs('auto_click') ? '&auto_click=edit' : '';
        // return false;
        Api('/auth/ping', {}, 'GET').done(resp => {
          $('#app-body').addClass('loaded');
          Cache.set('userLogin', resp);
          this.props.history.push(GetArgs('redirect') + autoClick);
        });
      })
      .catch(xhr => {
        console.log(xhr);
        this.setState({
          submitLoading: false,
          msg: (
            <Alert
              msg={xhr.getResponseHeader('X-TBMS-Message')}
              type="danger"
              closeHandler={() => {
                this.setState({ msg: '' });
              }}
            />
          ),
        });
      });
  }
  render() {
    const { msg, submitLoading } = this.state;
    return (
      <div className="login-box">
        <div className="login-logo">
          <Logo />
        </div>
        <div className="login-box-body">
          {Boolean(msg) && msg}
          <p className="login-box-msg">Sign in to start your session</p>
          <form
            method="post"
            onSubmit={e => {
              this.submitForm(e);
            }}
          >
            <div className="form-group has-feedback">
              <span className="glyphicon glyphicon-user form-control-feedback" />
              <input
                type="text"
                name="username"
                className="form-control"
                placeholder="Username"
              />
            </div>
            <div className="form-group has-feedback">
              <span className="glyphicon glyphicon-lock form-control-feedback" />
              <input
                type="password"
                name="password"
                className="form-control"
                placeholder="Password"
              />
            </div>
            <div className="row">
              <div className="col-xs-8">
                {/*<a href="#">I forgot my password</a>*/}
              </div>
              <div className="col-xs-4">
                <button
                  type="submit"
                  className={
                    'btn btn-primary btn-block btn-flat ' +
                    (submitLoading ? 'disabled' : '')
                  }
                >
                  {submitLoading ? 'Loading...' : 'Sign In'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default Login;
