import React, { Component } from 'react';
import { UserPermission, YesNo, UniqueId } from '../../modules/Helper';
import Box from '../../components/Box';
import MUser from '../../models/MUser';
import { BtnAdd, BtnEdit, BtnDelete, BtnSetPass } from '../../components/Btn';
import { Datatable } from '../../components/Datatable';
import { Modal } from '../../components/Modal';
import { UserForm } from './UserForm';
import { SetPassForm } from './SetPassForm';
import { GetModal, ShowModal, DatatableDelRow } from '../../modules/Event';
import moment from 'moment';
class Users extends Component {
  constructor(props) {
    super(props);
    var userData = localStorage.getItem('userLogin');
    var user = JSON.parse(userData);
    var userlevel = user.permission_level;
    this.state = {
      uniqueId: UniqueId(),
      loading: true,
      data: null,
      modalId: 'mdl-form',
      modalOpened: false,
      editData: null,
      setPassModalOpened: false,
      setPassModalId: 'mdl-set-pass',
      userlevel: userlevel,
    };
  }
  renderData() {
    MUser.list()
      .done(resp => {
        resp = Array.isArray(resp) ? resp : [resp];
        this.setState({
          uniqueId: UniqueId(),
          loading: false,
          data: resp,
        });
      })
      .catch(xhr => {
        this.setState({
          uniqueId: UniqueId(),
          loading: false,
          data: [],
        });
      });
  }
  componentDidMount() {
    const { modalId, setPassModalId } = this.state;
    this.renderData();
    GetModal(modalId).on('hidden.bs.modal', () => {
      this.setState({ modalOpened: false });
    });
    GetModal(setPassModalId).on('hidden.bs.modal', () => {
      this.setState({ setPassModalOpened: false });
    });
  }
  addItem(ev) {
    ev.preventDefault();
    const { modalId } = this.state;
    this.setState({ editData: null, modalOpened: true });
    ShowModal(modalId);
  }
  editItem(ev, id) {
    ev.preventDefault();
    const { modalId } = this.state;
    const elBtn = $(ev.target);
    elBtn.addClass('loading');
    MUser.get(id)
      .done(resp => {
        this.setState({
          modalOpened: true,
          editData: resp,
        });
        elBtn.removeClass('loading');
        ShowModal(modalId);
      })
      .catch(xhr => {
        elBtn.removeClass('loading');
        alert(
          'Edit error #' + id + '.' + xhr.getResponseHeader('X-TBMS-Message'),
        );
      });
  }
  setPass(ev, id) {
    ev.preventDefault();
    const { setPassModalId } = this.state;
    const elBtn = $(ev.target);
    elBtn.addClass('loading');
    MUser.get(id)
      .done(resp => {
        this.setState({
          setPassModalOpened: true,
          editData: resp,
        });
        elBtn.removeClass('loading');
        ShowModal(setPassModalId);
      })
      .catch(xhr => {
        elBtn.removeClass('loading');
        alert(
          'Set password error #' +
            id +
            '.' +
            xhr.getResponseHeader('X-TBMS-Message'),
        );
      });
  }
  deleteItem(ev, id) {
    ev.preventDefault();
    if (window.confirm('Delete User #' + id + ' ?')) {
      const elBtn = $(ev.target);
      elBtn.addClass('loading');
      MUser.delete(id)
        .done(() => {
          elBtn.removeClass('loading');
          const dtTbl = elBtn.closest('.tgt-datatable').attr('id');
          DatatableDelRow(dtTbl, id);
        })
        .catch(xhr => {
          elBtn.removeClass('loading');
          alert(xhr.getResponseHeader('X-TBMS-Message'));
        });
    }
  }
  render() {
    const {
      uniqueId,
      loading,
      data,
      modalId,
      modalOpened,
      setPassModalId,
      setPassModalOpened,
      editData,
    } = this.state;
    if (this.state.userlevel > 9) {
      const thead = [
        'ID',
        'Username',
        'Phone',
        'Email',
        'Permission',
        'Enabled',
        'Last Login',
        'Action',
      ];
      return [
        <Box
          key={uniqueId}
          title="User lists"
          rightBox={<BtnAdd clickHandler={e => this.addItem(e)} />}
        >
          <Datatable loading={loading} thead={thead}>
            {data != null &&
              !loading &&
              data.map((item, idx) => {
                const {
                  id,
                  username,
                  phonenumber,
                  email,
                  permission_level,
                  enabled,
                  last_login,
                } = item;
                var Time = moment(last_login).format('YYYY-MM-DD hh:mm:ss A');
                var Time2 = moment(last_login).format('YYYY-MM-DD hh:mm A');

                return (
                  <tr key={idx} id={'dt-item-' + id}>
                    <td style={{ width: 50 }}>{id}</td>
                    <td>{username}</td>
                    <td>{phonenumber}</td>
                    <td>{email}</td>
                    <td>{UserPermission(permission_level)}</td>
                    <td>{YesNo(enabled)}</td>
                    <td className="hidden_time">
                      <span>{Time}</span>
                      {Time2}
                    </td>
                    <td className="box-action" style={{ width: 120 }}>
                      <BtnSetPass clickHandler={e => this.setPass(e, id)} />
                      <BtnEdit clickHandler={e => this.editItem(e, id)} />
                      <BtnDelete clickHandler={e => this.deleteItem(e, id)} />
                    </td>
                  </tr>
                );
              })}
          </Datatable>
        </Box>,
        <Modal
          key="1"
          id={modalId}
          title={editData != null ? 'Edit User #' + editData.id : 'Add User'}
          size="sm"
        >
          {modalOpened && (
            <UserForm
              modalId={modalId}
              data={editData}
              RefreshParentData={() => this.renderData()}
            />
          )}
        </Modal>,
        <Modal key="2" id={setPassModalId} title="Set Password" size="sm">
          {setPassModalOpened && (
            <SetPassForm modalId={setPassModalId} data={editData} />
          )}
        </Modal>,
      ];
    } else {
      $('ul.sidebar-menu.tree > li')
        .find('i.fa.fa-user-o')
        .parent()
        .remove();
      return [
        <Box key={uniqueId} title="User lists">
          <h4> Permission denied. </h4>
        </Box>,
      ];
    }
  }
}
export default Users;
