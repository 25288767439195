import React, { Component } from 'react';
import { PermissionLevels } from '../../modules/Helper';
import MUser from '../../models/MUser';
import Alert from '../../components/Alert';
import { RenderForm } from '../../components/Form';
import { FormToObject } from '../../modules/Service';
import { GetModal } from '../../modules/Event';
export class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: '',
      submitLoading: false,
    };
  }
  submitForm(ev) {
    ev.preventDefault();
    const { modalId, RefreshParentData } = this.props;
    this.setState({ submitLoading: true });
    const formData = FormToObject($(ev.target).serializeArray());
    if (!formData.id) {
      MUser.add(formData)
        .done(resp => {
          GetModal(modalId).scrollTop(0);
          RefreshParentData();
          this.setState({
            submitLoading: false,
            msg: (
              <Alert
                msg="User added successfully."
                type="success"
                closeHandler={() => {
                  this.setState({ msg: '' });
                }}
              />
            ),
          });
        })
        .catch(xhr => this.catchError(xhr));
    } else {
      MUser.update(formData)
        .done(resp => {
          GetModal(modalId).scrollTop(0);
          RefreshParentData();
          this.setState({
            submitLoading: false,
            msg: (
              <Alert
                msg="User updated successfully."
                type="info"
                closeHandler={() => {
                  this.setState({ msg: '' });
                }}
              />
            ),
          });
        })
        .catch(xhr => this.catchError(xhr));
    }
  }
  catchError(xhr) {
    const { modalId } = this.props;
    GetModal(modalId).scrollTop(0);
    this.setState({
      submitLoading: false,
      msg: (
        <Alert
          msg={xhr.getResponseHeader('X-TBMS-Message')}
          type="danger"
          closeHandler={() => {
            this.setState({ msg: '' });
          }}
        />
      ),
    });
  }
  render() {
    const { submitLoading, msg } = this.state;
    const { data } = this.props;
    const fields = [
      {
        'col-sm-12 1': [
          {
            key: 'id',
            type: 'hidden',
          },
          {
            key: 'username',
            type: 'username',
            label: 'Username',
          },
          {
            key: 'user_group',
            type: 'select2js',
            label: 'User Group',
            lookup_url: '/user_group/list',
            lookup_key: 'id',
            lookup_format: '{name}',
          },
          {
            key: 'email',
            type: 'email',
            label: 'Email',
          },
          {
            key: 'fullname',
            type: 'text',
            label: 'Full Name',
          },
          {
            key: 'permission_level',
            type: 'select',
            label: 'Permission',
            options: PermissionLevels(),
          },
          {
            key: 'default_location_id',
            type: 'select2js',
            label: 'Default Location',
            lookup_url: '/location/list',
            lookup_key: 'id',
            lookup_format: '{name}',
          },
          {
            key: 'phonenumber',
            type: 'text',
            label: 'Phone Number',
          },
          {
            key: 'enabled',
            type: 'checkbox',
            label: 'Enabled',
          },
        ],
      },
    ];
    return (
      <RenderForm
        data={data}
        msg={msg}
        config={fields}
        submitLoading={submitLoading}
        submitHandler={e => this.submitForm(e)}
      />
    );
  }
}
