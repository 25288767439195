import MContract from './MContract';
import Cache from '../modules/Cache';

const MCityRental = {
  ...MContract,

  title: 'City Rental',

  validListFilters: ['start_location_id', 'bike_type_id', 'affiliate_id'],

  modifyListParams(search) {
    const query = new URLSearchParams({
      ...Object.fromEntries(search),
      city_rental: 'true',
      by: 'renew',
      order: 'asc',
    });

    const { default_location_id } = Cache.get('userLogin') || {};

    if (!query.get('start_location_id') && default_location_id) {
      query.set('start_location_id', default_location_id);
    } else if (query.get('start_location_id') === 'all') {
      query.delete('start_location_id');
    }

    return query;
  },
};

export default MCityRental;
