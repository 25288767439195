/* eslint-disable eqeqeq */

import React, { Component } from 'react';

import { getFullURL } from '../modules/Service';
import placeholderSrc from './img-placeholder.png';

class ImgView extends Component {
  imgError(ev) {
    ev.target.src = placeholderSrc;
  }
  render() {
    const { id, size } = this.props;

    if (id == null) {
      return <span>-</span>;
    }

    const imgSrc = getFullURL(
      ['/photo/get', size, id].filter(Boolean).join('/'),
    );

    return (
      <a
        href={getFullURL(`/photo/get/${id}`)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          alt="Thumbnail"
          className="thumbnail"
          width={size}
          height="auto"
          src={imgSrc}
          onError={e => this.imgError(e)}
        />
      </a>
    );
  }
}
export default ImgView;
ImgView.defaultProps = {
  id: '',
  size: '',
};
