import * as React from 'react';
import { Route } from 'react-router-dom';
import { useForm, useField } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import moment from 'moment';

import { useOptions } from '../../modules/Hooks';
import { Wizard, Field, validators } from '../../components/FinalForm';
import {
  AddBikes,
  AddBikeTypes,
  BikePlanTable,
  BikeTable,
} from './TransportBikes';
import MBikeType from '../../models/MBikeType';
import MTransport from '../../models/MTransport';
import MTransportBike from '../../models/MTransportBike';
import MTransportPlanning from '../../models/MTransportPlanning';
import MTransportPlanningBikeType from '../../models/MTransportPlanningBikeType';
import { saveItem, saveChildren } from '../../modules/FormHelper';

const TransportPlanField = ({ options, ...props }) => {
  const form = useForm();

  const {
    input: { value: selectedPlanId },
  } = useField('bike_transport_plan_id');

  const {
    input: { value: transportId },
  } = useField('id');

  React.useEffect(() => {}, []);

  const filteredOptions = React.useMemo(
    () =>
      options &&
      options.filter(
        ({ plan }) => !plan.allocated || plan.id === selectedPlanId,
      ),
    [options, selectedPlanId],
  );

  return (
    <Field
      disabled={Boolean(transportId)}
      select={{
        options: filteredOptions,
        isClearable: true,
        onChange: selected => {
          const { plan } = selected || {};
          if (plan) {
            form.getRegisteredFields().forEach(field => {
              if (['bike_transport_plan_id', 'id'].includes(field)) return;
              if (plan[field]) {
                form.change(field, plan[field]);
                form.blur(field);
              }
            });
            form.blur('departure_date,arrival_date');
          }
        },
      }}
      {...props}
    />
  );
};

const TransportCheckout = ({ isPlanning, data, ...props }) => {
  const [loading, setLoading] = React.useState(true);
  const [loadedData, setLoadedData] = React.useState(data);
  const [focusedDatepicker, setFocusedDatepicker] = React.useState();

  const locationOptions = useOptions('/location/list');

  const bikeTypeOptions =
    useOptions('/bike_type/list', bikeType => ({
      value: bikeType.id,
      label: MBikeType.label(bikeType),
    })) || [];

  const transportPlanOptions = useOptions(
    !isPlanning && '/bike_transport_plan/list?state=all',
    plan => ({
      value: plan.id,
      label: MTransportPlanning.label(plan),
      plan,
    }),
  );

  React.useEffect(() => {
    if (data.id && !isPlanning) {
      setLoading(true);
      const xhr = MTransport.get(data.id);

      xhr.then(transport => {
        setLoadedData({
          ...transport,
          bikes: (transport.bikes || []).map(bike => ({
            ...bike,
            bike_type_id: bike.type_id,
          })),
        });
        setLoading(false);
      });

      return () => xhr.abort();
    } else {
      setLoadedData(data);
      setLoading(false);
    }
  }, [isPlanning, data]);

  const bikeSectionProps = {
    isPlanning,
    bikeTypeOptions,
    transportPlanOptions,
  };

  const model = isPlanning ? MTransportPlanning : MTransport;

  const BeforeBikes = isPlanning ? AddBikeTypes : AddBikes;
  const tomorrow = moment().add(1, 'day');

  return (
    <Wizard {...props} loading={loading} data={loadedData}>
      <Wizard.Page
        id="info"
        icon="fa-newspaper-o"
        title="Detail"
        onSubmit={saveItem({ model })}
      >
        {!isPlanning && (
          <TransportPlanField
            className="col-xs-12"
            name="bike_transport_plan_id"
            label="Transport Plan"
            options={transportPlanOptions}
          />
        )}
        <Field
          className="col-xs-12"
          label="Date Range"
          name={['departure_date', 'arrival_date']}
          validate={(value, allValues) => {
            if (!allValues.departure_date && !allValues.arrival_date) {
              return 'Required';
            }

            if (!allValues.departure_date) return 'Departure Date required';
            if (
              !isPlanning &&
              moment(allValues.departure_date).isAfter(tomorrow)
            ) {
              return 'Departure Date cannot be after tomorrow';
            }

            if (!allValues.arrival_date) return 'Arrival Date required';
          }}
          dateRangePicker={{
            onFocusChange: setFocusedDatepicker,
            isOutsideRange: day =>
              !isPlanning &&
              focusedDatepicker === 'startDate' &&
              day.isAfter(tomorrow),
          }}
        />

        <Field
          className="col-sm-6"
          name="departure_location_id"
          label="Departure City"
          validate={validators.required}
          select={{ options: locationOptions }}
        />
        <Field
          className="col-sm-6"
          name="arrival_location_id"
          label="Arrival City"
          validate={validators.required}
          select={{ options: locationOptions }}
        />
        <Field
          className="col-xs-12"
          name="transport_type_id"
          label="Transport Type"
          validate={validators.required}
          select={{ endpoint: '/transport_type/list' }}
        />
      </Wizard.Page>
      <Wizard.Page
        id="bikes"
        icon="fa-motorcycle"
        title="Bikes"
        before={() => <BeforeBikes {...bikeSectionProps} />}
        onSubmit={
          isPlanning
            ? saveChildren({
                parentModel: MTransportPlanning,
                parentChildKey: 'bike_types',
                childModel: MTransportPlanningBikeType,
                childIdKey: 'bike_type_id',
                mapItem: (
                  bike_transport_plan_id,
                  { bike_type_id, quantity },
                ) => ({
                  bike_transport_plan_id,
                  bike_type_id,
                  quantity: Number(quantity),
                }),
              })
            : saveChildren({
                parentModel: MTransport,
                parentChildKey: 'bikes',
                childModel: MTransportBike,
                childIdKey: 'id',
                mapItem: (bike_transport_id, bike) => ({
                  bike_transport_id,
                  bike_id: bike.id,
                }),
              })
        }
      >
        {!isPlanning && <BikePlanTable {...bikeSectionProps} />}
        <BikeTable {...bikeSectionProps} />

        <FieldArray
          name={isPlanning ? 'bike_types' : 'bikes'}
          validate={value =>
            value && value.length
              ? undefined
              : `Must select at least one bike${isPlanning ? ' type' : ''}`
          }
        >
          {({ meta }) =>
            typeof meta.error === 'string' && (
              <b className="col-xs-12 text-danger">{meta.error}</b>
            )
          }
        </FieldArray>
      </Wizard.Page>
    </Wizard>
  );
};

export default props => (
  <Route
    path="/:report"
    render={({ match }) => (
      <TransportCheckout
        isPlanning={match.params.report === 'transport-plan'}
        {...props}
      />
    )}
  />
);
