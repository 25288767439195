import React, { Component } from 'react';
import { UserGroupForm } from './UserGroupForm.js';
import { UIAccessForm } from './UIAccessForm.js';
import { APIAccessForm } from './APIAccessForm.js';

export class UserGroupCheckout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: '',
      submitLoading: false,
    };
  }

  render() {
    return (
      <div className="box-block">
        <ul className="nav nav-tabs">
          <li className="active">
            <a data-toggle="tab" href="#tgt-tab-1">
              Group name{' '}
            </a>
          </li>
          <li className="">
            <a data-toggle="tab" href="#tgt-tab-2">
              UI Access
            </a>
          </li>
          <li className="">
            <a data-toggle="tab" href="#tgt-tab-3">
              API Access
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div id={'tgt-tab-1'} className={'tab-pane fade in active'}>
            <UserGroupForm
              RefreshParentData={() => this.props.RefreshParentData}
            />
          </div>
          <div id={'tgt-tab-2'} className={'tab-pane fade in'}>
            <UIAccessForm />
          </div>
          <div id={'tgt-tab-3'} className={'tab-pane fade in'}>
            <APIAccessForm />
          </div>
        </div>
      </div>
    );
  }
}
