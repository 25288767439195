import React, { Fragment, Component } from 'react';
import classnames from 'classnames';

import { InitDate } from '../../modules/Event';

class DatePicker extends Component {
  componentDidMount() {
    InitDate();
  }
  render() {
    const { config, data, className, required } = this.props;
    const { label, key, default_value } = config;
    const value = data != null ? data[key] : '';
    return (
      <Fragment>
        <label>{label}</label>
        <div className="input-group date">
          <div className="input-group-addon">
            <i className="fa fa-calendar" />
          </div>
          <input
            type="text"
            autoComplete="off"
            name={key}
            defaultValue={value || default_value}
            required={required}
            className={classnames(
              className,
              'form-control datepicker pull-right',
            )}
          />
        </div>
      </Fragment>
    );
  }
}
export default DatePicker;
