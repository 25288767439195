import * as React from 'react';
import moment from 'moment';

import { Modal } from '../../components/Modal';
import { EditForm, Field, validators } from '../../components/FinalForm';
import { usePropState } from '../../modules/Hooks';
import { saveItem } from '../../modules/FormHelper';
import MContract from '../../models/MContract';
import MBikeType from '../../models/MBikeType';

const ContractFinish = ({ contract, resolve, afterClose }) => {
  const [open, setOpen] = usePropState(Boolean(contract));
  const isCity = Boolean(contract && contract.city_rental);

  const title = [
    `Finish ${isCity ? 'City Rental' : 'Travel Contract'}`,
    contract && `#${contract.id}`,
  ]
    .filter(Boolean)
    .join(' ');

  const data = React.useMemo(
    () =>
      contract && {
        ...contract,
        review_email_send_date: moment()
          .add('1', 'days')
          .format('YYYY-MM-DD'),
      },
    [contract],
  );

  return (
    <Modal
      open={open}
      title={title}
      onClose={() => resolve && resolve()}
      afterClose={afterClose}
      size="lg"
    >
      {contract && (
        <EditForm
          data={data}
          onSubmit={async (values, form) => {
            const errors = await saveItem({
              model: MContract,
              method: 'finish',
              transform: () => values,
            })(values, form);

            if (errors) return errors;

            resolve({ refreshData: true });
            setOpen(false);
          }}
          submitText="Finish"
        >
          <Field
            className="col-md-6"
            name="customer"
            readOnly
            value={[
              contract.customer_email,
              contract.customer_name && `(${contract.customer_name})`,
            ]
              .filter(Boolean)
              .join(' ')}
          />
          <Field
            className="col-md-6"
            name="bike"
            readOnly
            value={`${contract.bike_numberplate} / ${MBikeType.label(
              contract,
              false,
            )}`}
          />
          <Field
            className="col-md-6"
            name="return_date"
            datePicker
            initialValue={moment().format('YYYY-MM-DD')}
          />
          <Field
            className="col-md-6"
            label="Return City"
            name="return_location_id"
            select={{ endpoint: '/location/list' }}
            defaultValue={
              isCity ? contract.start_location_id : contract.return_location_id
            }
          />

          <Field
            className="col-xs-12 col-sm-6"
            label="Bike Keys Allotted"
            name="release_keys_quantity"
            readOnly
            type="number"
          />
          <Field
            className="col-xs-12 col-sm-6"
            name="return_keys_quantity"
            type="number"
            validate={validators.composeValidators(
              validators.integer,
              validators.minValue(0),
            )}
            initialValue=""
          />

          <Field
            className="col-xs-12 col-sm-6"
            label="Start of Contract Odometer"
            name="bike_odometer_x10"
            readOnly
            type="number"
          />
          <Field
            className="col-xs-12 col-sm-6"
            label="End of Contract Odometer"
            name="odometer_x10"
            type="number"
            validate={validators.composeValidators(
              validators.required,
              validators.integer,
              validators.minValue(0),
            )}
            initialValue=""
          />

          <Field
            className="col-xs-12"
            name="ready_for_hire"
            type="checkbox"
            initialValue={false}
          />
          <div className="col-sm-6">
            <Field
              name="release_bluecard"
              label="Bluecard Released?"
              type="checkbox"
              readOnly
            />
            <Field
              name="retained_cash_deposit"
              label="Cash Deposit Retained?"
              type="checkbox"
              readOnly
            />
            <Field
              label="Passport Retained?"
              name="retained_passport"
              type="checkbox"
              readOnly
            />
          </div>
          <div className="col-sm-6">
            <Field
              name="return_bluecard"
              label="Bluecard Returned?"
              type="checkbox"
              initialValue={false}
              readOnly={!contract.release_bluecard}
            />
            <Field
              name="return_cash_deposit"
              label="Cash Deposit Returned?"
              type="checkbox"
              initialValue={false}
              readOnly={!contract.retained_cash_deposit}
            />
            <Field
              label="Passport Returned?"
              name="return_passport"
              type="checkbox"
              initialValue={false}
              readOnly={!contract.retained_passport}
            />
          </div>

          <div className="col-xs-12">
            <div className="row">
              <Field
                className="col-sm-6 col-md-4"
                name="contract_photo_id"
                type="photo"
                readOnly
              />
              <Field
                className="col-sm-6 col-md-4"
                name="damage_report_photo_id"
                type="photo"
                readOnly
              />
              <Field
                className="col-sm-6 col-md-4"
                name="crash_report_photo_id"
                type="photo"
                readOnly
              />
            </div>
          </div>

          <Field
            className="col-xs-12 col-md-6"
            label="Notes for Mechanic"
            name="notes_workshop"
            type="textarea"
          />
          <Field
            className="col-xs-12 col-md-6"
            label="Notes for Office"
            name="notes_office"
            type="textarea"
          />
          <Field
            className="col-xs-12"
            label="Send Review Email On..."
            name="review_email_send_date"
            datePicker
          />
          {contract.customer_disable_emails ? (
            <small className="col-xs-12 text-warning">
              <b>Warning:</b> All emails are disabled for this customer
            </small>
          ) : null}
        </EditForm>
      )}
    </Modal>
  );
};

export default ContractFinish;
