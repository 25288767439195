import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

export class Modal extends Component {
  modal = React.createRef();

  componentDidMount() {
    $(this.modal.current)
      .on('hide.bs.modal', () => {
        if (this.props.onClose) this.props.onClose();
      })
      .on('hidden.bs.modal', () => {
        if (this.props.afterClose) this.props.afterClose();
      });

    if (this.props.open) {
      $(this.modal.current).modal('show');
    }
  }

  componentWillUnmount() {
    $(this.modal.current)
      .off('hide.bs.modal')
      .off('hidden.bs.modal')
      .modal('hide');
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open) {
      $(this.modal.current).modal(this.props.open ? 'show' : 'hide');
    }
  }

  render() {
    const { children, id, title, size } = this.props;

    return createPortal(
      <div
        id={id}
        ref={this.modal}
        className="modal fade tgt-modal"
        role="dialog"
      >
        <div className={`modal-dialog modal-${size}`}>
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
              <h4 className="modal-title">{title}</h4>
            </div>
            <div className="modal-body">{children}</div>
          </div>
        </div>
      </div>,
      document.body,
    );
  }
}
Modal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  afterClose: PropTypes.func,
};
Modal.defaultProps = {
  size: 'lg',
};
