import * as React from 'react';
import { FORM_ERROR } from 'final-form';

import { EditForm, Field, validators } from '../../components/FinalForm';

export default ({ contracts, contractRequests, ...props }) => (
  <EditForm
    {...props}
    validate={(values, ...rest) => {
      console.log({ values, ...rest });
      let error;
      if (values.contract_id && values.contract_request_id) {
        error = 'Cannot have both a Contract and a Contract Request';
      } else if (!values.contract_id && !values.contract_request_id) {
        error = 'Must have either a Contract or a Contract Request';
      } else {
        return;
      }

      if (!('contract_id' in values) && !('contract_request_id' in values)) {
        // avoid showing error before user has edited either,
        // but still mark the individual ones as invalid
        error = undefined;
      }

      return {
        contract_id: true,
        contract_request_id: true,
        [FORM_ERROR]: error,
      };
    }}
  >
    <Field
      className="col-xs-12"
      name="contract_request_id"
      label="Contract Request"
      select={{ options: contractRequests, isClearable: true }}
      disabled={values =>
        Boolean(values.contract_id && !values.contract_request_id)
      }
    />
    <Field
      className="col-xs-12"
      name="contract_id"
      label="Contract"
      select={{ options: contracts, isClearable: true }}
      disabled={values =>
        Boolean(values.contract_request_id && !values.contract_id)
      }
    />
    <Field
      className="col-xs-12"
      name="shopify_invoice_id"
      label="Shopify Invoice ID"
      validate={validators.required}
    />
  </EditForm>
);
