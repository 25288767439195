import * as React from 'react';
import { sortBy } from 'lodash';

import MBikeCheckin from '../../models/MBikeCheckin';
import { Api } from '../../modules/Service';
import List from '../../components/List';
import { EditForm, Field } from '../../components/FinalForm';
import {
  column as bikePriorityColumn,
  options as bikePriorityOptions,
} from '../../components/BikeCheckinPriority';

const WorkshopForm = props => {
  const [data, setData] = React.useState(props.data);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (props.data.should_reload) {
      let canceled = false;

      setLoading(true);
      MBikeCheckin.get(props.data.id)
        .then(checkin => {
          if (!canceled) {
            setData({ ...checkin, ready_for_hire: props.data.ready_for_hire });
            setLoading(false);
          }
        })
        .catch(() => {
          if (!canceled) setLoading(false);
        });

      return () => {
        canceled = true;
      };
    }
  }, [props.data]);

  return (
    <EditForm {...props} loading={loading} data={data}>
      <Field
        className="col-sm-12"
        name="bike_numberplate"
        label="Numberplate"
        disabled
      />
      <Field
        className="col-sm-12"
        name="notes_workshop"
        label="Notes (Workshop)"
        rows={3}
        type="textarea"
      />
      <Field
        className="col-sm-12"
        name="ready_for_hire"
        type="checkbox"
        fieldConfig={{
          isEqual: value => !value, // mark dirty whenever it's set to true
        }}
      />
      <Field
        className="col-sm-12"
        label="Priority"
        name="priority_numeric"
        select={{ options: bikePriorityOptions }}
        defaultValue={3}
      />
    </EditForm>
  );
};

const Workshop = ({ history }) => {
  const [tabs, setTabs] = React.useState([]);
  const [defaultLocationId, setDefaultLocationId] = React.useState(null);

  React.useEffect(() => {
    Api('/auth/ping').then(user => {
      setDefaultLocationId(user.default_location_id);
    });
  }, []);

  const MBikeWorkshop = React.useMemo(
    () => ({
      ...MBikeCheckin,

      title: 'Workshop',

      list: () =>
        Api('/bike_location_report/summary').then(({ bikes, locations }) => {
          setTabs(
            Object.values(locations)
              .filter(location => location.id && !location.is_hidden)
              .map(location => ({
                id: location.id,
                title: location.name,
                filter: data => data.location_id === location.id,
              })),
          );

          return sortBy(
            Object.values(bikes)
              .map(bike => {
                if (bike.ready_for_hire || !bike.status.checkin) return null;

                return {
                  id: bike.status.checkin.id,
                  location_id: bike.location_id,
                  bike_manufacturer_name: bike.manufacturer_name,
                  bike_model_name: bike.model_name,
                  bike_numberplate: bike.numberplate,
                  bike_id: bike.id,
                  odometer_x10: bike.odometer_x10,
                  priority_numeric:
                    bike.status.checkin.priority_numeric ||
                    bike.status.checkin.priority,
                  should_reload: true,
                };
              })
              .filter(Boolean),
            'priority_numeric',
          );
        }),
    }),
    [],
  );

  return (
    <List
      model={MBikeWorkshop}
      edit
      editButton={false}
      editForm={WorkshopForm}
      tabs={tabs}
      defaultTabId={defaultLocationId}
      numActionsPerRow={3}
      columns={[
        { key: 'bike_numberplate', label: 'Numberplate' },
        {
          key: 'model',
          toText: row =>
            `${row.bike_manufacturer_name} / ${row.bike_model_name}`,
        },
        { key: 'odometer_x10', label: 'Odometer (km)', type: 'number' },
        bikePriorityColumn,
      ]}
      beforeActions={[
        ({ row }) => ({
          id: 'ready-for-hire',
          title: 'Ready for Hire',
          type: 'success',
          icon: 'fa-check white',
          onClick: async ({ editRow }) =>
            editRow({
              ...(await MBikeCheckin.get(row.id)),
              ready_for_hire: true,
            }),
        }),
        ({ row }) => ({
          id: 'adit',
          title: 'Edit',
          type: 'primary',
          icon: 'fa-edit',
          onClick: async ({ editRow }) =>
            editRow(await MBikeCheckin.get(row.id)),
        }),
        ({ row }) => ({
          id: 'view-contract',
          title: 'View Contract',
          type: 'info',
          icon: 'fa-paperclip',
          onClick: async () => {
            try {
              const contract = await Api(
                `/contract/get/recent_bike/${row.bike_id}`,
              );
              history.push(`/contracts?id=${contract.id}`);
            } catch {
              alert('Could not find a recent contract');
            }
          },
        }),
      ]}
      afterActions={[]}
    />
  );
};

export default Workshop;
