import Dashboard from './views/Dashboard/Dashboard';

import Bikes from './views/Bike/Bikes';
import DeletedBikes from './views/Bike/DeletedBikes';
import BikeCheckins from './views/Bike/BikeCheckins';
import BikeLookup from './views/Bike/BikeLookup';
import Manufacturers from './views/Bike/Manufacturers';
import Models from './views/Bike/Models';
import Transmissions from './views/Bike/Transmissions';
import Types from './views/Bike/Types';
import Transport from './views/Bike/Transport';
import TransportType from './views/Bike/TransportType';
import Customers from './views/Customer/Customers';
import ContractRequests from './views/Customer/ContractRequests';
import Contracts from './views/Customer/Contracts';
import CityRental from './views/Customer/CityRental';
import Referal from './views/Customer/Referal';
import Communication from './views/Customer/Communication';

import TransportCheckoutFinish from './views/Bike/TransportCheckoutFinish';
import Locations from './views/Location/Locations';
import Routes from './views/Location/Routes';

import Payments from './views/Payment/Payments';

import Inventory from './views/Inventory/Inventory';
import InventoryItems from './views/Inventory/InventoryItems';
import Suppliers from './views/Inventory/Suppliers';
import Category from './views/Inventory/Category';

import RBikeLocation from './views/Report/RBikeLocation';
import RReturns from './views/Report/RReturns';
import Workshop from './views/Report/Workshop';
import StockPrediction from './views/Report/StockPrediction';
import Users from './views/User/Users';
import UsersGroup from './views/User/userGroup.js';
import MyProfile from './views/User/MyProfile';
import ActiveContracts from './views/Report/ActiveContracts';
import PageNotFound from './views/PageNotFound';

export default [
  {
    name: 'Dashboard',
    url: '/',
    component: Dashboard,
    exact: true,
  },
  {
    name: 'Bikes',
    url: '/bikes',
    component: Bikes,
    exact: true,
  },
  {
    name: 'Bikes (Deleted)',
    url: '/deleted-bikes',
    component: DeletedBikes,
    exact: true,
  },
  {
    name: 'Bike Checkins',
    url: '/bike-checkins',
    component: BikeCheckins,
    exact: true,
  },
  {
    name: 'Bike Lookup',
    url: '/bike-lookup',
    component: BikeLookup,
    exact: true,
  },
  {
    name: 'Manufacturers',
    url: '/bike-manufacturers',
    component: Manufacturers,
    exact: true,
  },
  {
    name: 'Models',
    url: '/bike-models',
    component: Models,
    exact: true,
  },
  {
    name: 'Transmissions',
    url: '/bike-transmissions',
    component: Transmissions,
    exact: true,
  },
  {
    name: 'Types',
    url: '/bike-types',
    component: Types,
    exact: true,
  },
  {
    name: 'Customers',
    url: '/customers',
    component: Customers,
    exact: true,
  },
  {
    name: 'Contract Requests',
    url: '/contract-requests',
    component: ContractRequests,
    exact: true,
  },
  {
    name: 'Contracts',
    url: ['/contracts', '/contracts/finish/:contractId'],
    component: Contracts,
    exact: true,
  },
  {
    name: 'City Rental',
    url: ['/cityrental', '/cityrental/finish/:contractId'],
    component: CityRental,
    exact: true,
  },
  {
    name: 'Referal company',
    url: '/referal-company',
    component: Referal,
    exact: true,
  },
  {
    name: '',
    url: '/contract-communication/:finishid',
    component: Communication,
    exact: true,
  },
  {
    name: 'Locations',
    url: '/locations',
    component: Locations,
    exact: true,
  },
  {
    name: 'Routes',
    url: '/routes',
    component: Routes,
    exact: true,
  },
  {
    name: 'Payments',
    url: '/payments',
    component: Payments,
    exact: true,
  },
  {
    name: 'Inventory',
    url: '/inventory',
    component: Inventory,
    exact: true,
  },
  {
    name: 'Inventory Items',
    url: '/inventory-items',
    component: InventoryItems,
    exact: true,
  },
  {
    name: 'Suppliers',
    url: '/suppliers',
    component: Suppliers,
    exact: true,
  },
  {
    name: 'Category',
    url: '/category',
    component: Category,
    exact: true,
  },
  {
    name: 'Report Bike Location',
    url: '/report-bike-location',
    component: RBikeLocation,
    exact: true,
  },
  /*{
    name: 'Report Upcoming Rentals',
    url: '/report-upcoming-rentals',
    component:RUpcomingRentals,
    exact: true,
  },*/
  {
    name: 'Report Returns',
    url: '/report-returns',
    component: RReturns,
    exact: true,
  },
  {
    name: 'Workshop',
    url: '/workshop',
    component: Workshop,
    exact: true,
  },
  {
    name: 'Active Contracts',
    url: '/active-contracts',
    component: ActiveContracts,
    exact: true,
  },

  {
    name: 'Users',
    url: '/users',
    component: Users,
    exact: true,
  },
  {
    name: 'Users Group',
    url: '/users-group',
    component: UsersGroup,
    exact: true,
  },

  {
    name: 'Transport',
    url: '/transport',
    component: Transport,
    exact: true,
  },
  {
    name: 'Transport Plan',
    url: '/transport-plan',
    component: Transport,
    exact: true,
  },
  {
    name: 'Transport Type',
    url: '/transport-type',
    component: TransportType,
    exact: true,
  },
  {
    name: 'Transport Finish',
    url: '/transport/finish/:finishid',
    parent: '/transport',
    component: TransportCheckoutFinish,
    exact: true,
  },
  {
    name: 'Stock Prediction',
    url: '/stock-prediction/:bikeTypeId?',
    component: StockPrediction,
    exact: true,
  },

  {
    name: 'My Profile',
    url: '/my-profile',
    component: MyProfile,
    exact: true,
  },
  {
    name: '404',
    url: '',
    component: PageNotFound,
    exact: false,
  },
];
