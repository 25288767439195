import BaseModel from './BaseModel';

const MRoutes = {
  ...BaseModel,

  title: 'Routes',
  endpoint: '/route',

  getId: data =>
    data && data.start_location_id && data.return_location_id
      ? `${data.start_location_id}:${data.return_location_id}`
      : undefined,

  getDisplayName(data) {
    return data.start_location_name && data.return_location_name
      ? `${data.start_location_name} → ${data.return_location_name}`
      : BaseModel.getId.call(this, data);
  },
};

export default MRoutes;
