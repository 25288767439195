/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import MInventoryItem from '../../models/MInventoryItem';
import Alert from '../../components/Alert';
import { UniqueId } from '../../modules/Helper';
import { RenderForm } from '../../components/Form';
import { FormToObject } from '../../modules/Service';
import { GetModal } from '../../modules/Event';
import MCategory from '../../models/MCategory';
export class InventoryItemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uniqueId: UniqueId(),
      msg: '',
      submitLoading: false,
      PurchasePriceList: {
        VND: 'VND',
        USD: 'USD',
      },
    };
  }
  submitForm(ev) {
    ev.preventDefault();
    const { modalId, RefreshParentData } = this.props;
    this.setState({ submitLoading: true });
    const formData = FormToObject($(ev.target).serializeArray());
    if (formData.id == '') {
      MInventoryItem.add(formData)
        .done(resp => {
          GetModal(modalId).scrollTop(0);
          RefreshParentData();
          this.setState({
            uniqueId: UniqueId(),
            submitLoading: false,
            msg: (
              <Alert
                msg="Item added successfully."
                type="success"
                closeHandler={() => {
                  this.setState({ msg: '' });
                }}
              />
            ),
          });
        })
        .catch(xhr => this.catchError(xhr));
    } else {
      MInventoryItem.update(formData)
        .done(resp => {
          GetModal(modalId).scrollTop(0);
          RefreshParentData();
          this.setState({
            submitLoading: false,
            msg: (
              <Alert
                msg="Item updated successfully."
                type="info"
                closeHandler={() => {
                  this.setState({ msg: '' });
                }}
              />
            ),
          });
        })
        .catch(xhr => this.catchError(xhr));
    }
  }
  catchError(xhr) {
    const { modalId } = this.props;
    GetModal(modalId).scrollTop(0);
    this.setState({
      submitLoading: false,
      msg: (
        <Alert
          msg={xhr.getResponseHeader('X-TBMS-Message')}
          type="danger"
          closeHandler={() => {
            this.setState({ msg: '' });
          }}
        />
      ),
    });
  }
  renderData() {
    MCategory.list()
      .done(resp => {
        resp = Array.isArray(resp) ? resp : [resp];
        this.setState({
          uniqueId: UniqueId(),
          loading: false,
          CategoryData: resp,
        });
      })
      .catch(xhr => {
        this.setState({
          uniqueId: UniqueId(),
          loading: false,
          CategoryData: [],
        });
      });
  }
  componentDidMount() {
    this.renderData();
  }
  render() {
    const { submitLoading, msg, uniqueId, CategoryData } = this.state;
    var Category = {};
    if (CategoryData != null) {
      CategoryData.forEach(function(item) {
        var CategoryID = item.id;
        // var TransportName = item.name;
        var CategoryName = item.name;
        Category[CategoryID] = CategoryName;
      });
    }

    const { data } = this.props;
    const fields = [
      {
        'col-sm-6 1': [
          {
            key: 'id',
            type: 'hidden',
          },
          {
            key: 'name',
            type: 'text',
            label: 'Name',
          },
          {
            key: 'brand',
            type: 'text',
            label: 'Brand',
          },
          {
            key: 'description',
            type: 'textarea',
            label: 'Description',
          },
          {
            key: 'sale_price_vnd_x100',
            type: 'number',
            label: 'Sale Price (USD)',
            num_multiplier: 0.01,
          },
        ],
      },
      {
        'col-sm-6 2': [
          {
            key: 'inventoryPhoto1',
            type: 'photo_upload',
            label: 'Photo 1',
          },
          {
            key: 'inventoryPhoto2',
            type: 'photo_upload',
            label: 'Photo 2',
          },
          {
            key: 'inventoryPhoto3',
            type: 'photo_upload',
            label: 'Photo 3',
          },
          {
            key: 'inventoryPhoto4',
            type: 'photo_upload',
            label: 'Photo 4',
          },
          {
            key: 'category_id',
            type: 'select',
            label: 'Category',
            options: Category,
            default_value: 1,
          },
          {
            key: 'allow_hire',
            type: 'checkbox',
            label: 'Not Rentable',
            default_value: false,
          },
        ],
      },
    ];
    return (
      <RenderForm
        key={uniqueId}
        data={data}
        msg={msg}
        config={fields}
        submitLoading={submitLoading}
        submitHandler={e => this.submitForm(e)}
      />
    );
  }
}
