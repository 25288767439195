/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import { InitISelect2js } from '../../modules/Event';
import { TimeLocale, UniqueId } from '../../modules/Helper';
import { Api } from '../../modules/Service';

class ContractSelectRequest extends Component {
  constructor(props) {
    super(props);
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    today = yyyy + '-' + mm + '-' + dd;
    this.state = {
      uniqueId: UniqueId(),
      dataselect: [],
      loading: false,
      lookup_url:
        '/contract_request/list?date=' +
        today +
        '&limit_past=0&limit_future=0&by=start&start_location_id=&bike_type_id=',
    };
  }
  componentDidMount() {
    const { loading } = this.state;
    if (!loading) {
      this.renderData();
    }
    $('select[name="contract_request_id"]').change(ev => {
      var id = ev.target.value;
      this.fillData(id);
    });
  }
  renderData() {
    const { lookup_url } = this.state;
    this.setState({ loading: true });
    Api(lookup_url).done(resp => {
      this.setState({
        dataselect: resp,
        loading: false,
      });
    });
  }
  fillData(id) {
    if (id == '') return false;

    Api('/contract_request/get/' + id).done(resp => {
      $('[name="start_date"]').val(resp.start_date);
      $('[name="start_location_id"]')
        .val(resp.start_location_id)
        .trigger('change');
      $('[name="return_date_estimated"]').val(resp.return_date_estimated);
      $('[name="return_location_id"]')
        .val(resp.return_location_id)
        .trigger('change');
      // $('[name="notes"]').val(resp.notes);
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const { dataselect } = this.state;
    const { key } = this.props.config;
    if (dataselect != null) {
      InitISelect2js(key);
    }
  }
  render() {
    const { uniqueId, loading, dataselect } = this.state;
    const { config, data } = this.props;
    const {
      label,
      key,
      lookup_key,
      lookup_format,
      required,
      default_value,
    } = config;
    const value = data != null ? data[key] : '';
    return (
      <div key={uniqueId}>
        <label>{label}</label>
        <select
          name={key}
          id={'select2-' + key}
          data-value={value || default_value}
          required={required}
          className="form-control select2"
          defaultValue=""
        >
          {(() => {
            switch (true) {
              case loading:
                return (
                  <option value="" disabled>
                    Loading...
                  </option>
                );
              case dataselect.length > 0 && !loading:
                const retVal = [];
                retVal.push(
                  <option key="0" value="">
                    select item
                  </option>,
                );
                retVal.push(
                  dataselect.map(item => {
                    console.log(item.customer_email);
                    const opttext = lookup_format.replace(
                      /{(.*?)}/g,
                      ($1, $2) => {
                        if ($2 == 'timestamp') {
                          item[$2] = TimeLocale(item[$2]);
                        }
                        return item[$2] != null ? item[$2] : '-';
                      },
                    );
                    return (
                      <option key={item[lookup_key]} value={item[lookup_key]}>
                        {item.customer_email} {opttext}
                      </option>
                    );
                  }),
                );
                return retVal;
              default:
                return <option value="">Item not found</option>;
            }
          })()}
        </select>
      </div>
    );
  }
}
export default ContractSelectRequest;
