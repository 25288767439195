import { pick } from 'lodash';

import { Api } from '../modules/Service';
import { filterObjNulls } from '../modules/Helper';

const BaseModel = {
  _assertEndpoint() {
    if (!this.endpoint) {
      throw new Error(
        'In order to use `BaseModel`, the `endpoint` field must be supplied',
      );
    }
  },

  // required
  endpoint: undefined,

  // these are optional but often used
  title: undefined,
  defaultListValues: {},
  validListFilters: undefined,

  getId: data => data && data.id,
  getDisplayName(data) {
    return `#${this.getId(data)}`;
  },

  modifyListParams(params) {
    return params;
  },

  serialize(data) {
    return JSON.stringify(data);
  },

  list(filters) {
    this._assertEndpoint();

    const params = this.modifyListParams(
      new URLSearchParams(
        filterObjNulls({
          ...this.defaultListValues,
          ...(this.validListFilters
            ? pick(filters, this.validListFilters)
            : filters),
        }),
      ),
    );

    return Api([`${this.endpoint}/list`, params].filter(Boolean).join('?'));
  },
  add(data) {
    this._assertEndpoint();

    return Api(`${this.endpoint}/new`, this.serialize(data), 'POST', 'text');
  },
  update(data) {
    this._assertEndpoint();

    return Api(
      `${this.endpoint}/set/${this.getId(data)}`,
      this.serialize(data),
      'POST',
      'text',
    );
  },
  delete(id) {
    this._assertEndpoint();

    return Api(`${this.endpoint}/del/${id}`, {}, 'GET', 'text');
  },
  get(id) {
    this._assertEndpoint();

    return Api(`${this.endpoint}/get/${id}`);
  },
};

export default BaseModel;
