import React, { Component } from 'react';
import MBike from '../../models/MBike';
import Alert from '../../components/Alert';
import { RenderForm } from '../../components/Form';
import { FormToObject } from '../../modules/Service';
import { GetModal } from '../../modules/Event';
export class BikeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: '',
      submitLoading: false,
      PhotoItem: 5,
    };
  }
  submitForm(ev) {
    ev.preventDefault();
    const { modalId, RefreshParentData } = this.props;
    this.setState({ submitLoading: true });
    var inputtxt = document.getElementsByName('numberplate')[0].value;
    this.phonenumber(inputtxt);
    const formData = FormToObject($(ev.target).serializeArray());
    if (!formData.id) {
      MBike.add(formData)
        .done(resp => {
          GetModal(modalId).scrollTop(0);
          RefreshParentData();
          this.setState({
            submitLoading: false,
            msg: (
              <Alert
                msg="Bike added successfully."
                type="success"
                closeHandler={() => {
                  this.setState({ msg: '' });
                }}
              />
            ),
          });
        })
        .catch(xhr => this.catchError(xhr));
    } else {
      MBike.update(formData)
        .done(resp => {
          GetModal(modalId).scrollTop(0);
          RefreshParentData();
          this.setState({
            submitLoading: false,
            msg: (
              <Alert
                msg="Bike updated successfully."
                type="info"
                closeHandler={() => {
                  this.setState({ msg: '' });
                }}
              />
            ),
          });
        })
        .catch(xhr => this.catchError(xhr));
    }
  }

  catchError(xhr) {
    const { modalId } = this.props;
    GetModal(modalId).scrollTop(0);
    this.setState({
      submitLoading: false,
      msg: (
        <Alert
          msg={xhr.getResponseHeader('X-TBMS-Message')}
          type="danger"
          closeHandler={() => {
            this.setState({ msg: '' });
          }}
        />
      ),
    });
  }

  phonenumber(inputtxt) {
    jQuery("input[name='numberplate']").css('border', '0px solid #dd4b39');
    const phoneno = /(([\w\-.]+[-.][\w\-.]+)(\d+)\.(\d+))$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      jQuery("input[name='numberplate']").css('border', '1px solid #dd4b39');
      return false;
    }
  }

  render() {
    const { submitLoading, msg } = this.state;
    const { data } = this.props;

    const fields = [
      {
        'col-sm-6 1': [
          {
            key: 'id',
            type: 'hidden',
          },
          {
            key: 'numberplate',
            type: 'text',
            label: 'Number Plate',
            placeholder: 'BLADE-77C1-498.07',
          },
          {
            key: 'type_id',
            type: 'select2js',
            label: 'Type',
            lookup_url: '/bike_type/list',
            lookup_key: 'id',
            lookup_format:
              '{manufacturer_name} / {model_name} / {engine_capacity}cc / {transmission_name}',
          },
          {
            key: 'purchase_date',
            type: 'date',
            label: 'Purchase Date',
          },
          {
            key: 'manufacture_date',
            type: 'date',
            label: 'Reg Date',
          },
          {
            key: 'purchase_price_vnd_x100',
            type: 'number',
            label: 'Purchase Price (VND)',
            num_multiplier: 0.01,
          },
          {
            key: 'purchase_price_usd_x100',
            type: 'number',
            label: 'Purchase Price (USD)',
            num_multiplier: 0.01,
          },
        ],
      },
      {
        'col-sm-6 2': [
          {
            key: 'bluecard_front_photo_id',
            type: 'photo_upload',
            label: 'Bluecard Front Photo',
          },
          {
            key: 'bluecard_back_photo_id',
            type: 'photo_upload',
            label: 'Bluecard Back Photo',
          },
        ],
      },
    ];

    return (
      <RenderForm
        data={data}
        msg={msg}
        config={fields}
        submitLoading={submitLoading}
        submitHandler={e => this.submitForm(e)}
      />
    );
  }
}
