import React, { Component } from 'react';
import moment from 'moment';

import MReport from '../../models/MReport';
import Box from '../../components/Box';
import Alert from '../../components/Alert';
import BikeLocationRender from './BikeLocationRender';
import BikeLocationFilter from './BikeLocationFilter.js';

class RBikeLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      msg: '',
      data: null,
      xhr: null,
    };
  }

  renderData() {
    this.setState(prevState => {
      if (prevState.xhr) prevState.xhr.abort();

      const xhr = MReport.bikeLocation();

      xhr
        .done(resp => {
          this.setState({
            loading: false,
            data: resp,
          });
        })
        .catch(() => {
          if (xhr.statusText === 'abort') return;

          this.setState({
            msg: (
              <Alert
                msg={xhr.getResponseHeader('X-TBMS-Message')}
                type="danger"
                closeHandler={() => {
                  this.setState({ msg: '' });
                }}
              />
            ),
            loading: false,
            data: null,
          });
        });

      return { xhr };
    });
  }

  componentDidMount() {
    this.renderData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.setState({
        loading: true,
      });
      this.renderData();
    }
  }

  render() {
    const { loading, data, msg } = this.state;
    return (
      <Box className="report-page">
        <div className="bike-location-report clearfix">
          <h2>Bike Location Report ({moment().format('dddd, YYYY-MM-DD')})</h2>
          <BikeLocationFilter submitLoading={loading} />
          <br />
          {msg && msg}
          {loading && <div className="tgt-loader" />}
          {!loading && <BikeLocationRender data={data} />}
        </div>
      </Box>
    );
  }
}
export default RBikeLocation;
