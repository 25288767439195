import * as React from 'react';
import uuid from 'uuid/v4';
import { throttle, isEqual } from 'lodash';

import { Api } from '../modules/Service';

export const useUUID = () => React.useState(uuid)[0];

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  React.useEffect(() => {
    const timeout = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(timeout);
  }, [value, delay]);

  return debouncedValue;
};

export const useDebouncedState = (initialValue, delay) => {
  const [value, setValue] = React.useState(initialValue);
  const debouncedValue = useDebounce(value, delay);

  return [debouncedValue, setValue];
};

export const useThrottledState = (initialValue, delay) => {
  const [throttledValue, setThrottledValue] = React.useState(initialValue);
  const throttledSetter = React.useCallback(
    throttle(setThrottledValue, delay),
    [delay],
  );

  return [throttledValue, throttledSetter];
};

export const useAPI = (...args) => {
  const [data, setData] = React.useState(null);
  const [info, setInfo] = React.useState();

  React.useEffect(() => {
    // args will change every time so this will run on each render,
    // but the data will only be fetched when params changes
    const [model, fn, ...params] = args;
    const nextInfo = { model, fn, params };
    if (!isEqual(info, nextInfo)) {
      setInfo(nextInfo);
    }
  }, [args, info]);

  React.useEffect(() => {
    if (!info) return;

    const { model, fn, params } = info;

    if (model && fn) {
      const xhr = model[fn](...params);
      xhr.then(response => setData(response));

      return () => xhr.abort();
    } else {
      setData(null);
    }
  }, [info]);

  return data;
};

const EndpointModel = { Api };
export const useEndpoint = endpoint =>
  useAPI(endpoint && EndpointModel, 'Api', endpoint);

export const useOptions = (
  endpoint,
  uncachedToOption = ({ id, name }) => ({ value: id, label: name }),
  toOptionDependencies = [],
) => {
  const data = useEndpoint(endpoint);
  const toOption = React.useCallback(uncachedToOption, toOptionDependencies);

  return React.useMemo(() => data && data.map(toOption), [data, toOption]);
};

export const usePropState = propValue => {
  const [value, setValue] = React.useState(propValue);

  React.useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  return [value, setValue];
};
