import React, { Component } from 'react';

class ReadOnly extends Component {
  render() {
    const { config, data } = this.props;
    const { key, label, default_value } = config;
    const value = data != null ? data[key] : '';
    return [
      <label key="1">{label}</label>,
      <input
        key="2"
        type="text"
        name={key}
        value={value || default_value}
        className="form-control"
        readOnly
      />,
    ];
  }
}
export default ReadOnly;
