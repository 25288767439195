/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import { UniqueId } from '../../modules/Helper';
import { RenderForm } from '../../components/Form';
import MContract from '../../models/MContract';
import { FormToObject } from '../../modules/Service';
import Alert from '../../components/Alert';
import { GetModal } from '../../modules/Event';
export class CommunicationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uniqueId: UniqueId(),
      msg: '',
      submitLoading: false,
      contactMethod: [],
    };
  }

  componentDidMount() {
    MContract.ContactMethodlist().done(resp => {
      this.setState({
        contactMethod: resp,
      });
    });
  }

  submitForm(ev) {
    jQuery('input.form-control.datepicker.pull-right').attr('disabled', false);
    ev.preventDefault();
    const { communicationData } = this.props;
    const formData = FormToObject($(ev.target).serializeArray());
    if (communicationData == null) {
      MContract.addCommunication(formData)
        .done(resp => {
          this.setState({
            submitLoading: false,
            msg: (
              <Alert
                msg="Communication Added successfully."
                type="info"
                closeHandler={() => {
                  this.setState({ msg: '' });
                }}
              />
            ),
          });
          this.props.RefreshParentData();
          $('.modal-header .close').trigger('click');
        })
        .catch(xhr => this.catchError(xhr));
    } else {
      MContract.updateCommunication(formData)
        .done(resp => {
          this.setState({
            submitLoading: false,
            msg: (
              <Alert
                msg="Communication update successfully."
                type="info"
                closeHandler={() => {
                  this.setState({ msg: '' });
                }}
              />
            ),
          });
          this.props.RefreshParentData();
          $('.modal-header .close').trigger('click');
        })
        .catch(xhr => {
          alert('safsdfasdf');
          this.setState({
            submitLoading: false,
            msg: (
              <Alert
                msg={xhr.getResponseHeader('X-TBMS-Message')}
                type="danger"
                closeHandler={() => {
                  this.setState({ msg: '' });
                }}
              />
            ),
          });
        });
    }
  }

  catchError(xhr) {
    const { modalId } = this.props;
    GetModal(modalId).scrollTop(0);
    this.setState({
      submitLoading: false,
      msg: (
        <Alert
          msg={xhr.getResponseHeader('X-TBMS-Message')}
          type="danger"
          closeHandler={() => {
            this.setState({ msg: '' });
          }}
        />
      ),
    });
  }

  render() {
    const { submitLoading, msg, uniqueId, contactMethod } = this.state;
    const { data, communicationData } = this.props;
    var type = '';
    if (msg.props != undefined) {
      if (msg.props.type == 'danger') {
        type = 'danger';
      }
    }

    var contact = {};
    if (contactMethod != null) {
      contactMethod.forEach(function(item) {
        var contactID = item.id;
        var contactName = item.name;
        contact[contactID] = contactName;
      });
    }
    $('body').on('click', 'a.btn.btn-sm.btn-primary.clear.right', function() {
      jQuery('input.form-control.datepicker.pull-right').attr(
        'disabled',
        false,
      );
      jQuery('input.form-control.datepicker.pull-right').val('');
    });

    let renewID = null;
    let BikeID = null;
    let customer_id = null;
    let bike_numberplate = null;
    let customer_email = null;
    if (data != null) {
      if (type != 'danger') {
        if (data.renew_date != null) {
          jQuery('input.form-control.datepicker.pull-right').val(
            data.renew_date,
          );
          jQuery('input.form-control.datepicker.pull-right').attr(
            'disabled',
            true,
          );
          jQuery('.clear').remove();
          jQuery('.input-group.date').after(
            '<a href="javascript:void(0)" title="clear" class="btn btn-sm btn-primary clear right">Clear</a>',
          );
        }
      }

      if (type != 'danger') {
        renewID = data.renew_date;
        BikeID = data.bike_id;
        customer_id = data.customer_id;
        bike_numberplate = data.bike_numberplate;
        customer_email = data.customer_email;
      }
    }

    let id = null;
    let renewDate = null;
    let communication_method = null;
    let customer_responded = null;
    let notes = null;

    if (communicationData != null) {
      id = communicationData.id;
      renewDate = communicationData.renew_date;
      communication_method = communicationData.communication_method_id;
      customer_responded = communicationData.customer_responded;
      notes = communicationData.notes;
      $(
        'select[name="communication_method_id"] option[value="' +
          communication_method +
          '"]',
      ).attr('selected', 'selected');

      $('input[name="renew_date"]').val(renewDate);
      $('textarea[name="notes"]').val(notes);

      $('select[name="communication_method_id').prop(
        'selectedIndex',
        communication_method,
      );

      if (customer_responded == 1) {
        $('input[name=customer_responded]').attr('checked', true);
        $('.icheckbox_minimal-green').addClass('checked');
      } else {
        $('input[name=customer_responded]').attr('checked', false);
        $('.icheckbox_minimal-green').removeClass('checked');
      }
    } else {
      $('select[name="communication_method_id').prop('selectedIndex', 0);

      if (type != 'danger') {
        $('textarea[name="notes"]').val('');
        $('input[name=customer_responded]').attr('checked', false);
        $('.icheckbox_minimal-green').removeClass('checked');
      }
    }

    const fields = [
      {
        'col-sm-12': [
          {
            key: 'id',
            type: 'hidden',
            default_value: id,
          },
          {
            key: 'contract_id',
            type: 'readonly',
            label: 'Contract ID',
            default_value: this.props.ContractID,
          },
          {
            key: 'bike_id',
            type: 'hidden',
            default_value: BikeID,
          },
          {
            key: 'user_id ',
            type: 'hidden',
            default_value: customer_id,
          },
          {
            key: 'email',
            type: 'readonly',
            label: 'Customer email',
            default_value: customer_email,
          },
          {
            key: 'bike_number',
            type: 'readonly',
            label: 'Number Plate',
            default_value: bike_numberplate,
          },
          {
            key: 'renew_date',
            type: 'date',
            label: 'Renew date',
            default_value: renewID,
          },
          {
            key: 'communication_method_id',
            type: 'select',
            label: 'Communication Method',
            options: contact,
            default_value: communication_method,
          },
          {
            key: 'customer_responded',
            type: 'checkbox',
            label: 'Customer Responded',
            default_value: customer_responded,
          },
          {
            key: 'notes',
            type: 'textarea',
            label: 'Notes',
            default_value: notes,
          },
        ],
      },
    ];
    return (
      <RenderForm
        key={uniqueId}
        msg={msg}
        config={fields}
        submitLoading={submitLoading}
        submitHandler={e => this.submitForm(e)}
      />
    );
  }
}
