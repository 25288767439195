import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Box from '../../components/Box';
import MReport from '../../models/MReport';
import { Datatable } from '../../components/Datatable';
class ActiveContracts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: null,
      modalId: 'mdl-form',
      modalOpened: false,
      editData: null,
      viewModalId: 'mdl-view',
      BikeModalId: 'md2-view',
      viewModalOpened: false,
      viewData: null,
      contractReqID: '',
    };
  }
  renderData() {
    MReport.ActiveContracts()
      .done(resp => {
        resp = Array.isArray(resp) ? resp : [resp];
        this.setState({
          loading: false,
          data: resp,
        });
      })
      .catch(xhr => {
        this.setState({
          loading: false,
          data: [],
        });
      });
  }
  componentDidMount() {
    this.renderData();
  }
  componentWillReceiveProps(nextProps) {
    //url query changes
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        loading: true,
      });
      this.renderData();
    }
  }
  render() {
    const { loading, data } = this.state;

    const thead = ['Bike', 'Contracts', 'Number of Contracts'];

    return [
      <Box key="0" title="Multiple Active Contracts">
        <Datatable loading={loading} thead={thead}>
          {data != null &&
            !loading &&
            data.map((item, idx) => {
              const {
                id,
                bike_numberplate,
                bike_manufacturer_name,
                bike_model_name,
                bike_type_engine_capacity,
                contract_count,
                active_contracts,
              } = item;

              return (
                <tr key={idx} id={'dt-item-' + id}>
                  <td>
                    {bike_numberplate} ({bike_manufacturer_name}{' '}
                    {bike_model_name} {bike_type_engine_capacity}cc)
                  </td>
                  <td>
                    {active_contracts.map(function(object, i) {
                      return (
                        <span className="active_contracts">
                          <Link
                            title="View Contract"
                            to={'/contracts?customer=' + object.customer_id}
                            className="Contract btn btn-sm btn-success"
                          >
                            View Contract
                          </Link>
                          <Link
                            title="View Customer"
                            to={'/customers?customer=' + object.customer_id}
                            className="customer btn btn-sm btn-success"
                          >
                            View Customer
                          </Link>
                        </span>
                      );
                    })}
                  </td>

                  <td>{contract_count}</td>
                </tr>
              );
            })}
        </Datatable>
      </Box>,
    ];
  }
}
export default ActiveContracts;
