import { Api } from '../modules/Service';
import { GetArgs } from '../modules/Helper';

const MBikeType = {
  title: 'Bike Types',
  list: () => {
    let url = '/bike_type/list';
    const model = GetArgs('model');
    const manufacturer = GetArgs('manufacturer');
    const transmission = GetArgs('transmission');
    if (model) {
      url = `/bike_type/get/model/${model}`;
    } else if (manufacturer) {
      url = `/bike_type/get/manufacturer/${manufacturer}`;
    } else if (transmission) {
      url = `/bike_type/get/transmission/${transmission}`;
    }

    return Api(url);
  },
  add: data => {
    return Api('/bike_type/new', JSON.stringify(data), 'POST', 'text');
  },
  update: data => {
    return Api(
      '/bike_type/set/' + data.id,
      JSON.stringify(data),
      'POST',
      'text',
    );
  },
  delete: id => {
    return Api('/bike_type/del/' + id, {}, 'GET', 'text');
  },
  get: id => {
    return Api('/bike_type/get/' + id);
  },
  label: (bikeType, includeTransmission = true) => {
    if (!bikeType) return '';
    const mfg = bikeType.manufacturer_name || bikeType.bike_manufacturer_name;
    const model = bikeType.model_name || bikeType.bike_model_name;
    const capacity =
      bikeType.engine_capacity ||
      bikeType.type_engine_capacity ||
      bikeType.bike_engine_capacity;

    const base = [mfg, model, capacity && `${capacity}cc`]
      .filter(Boolean)
      .join(' ');

    if (!includeTransmission) return base;

    return [base, bikeType.transmission_name].filter(Boolean).join(' - ');
  },
};
export default MBikeType;
