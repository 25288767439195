import * as React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import classnames from 'classnames';

import MCityRental from '../../models/MCityRental';
import List from '../../components/List';
import CityRentalFilters from './CityRentalFilter';
import ContractForm from './ContractForm';
import ContractFinish from './ContractFinish';

import { useFinishing } from './Contracts';

const CityRental = () => {
  const [finishing, setFinishing] = useFinishing();

  return (
    <>
      <List
        model={MCityRental}
        add
        edit
        filters={CityRentalFilters}
        editForm={ContractForm}
        numActionsPerRow={4}
        modalSize="lg"
        beforeActions={[
          ({ row }) =>
            !row.return_date && {
              id: 'finish-contract',
              title: 'Finish Contract',
              onClick: () =>
                new Promise(resolve =>
                  setFinishing({ contract: row, resolve }),
                ),
              type: 'success',
              icon: 'fa-check white',
            },
        ]}
        afterActions={[
          ({ row }) => ({
            id: 'view-communications',
            type: 'warning',
            linkTo: `/contract-communication/${row.id}?redirect=cityrental`,
            icon: 'fa-envelope',
          }),
          ({ row }) => ({
            id: 'new-communication',
            type: 'warning',
            linkTo: `/contract-communication/${
              row.id
            }?new=true&redirect=cityrental`,
            icon: (
              <>
                <i className="fa fa-envelope" /> <i className="fa fa-plus" />
              </>
            ),
          }),
        ]}
        getRowClassName={row =>
          classnames({
            overdue: new Date() > new Date(row.renew_date),
            contacted: row.communication_renew_id != null,
            responded: row.communication_renew_customer_responded,
          })
        }
        columns={[
          {
            key: 'id',
            cellRenderer: ({ cell: id }) => (
              <Link to={`/contracts?id=${id}`}>#{id}</Link>
            ),
          },
          {
            key: 'customer_email',
            cellRenderer: ({ row }) => (
              <Link to={`/customers?customer=${row.customer_id}`}>
                {row.customer_email}
              </Link>
            ),
          },
          { key: 'customer_phone_number', label: 'Customer Phone' },
          { key: 'start_location_name', label: 'Location' },
          { key: 'bike_numberplate' },
          { key: 'bike_model_name', label: 'Bike Model' },
          { key: 'bike_odometer_x10', label: 'Bike Odometer (KM)' },
          { key: 'renew_date' },
          {
            key: 'contacted',
            label: 'Contacted?',
            toText: row =>
              row.communication_renew_id == null
                ? 'No'
                : moment(row.communication_renew_timestamp).format(
                    'h:mm D MMM YYYY',
                  ),
            cellRenderer: ({ row }) =>
              row.communication_renew_id == null ? (
                'No'
              ) : (
                <React.Fragment>
                  <i className="fa fa-check white" aria-hidden="true" />
                  {moment(row.communication_renew_timestamp).format(
                    ' h:mm D MMM YYYY',
                  )}
                </React.Fragment>
              ),
          },
          { key: 'communication_renew_notes', label: 'Notes' },
          { key: 'user_username', label: 'Renew Date Updated by' },
        ]}
      />
      {finishing && (
        <ContractFinish {...finishing} afterClose={() => setFinishing(null)} />
      )}
    </>
  );
};

export default CityRental;
