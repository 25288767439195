import * as React from 'react';
import { Form, useFormState } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { keyBy } from 'lodash';

import {
  validators,
  Field,
  useFormSubmitting,
} from '../../components/FinalForm';
import { BtnDelete } from '../../components/Btn';
import Table from '../../components/Table';

const quantityFieldProps = {
  type: 'number',
  min: 1,
  step: 1,
  validate: validators.composeValidators(
    validators.minValue(1),
    validators.integer,
  ),
};

export const AddBikes = ({ options }) => {
  const { fields: requestBikes } = useFieldArray('request_bikes');
  const formState = useFormState({ subscription: { values: true } });

  const requestedBikes = keyBy(
    formState.values.request_bikes || [],
    'bike_type_id',
  );

  const filteredOptions = options
    ? options.filter(({ value }) => !requestedBikes[value])
    : [];

  return (
    <Form
      onSubmit={({ quantity, bike_type_id }) => {
        requestBikes.push({ quantity, bike_type_id });
      }}
    >
      {({ handleSubmit, invalid, values }) => (
        <form className="tgt-form bike-type-add-form" onSubmit={handleSubmit}>
          <Field
            className="bike-type-add-form--quantity"
            name="quantity"
            label="Qty"
            initialValue={1}
            {...quantityFieldProps}
          />
          <Field
            className="bike-type-add-form--bike-type"
            name="bike_type_id"
            label="Bike Type"
            select={{ options: filteredOptions }}
            disabled={!filteredOptions.length}
          />

          <button
            type="submit"
            className="btn btn-primary bike-type-add-form--submit"
            disabled={
              invalid ||
              !filteredOptions.length ||
              !values.bike_type_id ||
              requestedBikes[values.bike_type_id]
            }
          >
            Add
          </button>
        </form>
      )}
    </Form>
  );
};

export const BikeTable = ({ options }) => {
  const { fields: requestBikes } = useFieldArray('request_bikes');
  const { values } = useFormState({ subscription: { values: true } });
  const submitting = useFormSubmitting();

  const bikeTypesById = React.useMemo(() => keyBy(options || [], 'value'), [
    options,
  ]);

  const getBikeTypeName = id =>
    bikeTypesById[id] ? bikeTypesById[id].label : 'Unknown Bike Type';

  return (
    <Table
      className="col-xs-12 removeborder"
      getRowKey={row => row.id || row.bike_type_id}
      columns={[
        {
          key: 'bike_type',
          cellRenderer: ({ row }) => getBikeTypeName(row.bike_type_id),
        },
        {
          key: 'quantity',
          label: 'Qty',
          cellRenderer: ({ row, originalIndex }) => (
            <Field
              label={null}
              name={`request_bikes[${originalIndex}].quantity`}
              {...quantityFieldProps}
            />
          ),
        },
        {
          key: 'action',
          cellRenderer: ({ row, originalIndex }) => (
            <BtnDelete
              disabled={submitting}
              onClick={() => requestBikes.remove(originalIndex)}
            />
          ),
        },
      ]}
      data={values.request_bikes}
    />
  );
};
