import { Api } from '../modules/Service';

export default {
  title: 'Transport Type',
  list: () => Api('/transport_type/list'),
  add: data => Api('/transport_type/new', JSON.stringify(data), 'POST', 'text'),
  update: data =>
    Api(`/transport_type/set/${data.id}`, JSON.stringify(data), 'POST', 'text'),
  delete: id => Api(`/transport_type/del/${id}`),
  get: id => Api(`/transport_type/get/${id}`),
};
