import * as React from 'react';
import { Link } from 'react-router-dom';
import { keyBy } from 'lodash';

import { Api } from '../../modules/Service';
import MPayment from '../../models/MPayment';
import List from '../../components/List';
import { contractLabel } from './util';
import PaymentForm from './PaymentForm';
import PaymentFilters from './PaymentFilters';

const toOption = contract => ({
  value: contract.id,
  label: contractLabel(contract),
});

const useContracts = endpoint => {
  const [contracts, setContracts] = React.useState();

  React.useEffect(() => {
    const xhr = Api(`/${endpoint}/list`);

    xhr
      .then(c => setContracts(c))
      .catch(() => xhr.status !== 0 && setContracts([]));

    return () => xhr.abort();
  }, [endpoint]);

  return [
    React.useMemo(() => contracts && contracts.map(toOption), [contracts]),
    React.useMemo(() => contracts && keyBy(contracts, 'id'), [contracts]),
  ];
};

export default () => {
  const [contracts, contractsById] = useContracts('contract');
  const [contractRequests, contractRequestsById] = useContracts(
    'contract_request',
  );

  const optionParams = { contracts, contractRequests };

  return (
    <List
      loading={!contracts || !contractRequests}
      model={MPayment}
      add
      edit
      delete
      editForm={props => <PaymentForm {...optionParams} {...props} />}
      filters={props => <PaymentFilters {...optionParams} {...props} />}
      columns={[
        'id',
        { key: 'shopify_invoice_id', label: 'Shopify Invoice ID' },
        {
          key: 'contract_request_id',
          label: 'Contract Request',
          cellRenderer: ({ cell }) =>
            cell && (
              <Link to={`/contract-requests?id=${cell}`}>
                {contractLabel(contractRequestsById[cell]) || `#${cell}`}
              </Link>
            ),
        },
        {
          key: 'contract_id',
          label: 'Contract',
          cellRenderer: ({ cell }) =>
            cell && (
              <Link to={`/contracts?id=${cell}`}>
                {contractLabel(contractsById[cell]) || `#${cell}`}
              </Link>
            ),
        },
      ]}
    />
  );
};
