import React from 'react';

import { FilterForm, Field } from '../../components/FinalForm';

const ContractFilters = props => (
  <FilterForm {...props}>
    <Field
      label="Status"
      name="state"
      select={{
        options: ['All', 'Running', 'Complete'].map(label => ({
          label,
          value: label.toLowerCase(),
        })),
      }}
      defaultValue="running"
    />
    <Field label="Created Date" name="date" datePicker />
  </FilterForm>
);

export default ContractFilters;
