import React, { Component } from 'react';
import Box from '../../components/Box';
import MUser from '../../models/MUser.js';
import { BtnAdd, BtnEdit, BtnDelete, BtnView } from '../../components/Btn';
import { Datatable } from '../../components/Datatable';
import { Modal } from '../../components/Modal';
import { ViewUserGroup } from './ViewUserGroup';
import { UserGroupCheckout } from './UserGroupCheckout';

import { ShowModal, DatatableDelRow } from '../../modules/Event';
class userGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
      modalId: 'mdl-bikes-form',
      modalOpened: false,
      editData: null,
      viewModalId: 'mdl-bikes-view',
      viewModalOpened: false,
      viewData: null,
    };
  }
  renderData() {
    MUser.userGrouplist()
      .done(resp => {
        resp = Array.isArray(resp) ? resp : [resp];
        this.setState({
          loading: false,
          data: resp,
        });
      })
      .catch(xhr => {
        this.setState({
          loading: false,
          data: [],
        });
      });
  }
  componentDidMount() {
    this.renderData();
  }
  addItem(ev) {
    ev.preventDefault();
    const { modalId } = this.state;
    this.setState({ editData: null, modalOpened: true });
    ShowModal(modalId);
  }
  editItem(ev, id) {
    ev.preventDefault();
    const { modalId } = this.state;
    const elBtn = $(ev.target);
    elBtn.addClass('loading');
    MUser.getGroup(id)
      .done(resp => {
        this.setState({
          modalOpened: true,
          editData: resp,
        });
        elBtn.removeClass('loading');
        ShowModal(modalId);
      })
      .catch(xhr => {
        elBtn.removeClass('loading');
        alert(
          'Edit error #' + id + '.' + xhr.getResponseHeader('X-TBMS-Message'),
        );
      });
  }
  viewItem(ev, id) {
    ev.preventDefault();
    const { viewModalId } = this.state;
    const elBtn = $(ev.target);
    elBtn.addClass('loading');
    MUser.getGroup(id)
      .done(resp => {
        this.setState({
          viewModalOpened: true,
          viewData: resp,
        });
        elBtn.removeClass('loading');
        this.renderData();
        ShowModal(viewModalId);
      })
      .catch(xhr => {
        elBtn.removeClass('loading');
        alert(
          'Edit error #' + id + '.' + xhr.getResponseHeader('X-TBMS-Message'),
        );
      });
  }
  deleteItem(ev, id, name) {
    ev.preventDefault();
    if (window.confirm('Delete User "' + name + '" ?')) {
      const elBtn = $(ev.target);
      elBtn.addClass('loading');
      MUser.DeleteGroup(id)
        .done(() => {
          elBtn.removeClass('loading');
          const dtTbl = elBtn.closest('.tgt-datatable').attr('id');
          DatatableDelRow(dtTbl, id);
        })
        .catch(xhr => {
          elBtn.removeClass('loading');
          this.renderData();
        });
    }
  }
  render() {
    const {
      loading,
      data,
      modalId,
      modalOpened,
      editData,
      viewModalId,
      viewModalOpened,
      viewData,
    } = this.state;
    const thead = ['ID', 'Name', 'Action'];
    return [
      <Box
        key="0"
        title="User Groups"
        rightBox={<BtnAdd clickHandler={e => this.addItem(e)} />}
      >
        <Datatable loading={loading} thead={thead}>
          {data != null &&
            !loading &&
            data.map((item, idx) => {
              const { id, name } = item;
              return (
                <tr key={idx} id={'dt-item-' + id}>
                  <td>{id}</td>
                  <td>{name}</td>
                  <td className="box-action" style={{ width: 120 }}>
                    <BtnView clickHandler={e => this.viewItem(e, id)} />
                    <BtnEdit clickHandler={e => this.editItem(e, id)} />
                    <BtnDelete
                      clickHandler={e => this.deleteItem(e, id, name)}
                    />
                  </td>
                </tr>
              );
            })}
        </Datatable>
      </Box>,
      <Modal
        key="1"
        id={modalId}
        title={
          editData != null
            ? 'Edit User Group #' + editData.id
            : 'Add User Group'
        }
        size="lg"
      >
        {modalOpened && (
          <UserGroupCheckout
            modalId={modalId}
            data={editData}
            RefreshParentData={() => this.renderData()}
          />
        )}
      </Modal>,
      <Modal
        key="2"
        id={viewModalId}
        title={viewData != null ? 'View user Group #' + viewData.id : ''}
        size="md"
      >
        {viewModalOpened && <ViewUserGroup data={viewData} />}
      </Modal>,
    ];
  }
}
export default userGroup;
