/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import Box from '../../components/Box';
import MBike from '../../models/MBike';
import { YesNo, GetArgs } from '../../modules/Helper';
import { BtnAdd, BtnEdit, BtnDelete, BtnView } from '../../components/Btn';
import { Datatable } from '../../components/Datatable';
import { Modal } from '../../components/Modal';
import { BikeForm } from './BikeForm';
import { BikeView } from './BikeView';
import { BikeFilter } from './BikeFilter';
import { GetModal, ShowModal, DatatableDelRow } from '../../modules/Event';
import { Link } from 'react-router-dom';
class Bikes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: null,
      modalId: 'mdl-bikes-form',
      modalOpened: false,
      editData: null,
      viewModalId: 'mdl-bikes-view',
      viewModalOpened: false,
      viewData: null,
    };
  }
  renderData() {
    MBike.list()
      .done(resp => {
        resp = Array.isArray(resp) ? resp : [resp];
        this.setState({
          loading: false,
          data: resp,
        });
        if (resp != '') {
          if (GetArgs('data-table-search') != '') {
            jQuery('div.dataTables_wrapper div.dataTables_filter input').val(
              GetArgs('data-table-search'),
            );
          }
          jQuery('div.dataTables_wrapper div.dataTables_filter input').trigger(
            'keyup',
          );
        }
      })
      .catch(xhr => {
        this.setState({
          loading: false,
          data: [],
        });
      });
  }
  componentDidMount() {
    const { modalId, viewModalId } = this.state;
    this.renderData();
    GetModal(modalId).on('hidden.bs.modal', () => {
      this.setState({ modalOpened: false });
    });
    GetModal(viewModalId).on('hidden.bs.modal', () => {
      this.setState({ viewModalOpened: false, viewData: null });
    });
  }
  componentWillReceiveProps(nextProps) {
    //url query changes
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        loading: true,
      });
      this.renderData();
    }
  }
  addItem(ev) {
    ev.preventDefault();
    const { modalId } = this.state;
    this.setState({ editData: null, modalOpened: true });
    ShowModal(modalId);
  }
  editItem(ev, id) {
    ev.preventDefault();
    const { modalId } = this.state;
    const elBtn = $(ev.target);
    elBtn.addClass('loading');
    MBike.get(id)
      .done(resp => {
        this.setState({
          modalOpened: true,
          editData: resp,
        });
        elBtn.removeClass('loading');
        ShowModal(modalId);
      })
      .catch(xhr => {
        elBtn.removeClass('loading');
        alert(
          'Edit error #' + id + '.' + xhr.getResponseHeader('X-TBMS-Message'),
        );
      });
  }
  viewItem(ev, id) {
    ev.preventDefault();
    const { viewModalId } = this.state;
    const elBtn = $(ev.target);
    elBtn.addClass('loading');
    MBike.get(id)
      .done(resp => {
        this.setState({
          viewModalOpened: true,
          viewData: resp,
        });
        elBtn.removeClass('loading');
        ShowModal(viewModalId);
      })
      .catch(xhr => {
        elBtn.removeClass('loading');
        alert(
          'Edit error #' + id + '.' + xhr.getResponseHeader('X-TBMS-Message'),
        );
      });
  }
  deleteItem(ev, id, numberplate) {
    ev.preventDefault();
    if (window.confirm('Delete MBike "' + numberplate + '" ?')) {
      const elBtn = $(ev.target);
      elBtn.addClass('loading');
      MBike.delete(id)
        .done(() => {
          elBtn.removeClass('loading');
          const dtTbl = elBtn.closest('.tgt-datatable').attr('id');
          DatatableDelRow(dtTbl, id);
        })
        .catch(xhr => {
          elBtn.removeClass('loading');
          alert(xhr.getResponseHeader('X-TBMS-Message'));
        });
    }
  }
  render() {
    const {
      loading,
      data,
      modalId,
      modalOpened,
      editData,
      viewModalId,
      viewModalOpened,
      viewData,
    } = this.state;
    const thead = [
      'Number Plate',
      'Odometer(km)',
      'Type',
      'Ready for Hire ?',
      'Bikes Current Location',
      'Action',
    ];
    return [
      <Box
        key="0"
        title="Bike lists"
        rightBox={<BtnAdd clickHandler={e => this.addItem(e)} />}
      >
        <BikeFilter parentThis={this} />
        <Datatable loading={loading} thead={thead}>
          {data != null &&
            !loading &&
            data.map((item, idx) => {
              const {
                id,
                numberplate,
                odometer_x10,
                manufacturer_name,
                model_name,
                engine_capacity,
                transmission_name,
                ready_for_hire,
                location_name,
              } = item;
              return (
                <tr key={idx} id={'dt-item-' + id}>
                  <td>
                    <Link to={'/bike-checkins?f-numberplate=' + numberplate}>
                      {' '}
                      {numberplate}{' '}
                    </Link>
                  </td>
                  <td>{odometer_x10}</td>
                  <td>
                    {manufacturer_name} {model_name} {engine_capacity}cc -{' '}
                    {transmission_name}
                  </td>

                  <td>{YesNo(ready_for_hire)}</td>
                  <td>{location_name}</td>
                  <td className="box-action" style={{ width: 150 }}>
                    <Link
                      title="Checkin item"
                      to={'/bike-checkins/?checkInbike=' + id}
                      className="btn btn-sm btn-success"
                    >
                      <i className="fa fa-check" />
                    </Link>
                    <BtnView clickHandler={e => this.viewItem(e, id)} />
                    <BtnEdit clickHandler={e => this.editItem(e, id)} />
                    <BtnDelete
                      clickHandler={e => this.deleteItem(e, id, numberplate)}
                    />
                  </td>
                </tr>
              );
            })}
        </Datatable>
      </Box>,
      <Modal
        key="1"
        id={modalId}
        title={editData != null ? 'Edit Bike #' + editData.id : 'Add Bike'}
        size="lg"
      >
        {modalOpened && (
          <BikeForm
            modalId={modalId}
            data={editData}
            RefreshParentData={() => this.renderData()}
          />
        )}
      </Modal>,
      <Modal
        key="2"
        id={viewModalId}
        title={viewData != null ? 'View Bike #' + viewData.id : ''}
        size="md"
      >
        {viewModalOpened && <BikeView data={viewData} />}
      </Modal>,
    ];
  }
}
export default Bikes;
