/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import Numb from './Fields/Numb';
import Input from './Fields/Input';
import Hidden from './Fields/Hidden';
import ReadOnly from './Fields/ReadOnly';
import Heading from './Fields/Heading';
import Username from './Fields/Username';
import Checkbox from './Fields/Checkbox';
import Textarea from './Fields/Textarea';
import Select from './Fields/Select';
import Select2js from './Fields/Select2js';
import Select2jsAjax from './Fields/Select2jsAjax';
import AffiliateSelect2js from './Fields/AffiliateSelect2js';
import DatePicker from './Fields/DatePicker';
import StartDatePicker from './Fields/StartDatePicker';
import EndDatePicker from './Fields/EndDatePicker';
import PhotoUpload from './Fields/PhotoUpload';
import ReadonlyNumber from './Fields/ReadonlyNumber';
import ContractSelectBike from './Fields/ContractSelectBike';
import ContractSelectBike2 from './Fields/ContractSelectBike2';
import ContractSelectBike1 from './Fields/ContractSelectBike1';
import ContractSelectPlate from './Fields/ContractSelectPlate';
import ContractSelectRequest from './Fields/ContractSelectRequest';
import TransportSelect2js from './Fields/TransportSelect2js';

export class RenderForm extends Component {
  render() {
    const {
      data,
      msg,
      config,
      submitLoading,
      submitHandler,
      className,
      wizardForm,
      isNew,
      isFinish,
    } = this.props;
    var subText = '';
    if (submitLoading == true) {
      subText = 'Loading';
    } else if (isNew == true || isFinish == true) {
      subText = 'Next';
    } else if (data != null) {
      if (typeof data.id != 'undefined' && data.id != '') subText = 'Update';
      else subText = 'Submit';
    } else {
      subText = 'Submit';
    }
    return (
      <Form className={className} submitHandler={submitHandler}>
        <div className="row">
          {msg != '' && <div className="col-md-12">{msg}</div>}
          {config.map(item => {
            const clsName = Object.keys(item)[0];
            const fields = Object.values(item)[0];
            return (
              <div key={clsName} className={clsName}>
                <RenderFields data={data} fields={fields} />
              </div>
            );
          })}
          <div className="col-md-12 form-footer">
            {wizardForm != true && (
              <button
                type="button"
                className="btn btn-default fclose"
                data-dismiss="modal"
              >
                Close
              </button>
            )}
            <button
              type="submit"
              className="btn btn-primary fsubmit"
              disabled={submitLoading}
            >
              {subText}
            </button>
          </div>
        </div>
      </Form>
    );
  }
}
RenderForm.defaultProps = {
  className: '',
};
class RenderFields extends Component {
  render() {
    const { fields, data } = this.props;
    return fields.map((field, idx) => {
      const { type, render } = field;
      if (render === false) return null;
      return (
        <FormGroup key={idx} className={type}>
          {(() => {
            switch (type) {
              case 'hidden':
                return <Hidden data={data} config={field} />;
              case 'readonly':
                return <ReadOnly data={data} config={field} />;
              case 'number':
                return <Numb data={data} config={field} />;
              case 'username':
                return <Username data={data} config={field} />;
              case 'checkbox':
                return <Checkbox data={data} config={field} />;
              case 'select2js':
                return <Select2js data={data} config={field} />;
              case 'transportSelect2js':
                return <TransportSelect2js data={data} config={field} />;

              case 'AffiliateSelect2js':
                return <AffiliateSelect2js data={data} config={field} />;

              case 'select2jsajax':
                return <Select2jsAjax data={data} config={field} />;
              case 'select':
                return <Select data={data} config={field} />;
              case 'date':
                return <DatePicker data={data} config={field} />;
              case 'startdate':
                return <StartDatePicker data={data} config={field} />;
              case 'enddate':
                return <EndDatePicker data={data} config={field} />;
              case 'textarea':
                return <Textarea data={data} config={field} />;
              case 'photo_upload':
                return <PhotoUpload data={data} config={field} />;
              case 'readonly_number':
                return <ReadonlyNumber data={data} config={field} />;
              case 'heading':
                return <Heading data={data} config={field} />;
              case 'contract_select_bike':
                return <ContractSelectBike data={data} config={field} />;
              case 'contract_select_bike1':
                return <ContractSelectBike1 data={data} config={field} />;

              case 'contract_select_bike2':
                return <ContractSelectBike2 data={data} config={field} />;
              case 'contract_select_plate':
                return <ContractSelectPlate data={data} config={field} />;
              case 'contract_select_request':
                return <ContractSelectRequest data={data} config={field} />;
              default:
                return <Input data={data} config={field} />;
            }
          })()}
        </FormGroup>
      );
    });
  }
}
export class Form extends Component {
  render() {
    const { children, className, submitHandler } = this.props;
    return (
      <form className={'tgt-form ' + className} onSubmit={submitHandler}>
        {children}
      </form>
    );
  }
}
Form.defaultProps = {
  className: '',
};
export class FormGroup extends Component {
  render() {
    const { children, className } = this.props;
    return <div className={'form-group ' + className}>{children}</div>;
  }
}
FormGroup.defaultProps = {
  className: '',
};
