import React, { Component } from 'react';

class Input extends Component {
  render() {
    const { config, data } = this.props;
    const { type, label, key, required, className, default_value } = config;
    const value = data != null ? data[key] : '';
    return [
      <label key="1">{label}</label>,
      <input
        key="2"
        type={type}
        name={key}
        defaultValue={value || default_value}
        required={required}
        className={'form-control ' + (className || '')}
      />,
    ];
  }
}
export default Input;
