const Cache = {
  init() {
    if (typeof Storage === 'undefined') {
      console.log('Your browser has no Storage!');
    }
  },
  set(key, val) {
    this.init();
    localStorage.setItem(key, JSON.stringify(val));
  },
  get(key) {
    this.init();
    var val = localStorage.getItem(key);
    return typeof val !== 'undefined' ? JSON.parse(val) : '';
  },
  clear(key) {
    if (typeof key !== 'undefined') {
      localStorage.removeItem(key);
    } else {
      localStorage.clear();
    }
  },
};
export default Cache;
