import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { createSelector } from 'reselect';

import MBike from '../../models/MBike';
import MBikeType from '../../models/MBikeType';
import MReport from '../../models/MReport';
import Box from '../../components/Box';
import { BtnEdit, BtnDelete, BtnView } from '../../components/Btn';
import { Modal } from '../../components/Modal';
import { BikeForm } from './BikeForm';
import { BikeView } from './BikeView';

const modalID = 'bike-lookup-modal';

const statusTitles = {
  city_future: 'City contract',
  city_overdue: 'City contract (OVERDUE)',
  city_soon: 'City contract (ending soon)',

  ready_for_hire: 'Garage',
  repair: 'Workshop',
  return_in_progress: 'Check In in progress',

  transport_future: 'Transport',
  transport_overdue: 'Transport (OVERDUE)',
  transport_soon: 'Transport (arriving soon)',

  travel_future: 'Travel contract',
  travel_overdue: 'Travel contract (OVERDUE)',
  travel_soon: 'Travel contract (ending soon)',

  unknown: 'UNKNOWN',
};

const Warning = () => (
  <Fragment>
    <i className="fa fa-warning" />{' '}
  </Fragment>
);

const getBikeStatus = ({ currentStatus } = {}) =>
  statusTitles[currentStatus] || currentStatus;

const selectBikes = createSelector(
  state => state.bikes,
  state => state.locations,
  (bikes, locations) =>
    locations.flatMap(location =>
      Object.entries(location.bikes).flatMap(
        ([currentStatus, bikesForStatus]) =>
          bikesForStatus.map(bikeId => ({ ...bikes[bikeId], currentStatus })),
      ),
    ),
);

const isInGarage = bike =>
  ['ready_for_hire', 'repair', 'return_in_progress'].includes(
    bike.currentStatus,
  );

const selectFilteredBikes = createSelector(
  selectBikes,
  state => state.currentLocation && Number(state.currentLocation),
  state => state.currentBikeType,
  (bikes, currentLocation, currentBikeType) =>
    bikes.filter(
      bike =>
        isInGarage(bike) &&
        (!currentLocation || currentLocation === bike.location_id) &&
        (!currentBikeType || currentBikeType === MBikeType.label(bike)),
    ),
);

class BikeLookup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      bikes: {},
      crossReference: '',
      locations: [],
      locationsById: {},
      bikeTypes: [],
      currentLocation: '',
      currentBikeType: '',
      viewingBike: null,
      editingBike: null,
      deletingBike: null,
    };
  }

  componentDidMount() {
    this.fetchBikes();
  }

  fetchBikes() {
    return MReport.bikeLocation().then(({ bikes, locations }) => {
      this.setState({
        bikes,
        locations: Object.values(locations),
        locationsById: locations,
        loading: false,
        bikeTypes: [
          ...new Set(Object.values(bikes).map(bike => MBikeType.label(bike))),
        ].sort(),
      });
    });
  }

  handleView(bike) {
    this.setState({ viewingBike: bike });
  }

  handleEdit(bike) {
    this.setState({ editingBike: bike });
  }

  handleDelete(bike) {
    if (window.confirm(`Delete bike ${bike.numberplate}?`)) {
      this.setState({ deletingBike: bike });

      const resetDeleting = () => this.setState({ deletingBike: null });

      MBike.delete(bike.id)
        .then(() => this.fetchBikes())
        .then(resetDeleting)
        .catch(xhr => {
          alert(xhr.getResponseHeader('X-TBMS-Message'));
          resetDeleting();
        });
    }
  }

  renderActions(bike) {
    if (!bike) return null;
    const { viewingBike, editingBike, deletingBike } = this.state;

    const disabled = Boolean(viewingBike || editingBike || deletingBike);

    return (
      <Fragment>
        <Link
          title="Checkin bike"
          to={`/bike-checkins/?bike_id=${bike.id}`}
          className={classnames('btn btn-sm btn-success', { disabled })}
        >
          <i className="fa fa-check white" />
        </Link>
        <BtnView
          onClick={() => this.handleView(bike)}
          loading={viewingBike && viewingBike.id === bike.id}
          disabled={disabled}
        />
        <BtnEdit
          onClick={() => this.handleEdit(bike)}
          loading={editingBike && editingBike.id === bike.id}
          disabled={disabled}
        />
        <BtnDelete
          onClick={() => this.handleDelete(bike)}
          loading={deletingBike && deletingBike.id === bike.id}
          disabled={disabled}
        />
      </Fragment>
    );
  }

  getLocationCellStyle(bike) {
    if (!bike) return null;

    const location = this.state.locationsById[bike.location_id];
    if (!location) return null;

    return {
      color: '#fff',
      backgroundColor: location.color,
    };
  }

  renderBikeRows() {
    const { crossReference, currentLocation, currentBikeType } = this.state;
    const bikes = selectBikes(this.state);

    const lists = {
      notInGarage: [],
      unmatchedInGarage: new Set(selectFilteredBikes(this.state)),
      multipleMatches: [],
      filtered: [],
      matched: [],
    };

    const entries = [
      ...new Set(crossReference.split('\n').filter(entry => entry.trim())),
    ];

    entries.forEach((entry, index) => {
      const [isMatch, regionCode, stateCode, number] =
        /(\d{2})?\s*([a-zA-Z][a-zA-Z\d])?[-\s]*(\d{4}|\d{3}.\d{2})/.exec(
          entry,
        ) || [];

      const regex =
        isMatch &&
        new RegExp(`${regionCode || '..'}${stateCode || '..'}-${number}`, 'i');

      const matches = regex
        ? bikes.filter(bike => regex.test(bike.numberplate))
        : [];

      if (matches.length === 0) {
        lists.notInGarage.push({ entry, matches });
      } else if (matches.length === 1) {
        lists.unmatchedInGarage.delete(matches[0]);
        const [bike] = matches;
        let list = 'matched';

        if (!isInGarage(bike)) {
          list = 'notInGarage';
        } else if (
          (currentLocation && currentLocation !== bike.location_id) ||
          (currentBikeType && currentBikeType !== MBikeType.label(bike))
        ) {
          list = 'filtered';
        }

        lists[list].push({ entry, matches });
      } else {
        lists.multipleMatches.push({ entry, matches });
      }
    });

    const categories = [
      {
        title: 'Bikes on list, but not in garage',
        context: 'danger',
        list: lists.notInGarage,
      },
      {
        title: 'Bikes in garage, but not on list',
        context: 'danger',
        list: [...lists.unmatchedInGarage].map(match => ({ matches: [match] })),
      },
      {
        title: 'Bikes on list with multiple matches',
        context: 'warning',
        list: lists.multipleMatches,
      },
      {
        title: 'Bikes on list, but filtered by location or bike type',
        context: 'warning',
        list: lists.filtered,
      },
      { title: 'Matched bikes', context: 'success', list: lists.matched },
    ];

    const BikeColumns = ({ bike }) => {
      const bikeType = MBikeType.label(bike);
      const wrongBikeType =
        bike && currentBikeType && currentBikeType !== bikeType;
      const wrongLocation =
        bike && currentLocation && currentLocation !== bike.location_id;

      return (
        <Fragment>
          <td className={classnames({ danger: !bike })}>
            {bike ? bike.numberplate : <i>- could not find bike -</i>}
          </td>
          <td
            className={classnames({
              danger: bike && !isInGarage(bike),
            })}
          >
            {getBikeStatus(bike)}
          </td>
          <td className={classnames({ warning: wrongBikeType })}>
            {wrongBikeType && <Warning />}
            {MBikeType.label(bike)}
          </td>
          <td style={this.getLocationCellStyle(bike)}>
            {wrongLocation && <Warning />}
            {bike && bike.location_name}
          </td>
          <td>{this.renderActions(bike)}</td>
        </Fragment>
      );
    };

    return categories.map(
      ({ title, context, list }) =>
        list.length > 0 && (
          <Fragment key={title}>
            <tr>
              <th colSpan={5} className={`text-${context}`}>
                <i>{title}</i>
              </th>
            </tr>
            {list.map(({ entry, matches }) =>
              matches.length === 0 ? (
                <tr key={entry}>
                  <td>{entry}</td>
                  <BikeColumns />
                </tr>
              ) : (
                matches.map((bike, index) => (
                  <tr key={`${entry || 'no-entry'}|${bike.id}`}>
                    {!index && (
                      <td
                        rowSpan={matches.length}
                        className={classnames({ danger: !entry })}
                      >
                        {entry || <i>- not on list -</i>}
                      </td>
                    )}
                    <BikeColumns bike={bike} />
                  </tr>
                ))
              ),
            )}
          </Fragment>
        ),
    );
  }

  renderModal() {
    const { editingBike, viewingBike } = this.state;

    const title = editingBike
      ? `Edit Bike #${editingBike.id}`
      : viewingBike
      ? `View Bike #${viewingBike.id}`
      : '';

    return (
      <Modal
        id={modalID}
        title={title}
        open={Boolean(editingBike || viewingBike)}
        onClose={() => this.setState({ editingBike: null, viewingBike: null })}
        size="lg"
      >
        {editingBike ? (
          <BikeForm
            modalId={modalID}
            data={editingBike}
            RefreshParentData={() => {
              this.fetchBikes();
            }}
          />
        ) : viewingBike ? (
          <BikeView data={viewingBike} />
        ) : null}
      </Modal>
    );
  }

  render() {
    const {
      loading,
      locations,
      bikeTypes,
      crossReference,
      currentLocation,
      currentBikeType,
    } = this.state;

    return (
      <Box title="Bike Lookup" className="bike-lookup">
        <div className="row">
          <div className="col-xs-12">
            <label>Cross Reference List</label>
            <textarea
              className="form-control"
              value={crossReference}
              onChange={e => this.setState({ crossReference: e.target.value })}
            />
          </div>
        </div>
        {loading ? (
          <div
            className="col-xs-12 loading-container"
            style={{ paddingTop: 10 }}
          >
            <div className="tgt-loader" />
          </div>
        ) : (
          <Fragment>
            <div className="row">
              <div className="col-sm-6">
                <label>Location</label>
                <select
                  className="form-control select2"
                  value={currentLocation}
                  onChange={e =>
                    this.setState({
                      currentLocation: e.target.value && Number(e.target.value),
                    })
                  }
                >
                  <option value="">-- All --</option>
                  {locations.map(location => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-6">
                <label>Bike Type</label>
                <select
                  className="form-control select2"
                  value={currentBikeType}
                  onChange={e =>
                    this.setState({ currentBikeType: e.target.value })
                  }
                >
                  <option value="">-- All --</option>
                  {bikeTypes.map(bikeType => (
                    <option key={bikeType} value={bikeType}>
                      {bikeType}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Input Line</th>
                  <th>Matched Number Plate</th>
                  <th>Status</th>
                  <th>Bike Type</th>
                  <th>Current Location</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{this.renderBikeRows()}</tbody>
            </table>
          </Fragment>
        )}
        {this.renderModal()}
      </Box>
    );
  }
}

export default BikeLookup;
