import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { get } from 'lodash';
import keyBy from 'lodash/fp/keyBy';

import MTransport from '../../models/MTransport';
import MTransportPlanning from '../../models/MTransportPlanning';
import MBikeType from '../../models/MBikeType';
import { Api } from '../../modules/Service';

import TransportCheckout from './TransportCheckout';
import TransportFilter from './TransportFilter';
import MergeTransportPlans, {
  selectPlanGroups,
  groupKey,
} from './TransportPlanMerge';

import List from '../../components/List';

const useBikes = enable => {
  const [bikes, setBikes] = React.useState({});
  React.useEffect(() => {
    if (enable) {
      Api('/bike/list')
        .then(keyBy('id'))
        .then(setBikes);
    }
  }, [enable]);

  return bikes;
};

const Transport = () => {
  const match = useRouteMatch('/:report');
  const isPlanning = match.params.report === 'transport-plan';
  const bikes = useBikes(!isPlanning);

  const [mergeData, setMergeData] = React.useState(null);

  const columns = [
    { key: 'id' },
    { key: 'transport_type_name', label: 'Transport' },
    { key: 'departure_date', type: 'date' },
    { key: 'departure_location_name', label: 'Departure Location' },
    { key: 'arrival_date', type: 'date' },
    { key: 'arrival_location_name', label: 'Arrival Location' },
    isPlanning && {
      key: 'bike_types',
      toText: row =>
        row.bike_types
          .map(type => `${type.quantity} x ${MBikeType.label(type, false)}`)
          .join(', '),
      cellRenderer: ({ cell }) =>
        cell &&
        cell.map(bikeType => (
          <div key={bikeType.bike_type_id}>
            {bikeType.quantity} x {MBikeType.label(bikeType, false)}
          </div>
        )),
    },
    !isPlanning && {
      key: 'bikes',
      toText: row =>
        row.bike_ids
          .map(id => {
            const bike = bikes[id];
            return bike
              ? `${bike.numberplate} - ${MBikeType.label(bike, false)}`
              : '';
          })
          .join(', '),
      cellRenderer: ({ row }) => (
        <React.Fragment>
          <i>
            {row.bike_ids.length - row.bike_ids_unconfirmed.length} of{' '}
            {row.bike_ids.length} confirmed
          </i>
          {row.bike_ids.map(id => {
            const bike = bikes[id];
            if (!bike) return null;

            return (
              <div key={id}>
                {bike.numberplate} - {MBikeType.label(bike, false)}
                {!row.bike_ids_unconfirmed.includes(bike.id) && (
                  <span>
                    {' '}
                    <i className="fa fa-check" />
                  </span>
                )}
              </div>
            );
          })}
        </React.Fragment>
      ),
    },
    isPlanning && {
      key: 'allocated',
      type: 'check',
    },
  ].filter(Boolean);

  return (
    <React.Fragment>
      <List
        model={isPlanning ? MTransportPlanning : MTransport}
        add
        edit
        delete
        filters={TransportFilter}
        editForm={TransportCheckout}
        columns={columns}
        numActionsPerRow={3}
        beforeActions={[
          ({ row }, plans) => {
            if (isPlanning) {
              return {
                id: 'merge',
                title: 'Merge',
                type: 'warning',
                icon: 'fa-compress',
                disabled:
                  get(selectPlanGroups(plans), groupKey(row), []).length < 2,
                onClick: () =>
                  new Promise(resolve => {
                    setMergeData({ plan: row, plans, resolve });
                  }),
              };
            }

            return {
              id: 'finish',
              title: 'Finish',
              linkTo: `/transport/finish/${row.id}`,
              type: 'success',
              icon: 'fa-check white',
            };
          },
        ]}
      />
      {isPlanning && (
        <MergeTransportPlans
          columns={columns}
          {...mergeData || {}}
          afterClose={() => setMergeData(null)}
        />
      )}
    </React.Fragment>
  );
};

export default Transport;
