import React, { Component } from 'react';
class Alert extends Component {
  render() {
    const { msg, type, closeHandler } = this.props;
    return (
      <div className={'alert fade in alert-dismissable alert-' + type}>
        {closeHandler && (
          <span className="close" title="close" onClick={closeHandler}>
            ×
          </span>
        )}
        {msg}
      </div>
    );
  }
}
export default Alert;
