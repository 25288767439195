import React, { Component } from 'react';
class Select extends Component {
  render() {
    const { config, data, onChange } = this.props;
    const { label, key, options, required, default_value } = config;
    const value = data != null ? data[key] : '';
    return [
      <label key="0">{label}</label>,
      <select
        key="1"
        name={key}
        required={required}
        className="form-control select2"
        defaultValue={value || default_value}
        onChange={
          typeof onChange == 'function'
            ? onChange
            : () => {
                return false;
              }
        }
      >
        <option key="default" value="">
          Select item
        </option>
        {Object.keys(options).map(optKey => (
          <option key={optKey} value={optKey}>
            {options[optKey]}
          </option>
        ))}
      </select>,
    ];
  }
}
export default Select;
