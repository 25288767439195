import * as React from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import moment from 'moment';
import classnames from 'classnames';
import Slider from 'rc-slider';
import { createSelector } from 'reselect';
import {
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  ErrorBar,
  Tooltip,
  Line,
  LineChart,
  Dot,
  Text,
  Legend,
} from 'recharts';
import { get, omit, uniqBy } from 'lodash';

import Box from '../../components/Box';
import MReport from '../../models/MReport';
import { DateRangePicker } from '../../components/Datepicker';

const selectDatasetSlice = createSelector(
  state => state.dateRange,
  state => state.fetchedRange,
  (dateRange, fetchedRange) => [
    dateRange[0] - fetchedRange[0],
    dateRange[1] - fetchedRange[0],
  ],
);

const selectDateRange = createSelector(
  state => state.pendingDateRange,
  range => {
    const today = moment().startOf('day');
    const [start, end] = range.map(plusDays =>
      plusDays == null ? null : moment(today).add(plusDays, 'days'),
    );
    return { today, start, end };
  },
);

const selectRangeSliderValue = createSelector(
  state => state.pendingDateRange,
  range => {
    let [start, end] = range;

    if (start != null && end == null) {
      end = start + 7;
    } else if (start == null && end != null) {
      start = end - 7;
    }

    if (start < 0) start = 0;
    if (end < 0) end = 0;

    if (start > end) {
      [end, start] = [start, end];
    }

    return [start, end];
  },
);

const Range = Slider.createSliderWithTooltip(Slider.Range);

const sortedValues = items =>
  Object.keys(items)
    .map(Number)
    .sort()
    .map(id => items[id]);

const bikeModelOrder = bike =>
  ({
    '7': 1, // XR
    '2': 2, // Blade
    '14': 3, // CB 500x
    '13': 4, // Himalayan
    '6': 5, // Winner
    '10': 6, // Exciter
  }[bike.modelId] || 7);

// sort by model order then by bikeType string
const sortedBikes = bikes =>
  Object.values(bikes).sort(
    (a, b) =>
      bikeModelOrder(a) - bikeModelOrder(b) ||
      a.bikeType.localeCompare(b.bikeType),
  );

const createHoverMonitor = () => {
  let isHoverEnabled = false;
  let lastTouchTime = 0;

  const enableHover = () => {
    if (isHoverEnabled || Date.now() - lastTouchTime < 500) return;
    isHoverEnabled = true;
  };

  const disableHover = () => {
    lastTouchTime = new Date();
    if (isHoverEnabled) isHoverEnabled = false;
  };

  document.addEventListener('touchstart', disableHover, true);
  document.addEventListener('mousemove', enableHover, true);

  return {
    get isEnabled() {
      return isHoverEnabled;
    },
  };
};

const hover = createHoverMonitor();

const svgProps = (props, width) => ({
  x: props.cx - width / 2,
  y: props.cy - width / 2,
  width,
  height: width,
});

const Icons = {
  Warning: props => (
    <svg {...svgProps(props, props.active ? 32 : 24)} viewBox="0 0 24 24">
      <circle fill={props.color} cx="12" cy="12" r="10" />
      <path
        fill="white"
        d="M12,5.58A4.8,4.8,0,0,0,7.58,8a.44.44,0,0,0,.17.58l1.33,1a.41.41,0,0,0,.59-.08c.66-.83,1.16-1.33,2.16-1.33.75,0,1.75.5,1.75,1.25,0,.58-.5.83-1.25,1.25-.91.5-2.08,1.08-2.08,2.66v.17a.4.4,0,0,0,.42.42h2.16a.4.4,0,0,0,.42-.42v-.08c0-1.09,3.17-1.09,3.17-4C16.42,7.25,14.17,5.58,12,5.58Z"
      />
      <circle fill="white" cx="12" cy="17" r="1.67" />
    </svg>
  ),
  Emergency: props => (
    <svg {...svgProps(props, props.active ? 32 : 24)} viewBox="0 0 24 24">
      <path fill={props.color} d="M.76,21h22l-11-19Z" />
      <rect fill="white" x="10.76" y="16" width="2" height="2" />
      <rect fill="white" x="10.76" y="10" width="2" height="4" />
    </svg>
  ),
  Transport: props => (
    <svg {...svgProps(props, props.active ? 20 : 12)} viewBox="0 0 640 512">
      <path
        fill="white"
        d="M470.6,516.4c-53.8,0-98.6-38.5-107.6-89H277c-8.9,50.5-53.8,89-107.6,89c-53.8,0-98.6-38.5-107.6-89.1 c-34.3-1.1-61.9-29-61.9-63V67.5C0,32.7,28.7,4.4,64,4.4h301.2c35.3,0,64,28.3,64,63.1v26h22.7c16.8,0,33.3,6.7,45.2,18.5l94,92.7 c11.9,11.8,18.8,28,18.8,44.6v81.9c16.9,1.9,30.1,16,30.1,33.2V394c0,18.4-15.2,33.4-33.9,33.4h-28 C569.2,477.9,524.4,516.4,470.6,516.4z"
      />
      <path
        fill={props.color}
        d="M606.1,345h-15.1V244.8c0-11.8-4.8-23.1-13.3-31.4l-94-92.7c-8.5-8.3-20-13.1-31.9-13.1h-41.5V63.1 c0-24.6-20.2-44.5-45.2-44.5H64c-24.9,0-45.2,19.9-45.2,44.5v296.8c0,24.6,20.2,44.5,45.2,44.5h15.1c0,49.2,40.5,89,90.4,89 s90.4-39.9,90.4-89h120.5c0,49.2,40.5,89,90.4,89s90.4-39.9,90.4-89h45.2c8.3,0,15.1-6.7,15.1-14.8v-29.7 C621.2,351.7,614.4,345,606.1,345z M169.4,448.9c-24.9,0-45.2-19.9-45.2-44.5s20.2-44.5,45.2-44.5s45.2,19.9,45.2,44.5 S194.4,448.9,169.4,448.9z M470.6,448.9c-24.9,0-45.2-19.9-45.2-44.5s20.2-44.5,45.2-44.5c24.9,0,45.2,19.9,45.2,44.5 S495.5,448.9,470.6,448.9z M545.9,256H410.4V152.1h41.5l94,92.7V256z"
      />
    </svg>
  ),
  Plan: props => (
    <svg
      {...svgProps(props, props.active ? 24 : 16)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <path
        fill="white"
        d="M55.1,512C24.7,512,0,487,0,456.2V117.3c0-30.8,24.7-55.8,55.1-55.8h67.2C127,26.7,156.4-0.2,192-0.2 s65,26.9,69.7,61.6h67.2c30.4,0,55.1,25,55.1,55.8v338.9c0,30.8-24.7,55.8-55.1,55.8H55.1z"
      />
      <path
        fill={props.color}
        d="M328.9,71.1h-76c0-34-27.3-61.6-60.8-61.6s-60.8,27.6-60.8,61.6h-76c-25.2,0-45.6,20.7-45.6,46.2v338.9 c0,25.5,20.4,46.2,45.6,46.2h273.7c25.2,0,45.6-20.7,45.6-46.2V117.3C374.5,91.8,354.1,71.1,328.9,71.1z M100.8,417.6 c-12.6,0-22.8-10.3-22.8-23.1s10.2-23.1,22.8-23.1s22.8,10.3,22.8,23.1S113.4,417.6,100.8,417.6z M100.8,325.2 c-12.6,0-22.8-10.3-22.8-23.1S88.1,279,100.8,279s22.8,10.3,22.8,23.1S113.4,325.2,100.8,325.2z M100.8,232.8 c-12.6,0-22.8-10.3-22.8-23.1s10.2-23.1,22.8-23.1s22.8,10.3,22.8,23.1S113.4,232.8,100.8,232.8z M192,47.9 c12.6,0,22.8,10.3,22.8,23.1S204.6,94.2,192,94.2s-22.8-10.3-22.8-23.1S179.4,47.9,192,47.9z M313.7,402.2c0,4.2-3.4,7.7-7.6,7.7 H169.2c-4.2,0-7.6-3.5-7.6-7.7v-15.4c0-4.2,3.4-7.7,7.6-7.7h136.9c4.2,0,7.6,3.5,7.6,7.7V402.2z M313.7,309.8c0,4.2-3.4,7.7-7.6,7.7 H169.2c-4.2,0-7.6-3.5-7.6-7.7v-15.4c0-4.2,3.4-7.7,7.6-7.7h136.9c4.2,0,7.6,3.5,7.6,7.7V309.8z M313.7,217.4c0,4.2-3.4,7.7-7.6,7.7 H169.2c-4.2,0-7.6-3.5-7.6-7.7V202c0-4.2,3.4-7.7,7.6-7.7h136.9c4.2,0,7.6,3.5,7.6,7.7V217.4z"
      />
    </svg>
  ),
};

const getSingleDayIconMeta = (
  { stockEmergency, stockWarning },
  { summary: { min }, detail },
) => {
  if (min <= stockEmergency) return { priority: -2, icon: Icons.Emergency };
  if (min <= stockWarning) return { priority: -1, icon: Icons.Warning };

  const transportPriority = Math.max(
    Math.abs(detail.transport_arrive || 0),
    Math.abs(detail.transport_depart || 0),
  );
  const planPriority = Math.abs(detail.transport_plan_depart || 0);

  const priority = Math.max(planPriority, transportPriority);

  if (!priority) return { priority };

  return {
    priority,
    icon: planPriority >= transportPriority ? Icons.Plan : Icons.Transport,
  };
};

const getIconMeta = (bike, data, color, prevData) => {
  const meta = getSingleDayIconMeta(bike, data);

  if (
    prevData &&
    data.summary.min === prevData.summary.min &&
    data.summary.sum === prevData.summary.sum &&
    meta.priority === getSingleDayIconMeta(bike, prevData).priority
  ) {
    return { priority: 0 };
  }

  return { ...meta, color };
};

const CustomDot = props => {
  const prevDayPayload = props.bike.dataset[props.index - 1];
  if (props.cx == null || props.cy == null) return null;
  let iconMeta = { priority: 0 };

  if (props.onlyLine) {
    const [id] = props.dataKey.split('.');

    iconMeta = getIconMeta(
      props.bike,
      props.payload[id],
      props.fill,
      get(prevDayPayload, id),
    );
  } else {
    // get most severe warning / significant change
    Object.entries(props.payload).forEach(([id, data]) => {
      if (
        typeof data !== 'object' ||
        props.hiddenDatasets[id] ||
        ![data.summary.sum, data.summary.uncertainSum].includes(props.value)
      ) {
        return false;
      }

      const currentIconMeta = getIconMeta(
        props.bike,
        data,
        props.bike.locations[id].color,
        get(prevDayPayload, id),
      );

      if (
        (currentIconMeta.priority < 0 &&
          currentIconMeta.priority <= iconMeta.priority) ||
        (currentIconMeta.priority > 0 &&
          currentIconMeta.priority >= iconMeta.priority)
      ) {
        iconMeta = currentIconMeta;
      }
    });
  }

  return iconMeta.icon ? (
    <iconMeta.icon {...props} color={iconMeta.color} />
  ) : (
    <Dot {...props} fill={props.uncertain ? 'white' : props.fill} />
  );
};

const DatasetLine = ({
  bike,
  location,
  hiddenDatasets = {},
  errorBars,
  onlyLine,
  uncertain,
}) => {
  const hidden = hiddenDatasets[location.id];
  return (
    <Line
      key={`${location.id}${uncertain ? '-uncertain' : ''}`}
      legendType={uncertain ? 'none' : 'line'}
      animationDuration={500}
      dataKey={`${location.id}.summary.${uncertain ? 'uncertainSum' : 'sum'}`}
      name={location.name}
      data-id={location.id}
      type="linear"
      stroke={location.color}
      strokeDasharray={uncertain ? '5 5' : undefined}
      strokeWidth={2}
      strokeOpacity={hidden ? 0 : 1}
      dot={
        !hidden && (
          <CustomDot
            bike={bike}
            hiddenDatasets={hiddenDatasets}
            onlyLine={onlyLine}
            stroke={location.color}
            strokeDasharray={uncertain ? '1' : 'none'}
            fill={location.color}
            uncertain={uncertain}
          />
        )
      }
      activeDot={
        !hidden && (
          <CustomDot
            bike={bike}
            hiddenDatasets={hiddenDatasets}
            onlyLine={onlyLine}
            stroke={location.color}
            strokeDasharray={uncertain ? '2' : 'none'}
            fill={location.color}
            r={5}
            active
            uncertain={uncertain}
          />
        )
      }
    >
      {Boolean(errorBars && !hidden) && (
        <ErrorBar
          dataKey={`${location.id}.${
            uncertain ? 'uncertainErrorBars' : 'errorBars'
          }`}
          width={4}
          strokeWidth={3}
          stroke={location.color}
          strokeDasharray={uncertain ? '5 3' : undefined}
          direction="y"
        />
      )}
    </Line>
  );
};

const CustomTooltip = ({ type, payload, label, ...props }) => {
  const datapoints = [
    { key: 'stock', showWarning: true },
    { key: 'sum', showWarning: true },
    { key: 'request_outgoing' },
    { key: 'request_return' },
    { key: 'contract_return' },
    { key: 'transport_arrive' },
    { key: 'transport_depart' },
    { key: 'transport_plan_arrive' },
    { key: 'transport_plan_depart' },
  ];

  const points = uniqBy(
    (payload || [])
      .filter(data => data.strokeOpacity)
      .map(data => {
        const [id] = data.dataKey.split('.');
        const { detail, summary } = data.payload[id];

        return {
          id,
          color: data.color,
          name: data.name,
          ...detail,
          ...summary,
        };
      }),
    'id',
  );

  if (!points.length) return null;

  const runningTotal = get(payload, '0.payload.runningTotal');

  return (
    <div>
      <h5>
        <b>{label} </b>
        <i>
          Running total:{' '}
          {runningTotal < 0 ? (
            <span className="text-danger">
              <i className="fa fa-exclamation-triangle" /> {runningTotal}
            </span>
          ) : (
            runningTotal
          )}
        </i>
      </h5>
      <table>
        <thead>
          <tr>
            <th rowSpan={2}>Location</th>
            <th colSpan={2}>Day</th>
            <th colSpan={2}>Request</th>
            <th rowSpan={2}>
              Rental <br /> Returns
            </th>
            <th colSpan={2}>Transport</th>
            <th colSpan={2}>Transport Plan</th>
          </tr>
          <tr>
            <th>Start</th>
            <th>End</th>
            <th>Pickup</th>
            <th>Return</th>
            <th>Arrive</th>
            <th>Depart</th>
            <th>Arrive</th>
            <th>Depart</th>
          </tr>
        </thead>
        <tbody>
          {points.map(data => (
            <tr key={data.id}>
              <td style={{ color: data.color }}>{data.name}</td>
              {datapoints.map(({ key, showWarning }) => (
                <td
                  key={key}
                  className={classnames({
                    'text-bold text-danger': showWarning && data[key] < 0,
                  })}
                >
                  {showWarning && data[key] < 0 && (
                    <React.Fragment>
                      <i className="fa fa-exclamation-triangle" />{' '}
                    </React.Fragment>
                  )}
                  {data[key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const CustomRunningTotalTick = props => {
  const isError = props.payload.value < 0;

  if (props.errors) {
    return isError ? (
      <g transform={`translate(${props.x},${props.y})`}>
        <svg y={-14} x={-7} width={14} height={14} viewBox="0 0 24 24">
          <path fill="#a94442" d="M.76,21h22l-11-19Z" />
          <rect fill="white" x="10.76" y="16" width="2" height="2" />
          <rect fill="white" x="10.76" y="10" width="2" height="4" />
        </svg>
      </g>
    ) : null;
  }

  return isError ? null : (
    <Text {...props} className="recharts-cartesian-axis-tick-value">
      {props.payload.value}
    </Text>
  );
};

const BaseChart = React.memo(
  ({
    syncId,
    dataset,
    dateRange = [0, dataset.length],
    range: [min, max],
    className,
    children,
  }) => {
    const range = [min > 0 ? 0 : min - 5, max + 5];

    return (
      <ResponsiveContainer height={350}>
        <LineChart
          syncId={syncId}
          data={dataset.slice(dateRange[0], dateRange[1] + 1)}
          className={classnames(className, 'stock-prediction-chart')}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" angle={-45} textAnchor="end" height={65} />
          <XAxis
            xAxisId="runningTotal"
            dataKey="runningTotal"
            orientation="top"
            tick={<CustomRunningTotalTick />}
            // interval={0}
          />
          <XAxis
            xAxisId="runningTotalErrors"
            dataKey="runningTotal"
            orientation="top"
            mirror
            tick={<CustomRunningTotalTick errors />}
            tickLine={false}
            tickMargin={-6}
            tickSize={0}
            axisLine={false}
            interval={0}
          />
          <YAxis domain={range} interval={0} width={20} scale="linear" />
          <Tooltip content={<CustomTooltip />} />
          <Line
            legendType="none"
            dataKey={() => 0}
            dot={null}
            activeDot={null}
            animationDuration={0}
            stroke="#000"
          />
          {children}
        </LineChart>
      </ResponsiveContainer>
    );
  },
);

const LocationChart = React.memo(({ bike, location, dateRange }) => {
  const lineProps = {
    bike,
    location,
    errorBars: true,
    onlyLine: true,
  };
  return (
    <BaseChart
      syncId={bike.id}
      dataset={bike.dataset}
      dateRange={dateRange}
      range={location.range}
    >
      <Legend verticalAlign="top" />
      {DatasetLine(lineProps)}
      {DatasetLine({ ...lineProps, uncertain: true })}
    </BaseChart>
  );
});

const BikeChart = React.memo(({ bike, dateRange, search }) => {
  const [errorBars, setErrorBars] = React.useState({});
  const [hiddenDatasets, setHiddenDatasets] = React.useState({});

  const showErrorBars = ({ payload }) =>
    hover.isEnabled && setErrorBars(keys => ({ [payload['data-id']]: true }));

  const hideErrorBars = ({ payload }) =>
    setErrorBars(keys => ({ ...keys, [payload['data-id']]: false }));

  const toggleDataset = ({ payload }) =>
    setHiddenDatasets(keys => ({
      ...keys,
      [payload['data-id']]: keys[payload['data-id']] ? false : true,
    }));

  const values = Object.values(bike.locations).flatMap(({ range }) => range);

  return (
    <BaseChart
      className="interactive-legend"
      dataset={bike.dataset}
      dateRange={dateRange}
      range={[Math.min(...values), Math.max(...values)]}
    >
      <Legend
        verticalAlign="top"
        onMouseEnter={showErrorBars}
        onMouseLeave={hideErrorBars}
        onClick={toggleDataset}
        formatter={(value, { payload }) =>
          hiddenDatasets[payload['data-id']] ? (
            <span className="disabled">{value}</span>
          ) : (
            value
          )
        }
      />
      {sortedValues(bike.locations).flatMap(location => {
        const lineProps = {
          bike,
          location,
          hiddenDatasets,
          errorBars: errorBars[location.id],
        };

        return [
          DatasetLine(lineProps),
          DatasetLine({ ...lineProps, uncertain: true }),
        ];
      })}
    </BaseChart>
  );
});

const getDateRange = props => {
  try {
    const params = new URLSearchParams(props.location.search.replace('?', ''));
    const dateRange = (params.get('date-range') || '0,30')
      .split(',')
      .map(Number)
      .filter(number => !window.isNaN(number));
    if (dateRange.length === 2) return dateRange;
  } catch (error) {
    // fall through
  }

  return [0, 30];
};

const formatDuration = (num, string) => {
  if (!string) {
    let value = num;

    const months = Math.floor(value / 30);
    value %= 30;

    const weeks = Math.floor(value / 7);
    value %= 7;

    const days = value;

    return [
      formatDuration(months, 'month'),
      formatDuration(weeks, 'week'),
      formatDuration(days, 'day'),
    ]
      .filter(Boolean)
      .join(', ');
  }

  if (!num) return null;
  return `${num} ${string}${num === 1 ? '' : 's'}`;
};

class StockPrediction extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bikes: null,
      pendingDateRange: [0, 30],
      dateRange: [0, 30],
      fetchedRange: [0, 0],
    };
  }

  fetchData() {
    const { fetchedRange } = this.state;
    const dateRange = getDateRange(this.props);

    this.setState({ pendingDateRange: dateRange, dateRange });

    if (fetchedRange[0] <= dateRange[0] && fetchedRange[1] >= dateRange[1])
      return;

    if (this.state.xhr) this.state.xhr.abort();

    const xhr = MReport.stockPrediction({
      date: moment
        .utc()
        .add(dateRange[0], 'days')
        .format('YYYY-MM-DD'),
      limit_future: dateRange[1] - dateRange[0],
    });

    this.setState({ xhr, bikes: null });

    xhr.then(data => {
      const bikes = {};

      data.forEach(
        ({
          bike_type_id: id,
          bike_model_id: modelId,
          bike_manufacturer_name: manufacturer,
          bike_model_name: model,
          bike_type_engine_capacity: capacity,
          bike_transmission_name: transmission,
          bike_type_stock_emergency: stockEmergency,
          bike_type_stock_warning: stockWarning,
          location_id: locationId,
          location_name: location,
          location_color: color,
          forecast,
        }) => {
          if (!bikes[id]) {
            bikes[id] = {
              id,
              modelId,
              stockEmergency: stockEmergency == null ? 0 : stockEmergency,
              stockWarning: stockWarning == null ? 5 : stockWarning,
              bikeType: `${manufacturer} ${model} (${capacity} cc, ${transmission})`,
              dataset: forecast.map(({ date }) => ({ date, runningTotal: 0 })),
              locations: {},
            };
          }

          const values = forecast.flatMap(({ summary }) => [
            summary.min,
            summary.max,
          ]);

          bikes[id].locations[locationId] = {
            id: locationId,
            name: location,
            color,
            range: [Math.min(...values), Math.max(...values)],
          };

          let certain = true;

          forecast.forEach(({ summary, detail_diff, detail }, index) => {
            const { sum } = summary;
            const daySummary = omit(summary, ['sum']);

            bikes[id].dataset[index].runningTotal += detail_diff.stock || 0;

            if (certain) {
              daySummary.sum = sum;
              certain =
                detail.transport_plan_arrive === 0 &&
                detail.transport_plan_depart === 0;
            }

            if (!certain) {
              daySummary.uncertainSum = sum;
            }

            const errorBars = [sum - summary.min, summary.max - sum];

            bikes[id].dataset[index][locationId] = {
              summary: daySummary,
              detail: detail_diff,
              [certain ? 'errorBars' : 'uncertainErrorBars']: errorBars,
            };
          });
        },
      );

      this.setState({ xhr: null, bikes, fetchedRange: dateRange });
    });
  }

  componentWillMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.bikeTypeId != null &&
      prevProps.match.params.bikeTypeId == null
    ) {
      window.scrollTo(0, 0);
    }

    if (this.props.location.search !== prevProps.location.search) {
      this.fetchData();
    }
  }

  getSearch() {
    return `?date-range=${this.state.pendingDateRange.join(',')}`;
  }

  renderCharts() {
    const { bikes } = this.state;
    if (!bikes) return <div style={{ marginTop: 10 }} className="tgt-loader" />;

    const { bikeTypeId } = this.props.match.params;
    const search = this.getSearch();

    const datasetSlice = selectDatasetSlice(this.state);

    if (bikeTypeId == null) {
      return sortedBikes(bikes).map(bike => (
        <React.Fragment key={bike.id}>
          <h4>
            <Link to={`/stock-prediction/${bike.id}${search}`}>
              {bike.bikeType}
            </Link>
          </h4>
          <BikeChart bike={bike} dateRange={datasetSlice} />
        </React.Fragment>
      ));
    }

    const bike = bikes[bikeTypeId];

    if (!bike) return <Redirect to="/stock-prediction" />;

    return (
      <React.Fragment>
        <h4>{bike.bikeType}</h4>
        <h5>
          <Link to={`/stock-prediction${search}`}>Back to all bikes</Link>
        </h5>
        {sortedValues(bike.locations).map(location => (
          <LocationChart
            key={location.id}
            bike={bike}
            location={location}
            dateRange={datasetSlice}
          />
        ))}
      </React.Fragment>
    );
  }

  renderControls() {
    const range = selectRangeSliderValue(this.state);

    const maxRange = Math.max(90, ...range);
    const marks = {
      0: 'Today',
      14: '2 weeks',
      30: '1 month',
      60: '2 months',
      90: {
        style:
          maxRange === 90 ? { right: 0, transform: null, left: null } : null,
        label: '3 months',
      },
    };

    const dates = selectDateRange(this.state);

    return (
      <div className="tgt-form">
        <div className="form-group">
          <label htmlFor="limit-future-slider">Date Range</label>
          <div className="row">
            <div className="col-sm-6">
              <DateRangePicker
                startDate={dates.start}
                endDate={dates.end}
                minimumNights={7}
                onDatesChange={({ startDate, endDate }) => {
                  this.setState({
                    pendingDateRange: [startDate, endDate].map(
                      date =>
                        date &&
                        Math.floor(
                          moment.duration(date.diff(dates.today)).as('days'),
                        ),
                    ),
                  });
                }}
              />
            </div>
            <dl className="col-sm-6">
              <dt>Duration</dt>
              <dd>{formatDuration(range[1] - range[0])}</dd>
            </dl>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <Range
            style={{ margin: '0 10px', flex: 1 }}
            min={0}
            max={maxRange}
            marks={marks}
            value={range}
            step={1}
            pushable={7}
            onChange={pendingDateRange => this.setState({ pendingDateRange })}
            tipFormatter={value => {
              if (value === 0) return 'Today';
              if (value === 1) return 'Tomorrow';
              return formatDuration(value);
            }}
          />
          <Link to={this.getSearch()} className="btn btn-primary">
            Update
          </Link>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Box title="Stock Prediction">
        {this.renderControls()}
        {this.renderCharts()}
      </Box>
    );
  }
}

export default withRouter(StockPrediction);
