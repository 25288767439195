import BaseModel from './BaseModel';
import { Api } from '../modules/Service';

export default {
  ...BaseModel,

  endpoint: '/bike_transport_plan',
  title: 'Transport Plan',
  defaultListValues: { state: 'planned' },
  validListFilters: [
    'id',
    'departure_location_id',
    'arrival_location_id',
    'state',
    'bike_type_id',
  ],

  merge(recipientId, donorIds) {
    const params = new URLSearchParams({
      donor_ids: donorIds.join(','),
    });

    return Api(
      `${this.endpoint}/merge/${recipientId}?${params}`,
      {},
      'GET',
      'text',
    );
  },

  label: ({
    departure_location_name: departure,
    arrival_location_name: arrival,
    bike_types: bikeTypes,
    departure_date: departureDate,
    arrival_date: arrivalDate,
  }) => {
    const numBikes = bikeTypes.reduce((sum, { quantity }) => sum + quantity, 0);
    const bikes = numBikes === 1 ? 'bike' : 'bikes';
    const departs = numBikes === 1 ? 'departs' : 'depart';

    return `${numBikes} ${bikes} ${departs} ${departure} on ${departureDate}, arriving in ${arrival} ${arrivalDate}`;
  },
};
