import moment from 'moment';

import { Api } from '../modules/Service';
import { GetArgs } from '../modules/Helper';

const MContractRequest = {
  title: 'Contract Request',
  list: ({
    id,
    customer,
    from_date,
    to_date,
    start_location_id,
    bike_type_id,
    state = 'planned',
  } = {}) => {
    if (id) {
      return MContractRequest.get(id);
    }

    if (customer) {
      return MContractRequest.getContractRequestbyCustomerID(customer);
    }

    const params = { limit_past: 0, by: 'start', state };

    if (start_location_id) Object.assign(params, { start_location_id });
    if (bike_type_id) Object.assign(params, { bike_type_id });

    if (from_date && !to_date) {
      params.date = from_date;
      params.limit_past = 0;
    } else if (to_date && !from_date) {
      params.date = to_date;
      params.limit_future = 0;
      delete params.limit_past;
    } else if (from_date && to_date) {
      params.date = from_date;
      params.limit_future = Math.round(
        moment
          .duration(
            moment(to_date)
              .startOf('day')
              .diff(moment(to_date).startOf('day')),
          )
          .as('days'),
      );
    }

    return Api(`/contract_request/list?${new URLSearchParams(params)}`);
  },
  listbylocation: locationID => {
    const contract_request = GetArgs('contract_request');
    if (contract_request) {
      return MContractRequest.get(contract_request);
    }

    const customer = GetArgs('customer');
    if (customer) {
      return MContractRequest.getContractRequestbyCustomerID(customer);
    }

    return Api(`/contract_request/list?start_location_id=${locationID}`);
  },
  listbymodel: modelID => {
    const contract_request = GetArgs('contract_request');
    if (contract_request) {
      return MContractRequest.get(contract_request);
    }

    const customer = GetArgs('customer');
    if (customer) {
      return MContractRequest.getContractRequestbyCustomerID(customer);
    }

    return Api(`/contract_request/list?bike_type_id=${modelID}`);
  },
  add: data => {
    return Api('/contract_request/new', JSON.stringify(data), 'POST', 'text');
  },
  update: data => {
    return Api(
      '/contract_request/set/' + data.id,
      JSON.stringify(data),
      'POST',
      'text',
    );
  },
  delete: id => {
    return Api('/contract_request/del/' + id, {}, 'GET', 'text');
  },
  get: id => {
    return Api('/contract_request/get/' + id);
  },
  getContractRequestbyCustomerID: id => {
    return Api('/contract_request/get/customer/' + id);
  },

  label: ({
    start_location_name: departure,
    return_location_name: arrival,
    request_bikes: bikeTypes = [],
    start_date: departureDate,
    return_date_estimated: arrivalDate,
  }) => {
    const numBikes = bikeTypes.reduce((sum, { quantity }) => sum + quantity, 0);
    const bikes = numBikes === 1 ? 'bike' : 'bikes';
    const departs = numBikes === 1 ? 'departs' : 'depart';

    return `${numBikes} ${bikes} ${departs} ${departure} on ${departureDate}, arriving in ${arrival} around ${arrivalDate}`;
  },
};

export default MContractRequest;
