import * as React from 'react';

import MLocation from '../../models/MLocation';
import LocationForm from './LocationForm';
import LocationFilters from './LocationFilters';
import List from '../../components/List';
import { isValidURL } from '../../modules/Helper';

export default () => (
  <List
    add
    edit
    delete
    model={MLocation}
    filters={LocationFilters}
    editForm={LocationForm}
    columns={[
      'id',
      {
        key: 'name',
        label: 'Location',
        cellRenderer: ({ row }) => (
          <React.Fragment>
            {row.color && (
              <div
                className="location-color"
                style={{ backgroundColor: row.color }}
              />
            )}
            {row.name}
          </React.Fragment>
        ),
      },
      'address',
      {
        key: 'review_url',
        label: 'Review URL',
        cellRenderer: ({ row }) =>
          isValidURL(row.review_url) ? (
            <a href={row.review_url} target="_blank" rel="noopener noreferrer">
              {row.review_url}
            </a>
          ) : (
            row.review_url
          ),
      },
    ]}
    modalSize="sm"
  />
);
