import React, { Component } from 'react';
import { Link } from 'react-router-dom';
const StateList = [
  { name: 'available', title: 'Available', keys: ['ready_for_hire'] },
  { name: 'workshop', title: 'Workshop', keys: ['repair'] },
  {
    name: 'return_complete',
    title: 'Return Complete',
    keys: ['return_complete'],
  },
  {
    name: 'transport',
    title: 'Transport',
    keys: ['transport_overdue', 'transport_soon', 'transport_future'],
  },
  {
    name: 'city_rental',
    title: 'City Rental',
    keys: ['city_overdue', 'city_soon', 'city_future'],
  },
  {
    name: 'travel',
    title: 'Travel',
    keys: ['travel_future', 'travel_soon', 'travel_overdue'],
  },
  { name: 'no_checkin', title: 'No Checkin', keys: ['return_in_progress'] },
  {
    name: 'city',
    title: 'City',
    keys: ['recurring_overdue', 'recurring_soon', 'recurring_future'],
    sub_cols: [{ cell_text: 'latest_records.contract.renew_date' }],
  },
  { name: 'unknown', title: 'Unknown', keys: ['unknown'] },
];

const ColumnBlock = ({ item, bikes, locations }) => {
  const userData = JSON.parse(localStorage.getItem('userLogin'));

  if (
    userData.permission_level === 5 &&
    Number(item.idloc) !== Number(userData.default_location_id)
  ) {
    return false;
  }

  const location = locations[item.idloc];

  return (
    <div className="column-block">
      <h2 style={{ backgroundColor: location && location.color }}>
        {item.title} ({item.bikeCount})
      </h2>
      <RowBlock item={item.state} bikes={bikes} />
    </div>
  );
};

const RowBlock = props => {
  const { item, bikes } = props;
  return (
    <div>
      {item.map((itmst, idst) => {
        const rowId = itmst.uniqueKey;
        return (
          <div className="row-block" key={idst}>
            <h4 className={itmst.idst}>
              <a
                href={'#' + rowId}
                className="collapsed"
                data-toggle="collapse"
              >
                {itmst.title} ({itmst.bikeCount})
              </a>
            </h4>
            <div id={rowId} className="collapse">
              <table className="table table-hover table-striped">
                <tbody>
                  {itmst.bikes.map((itmbk, idbk) => {
                    const bike = bikes[itmbk];
                    var checkin_id = bike.status.checkin_id;
                    var ContractID = bike.status.contract_id;
                    var transport_id = bike.status.transport_id;
                    if (typeof bike == 'undefined') {
                      return (
                        <tr key={idbk}>
                          <td>
                            <b>Bike missing!</b>
                          </td>
                        </tr>
                      );
                    }
                    return (
                      <tr key={idbk}>
                        <td className="bike-item">
                          <div className="bike-title">
                            <a
                              href={'#bike-' + rowId + '-' + bike.id}
                              className="collapsed"
                              data-toggle="collapse"
                            >
                              {bike.model_name}-{bike.numberplate}
                            </a>
                          </div>
                          <div
                            id={'bike-' + rowId + '-' + bike.id}
                            className="collapse bike-add-info"
                          >
                            {itmst.sub_cols.map((itmsc, idsc) => {
                              var sbtxt = bike;
                              itmsc.cell_text.split('.').forEach(itmcelltxt => {
                                if (sbtxt != null) {
                                  sbtxt = sbtxt[itmcelltxt];
                                }
                              });
                              return <p key={idsc}>{sbtxt}</p>;
                            })}

                            {ContractID != null && (
                              <Link
                                to={'/contracts?id=' + ContractID}
                                className="btn-sm-view"
                              >
                                View Contract
                              </Link>
                            )}
                            {transport_id != null && (
                              <Link
                                to={'/transport?id=' + transport_id}
                                className="btn-sm-view"
                              >
                                View Transport
                              </Link>
                            )}
                            {checkin_id != null && (
                              <Link
                                to={'/bike-checkins?id=' + checkin_id}
                                className="btn-sm-view"
                              >
                                View Checkin
                              </Link>
                            )}

                            <Link
                              to={'/bikes?bike=' + bike.id}
                              className="btn-sm-view"
                            >
                              View Bike
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
    </div>
  );
};
class BikeLocationRender extends Component {
  constructor(props) {
    super(props);
    const { data } = props;
    var dataLocation = [];
    const arrBikeLocation = Object.values(data.locations);
    const arrBikeLocationKey = Object.keys(data.locations);

    arrBikeLocation.forEach((itmloc, idloc) => {
      var locInfo = {
        idloc: arrBikeLocationKey[idloc],
        title: itmloc.name,
        state: [],
        bikeCount: 0,
      };

      StateList.forEach((itmst, idst) => {
        var locState = {
          idst: itmst.name,
          uniqueKey: 'row-' + idloc + idst,
          title: itmst.title,
          sub_cols: typeof itmst.sub_cols != 'undefined' ? itmst.sub_cols : [],
          bikes: [],
          bikeCount: 0,
        };

        itmst.keys.forEach((itmkey, idkey) => {
          const bikeInState = itmloc.bikes[itmkey];
          if (bikeInState != null) {
            if (bikeInState.length > 0) {
              Array.prototype.push.apply(locState['bikes'], bikeInState);
            }
          }
        });
        locState['bikeCount'] = locState['bikes'].length;
        locInfo['bikeCount'] =
          parseInt(locInfo['bikeCount']) + parseInt(locState['bikeCount']);
        if (locState['bikeCount'] > 0) locInfo['state'].push(locState);
      });
      if (locInfo['bikeCount'] > 0) dataLocation.push(locInfo);
    });
    this.state = {
      dataLocation: dataLocation,
    };
  }
  render() {
    const { dataLocation } = this.state;
    const { data } = this.props;
    if (data == null || data.length < 0) {
      return <p className="text-center">Data not found !</p>;
    }
    return (
      <div className="box-block">
        {dataLocation.map((item, idx) => {
          return (
            <ColumnBlock
              key={idx}
              item={item}
              bikes={data.bikes}
              locations={data.locations}
            />
          );
        })}
      </div>
    );
  }
}
export default BikeLocationRender;
