/* eslint-disable eqeqeq */

import { Api } from '../modules/Service';
import { GetArgs } from '../modules/Helper';

const MUser = {
  list: () => {
    var url = '/user/list';
    const user = GetArgs('user');
    if (user != '') {
      url = '/user/get/' + user;
    }
    return Api(url);
  },
  add: data => {
    return Api('/user/new', JSON.stringify(data), 'POST', 'text');
  },
  update: data => {
    return Api('/user/set/' + data.id, JSON.stringify(data), 'POST', 'text');
  },
  delete: id => {
    return Api('/user/del/' + id, {}, 'GET', 'text');
  },
  get: id => {
    return Api('/user/get/' + id);
  },
  setPassword: data => {
    return Api('/auth/change_password', JSON.stringify(data), 'POST', 'text');
  },

  userGrouplist: () => {
    var url = '/user_group/list';
    const user = GetArgs('user');
    if (user != '') {
      url = '/user/get/' + user;
    }
    return Api(url);
  },
  addGroup: data => {
    return Api('/user_group/new', JSON.stringify(data), 'POST', 'text');
  },
  getGroup: id => {
    return Api('/user_group/get/' + id);
  },
  DeleteGroup: id => {
    return Api('/user_group/del/' + id);
  },
  updateGroup: data => {
    return Api(
      '/user_group/set/' + data.id,
      JSON.stringify(data),
      'POST',
      'text',
    );
  },
};
export default MUser;
