import React, { Component } from 'react';
import { Api } from '../modules/Service';
import Cache from '../modules/Cache';
class Logout extends Component {
  constructor(props) {
    super(props);
    Api('/auth/logout', {}, 'GET', 'text').done(data => {
      Cache.clear();
      this.props.history.push('/login');
    });
  }
  render() {
    return <p className="text-center">Logging out...</p>;
  }
}
export default Logout;
