import { Api } from '../modules/Service';

const MBikeManufacturer = {
  list: () => {
    return Api('/bike_manufacturer/list');
  },
  add: data => {
    return Api('/bike_manufacturer/new', JSON.stringify(data), 'POST', 'text');
  },
  update: data => {
    return Api(
      '/bike_manufacturer/set/' + data.id,
      JSON.stringify(data),
      'POST',
      'text',
    );
  },
  delete: id => {
    return Api('/bike_manufacturer/del/' + id, {}, 'GET', 'text');
  },
  get: id => {
    return Api('/bike_manufacturer/get/' + id);
  },
};
export default MBikeManufacturer;
