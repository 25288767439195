import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  useForm,
  useFormState,
  useField,
  Field as FinalFormField,
} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FORM_ERROR } from 'final-form';
import { startCase, isEqual, omit, has, merge, get, set } from 'lodash';
import ReactSelect from 'react-select';
import ReactCreatableSelect from 'react-select/creatable';
import Fuse from 'fuse.js';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import Alert from './Alert';
import { Api } from '../modules/Service';
import { useUUID, useThrottledState } from '../modules/Hooks';
import { DatePicker, DateRangePicker } from './Datepicker';
import PhotoUpload from './Fields/PhotoUpload';
import ImgView from './ImgView';

const nameToLabel = (name = '') => startCase(name.replace(/[_\- ]id$/, ''));

const isInvalid = ({ errors, submitErrors, dirtySinceLastSubmit }) =>
  Boolean(
    (errors && Object.keys(errors).length > 0) ||
      (submitErrors &&
        Object.keys(submitErrors).length > 0 &&
        !dirtySinceLastSubmit),
  );

const FormControllerContext = React.createContext();

export const validators = {
  required: value => (value ? undefined : 'Required'),
  composeValidators: (...validatorArray) => value =>
    validatorArray.reduce(
      (error, validator) => error || validator(value),
      undefined,
    ),
  minValue: min => value =>
    isNaN(value) || value >= min ? undefined : `Should be at least ${min}`,
  integer: value => (value % 1 ? 'Must be an integer' : undefined),
  email: value =>
    /.+@.+/i.test(value) ? undefined : 'Must be a valid email address',
};

const defaultToOption = ({ id, name }) => ({ value: id, label: name });

export const Select = ({
  endpoint,
  toOption = defaultToOption,
  value,
  disabled,
  creatable,
  placeholder,
  options: providedOptions,
  ...rest
}) => {
  const [asyncData, setAsyncData] = React.useState(null);
  const [inputValue, setInputValue] = useThrottledState('', 300);
  const asyncOptions = React.useMemo(
    () => asyncData && toOption && asyncData.map(toOption),
    [asyncData, toOption],
  );
  const options = providedOptions || asyncOptions;
  const filteredOptions = React.useMemo(() => {
    const term = inputValue.trim();

    if (term && options) {
      const fuse = new Fuse(options, {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ['value', 'label'],
      });

      return fuse.search(term);
    }

    return options || [];
  }, [inputValue, options]);

  const selected = React.useMemo(() => {
    if (options && (typeof value === 'number' || typeof value === 'string')) {
      const strValue = `${value}`;
      return options.find(option => `${option.value}` === strValue) || null;
    }

    return value || null;
  }, [options, value]);

  React.useEffect(() => {
    if (endpoint) {
      const xhr = Api(endpoint);
      xhr.then(data => setAsyncData(data));

      return () => xhr.abort();
    }
  }, [endpoint]);

  const SelectComponent = creatable ? ReactCreatableSelect : ReactSelect;

  return (
    <SelectComponent
      options={filteredOptions.slice(0, 50)}
      isLoading={!options}
      isDisabled={disabled || rest.readOnly || (!options && !creatable)}
      onInputChange={newValue => setInputValue(newValue)}
      value={selected}
      placeholder={value && !options ? 'Loading...' : placeholder}
      filterOption={null}
      className="react-select-container"
      classNamePrefix="react-select"
      createOptionPosition="first"
      {...rest}
    />
  );
};

export const useFormSubmitting = () => {
  const pageState = useFormState({ subscription: { submitting: true } });
  const { loading, controller = {} } =
    React.useContext(FormControllerContext) || {};
  const firstRender = React.useRef(true);

  // synchronously register and unregister to query field state for our subscription on first render
  const [controllerState, setState] = React.useState(() => {
    let initialState = {};

    if (controller.form) {
      controller.form.subscribe(
        state => {
          initialState = state;
        },
        { submitting: true },
      )();
    } else {
      // if controller changes later, update changes immediately
      firstRender.current = false;
    }

    return initialState;
  });

  React.useEffect(
    () =>
      controller.form &&
      controller.form.subscribe(
        newState => {
          if (firstRender.current) {
            firstRender.current = false;
          } else {
            setState(newState);
          }
        },
        { submitting: true },
      ),
    [controller.form],
  );

  return Boolean(loading || controllerState.submitting || pageState.submitting);
};

export const useWizardPageValues = () => {
  const { controller } = React.useContext(FormControllerContext) || {};

  return (controller && controller.values && controller.values.values) || {};
};

const parseDate = value => (value ? value.format('YYYY-MM-DD') : undefined);
const formatDate = value => (value ? moment(value, 'YYYY-MM-DD') : null);

const DatePickerField = ({ id, name, datePicker, disabled }) => {
  const field = useField(name);
  const form = useForm();

  return (
    <DatePicker
      disabled={disabled}
      date={formatDate(field.input.value)}
      id={id}
      onDateChange={date => {
        field.input.onChange(parseDate(date));
      }}
      onBlur={React.useCallback(() => form.blur(name), [name, form])}
      showClearDate
      isOutsideRange={() => false}
      placeholder={nameToLabel(name)}
      {...datePicker}
    />
  );
};

const DateRangePickerField = ({ id, name, dateRangePicker, disabled }) => {
  const from = useField(name[0]);
  const to = useField(name[1]);
  const form = useForm();

  return (
    <DateRangePicker
      disabled={disabled}
      startDate={formatDate(from.input.value)}
      startDateId={id}
      endDate={formatDate(to.input.value)}
      onDatesChange={({ startDate, endDate }) => {
        from.input.onChange(parseDate(startDate));
        to.input.onChange(parseDate(endDate));
      }}
      onBlur={React.useCallback(() => form.blur(`${name}`), [name, form])}
      showClearDates
      isOutsideRange={() => false}
      startDatePlaceholderText={nameToLabel(name[0])}
      endDatePlaceholderText={nameToLabel(name[1])}
      {...dateRangePicker}
    />
  );
};

const PhotoField = ({ disabled, name, value, onChange }) =>
  disabled ? (
    value ? (
      <ImgView id={value} size="260px" />
    ) : (
      <h5>No image</h5>
    )
  ) : (
    <PhotoUpload
      config={{ key: name, default_value: value }}
      onChange={onChange}
    />
  );

export const Field = ({
  name,
  label = nameToLabel(name),
  className,
  style,
  select,
  datePicker,
  dateRangePicker,
  defaultValue,
  disabled,
  fieldConfig = {},
  ...rest
}) => {
  const id = useUUID();
  const { values, initialValues } = useFormState({
    subscription: {
      values: typeof disabled === 'function',
      initialValues: true,
    },
  });
  const { input, meta } = useField(`${name || id}`, {
    parse: select ? option => option && option.value : undefined,
    ...(has(initialValues, name) ? {} : { initialValue: defaultValue }),
    ...fieldConfig,
    ...rest,
  });

  const inputProps = {
    disabled:
      useFormSubmitting() ||
      rest.readOnly ||
      (typeof disabled === 'function' ? disabled(values) : disabled),
    ...input,
    ...omit(rest, ['validate', 'initialValue']),
    id,
  };

  if (meta.error) {
    inputProps['aria-describedby'] = `${id}-help-block`;
  }

  if (dateRangePicker && !Array.isArray(name)) {
    console.error(
      'The `name` prop must be an array for a dateRangePicker field',
    );
    return null;
  }

  return (
    <div
      className={classnames(className, 'form-group', {
        'has-error': meta.error,
        'checkbox icheck-like': inputProps.type === 'checkbox',
        'date-picker': datePicker || dateRangePicker,
        disabled: inputProps.disabled,
      })}
      style={style}
    >
      {(label || inputProps.type === 'checkbox') && (
        <label htmlFor={id}>
          {inputProps.type === 'checkbox' && (
            <>
              <input {...inputProps} />
              <div className="styled-checkbox" />
            </>
          )}
          {label}
        </label>
      )}
      {select ? (
        <Select
          {...inputProps}
          {...select}
          onChange={(...args) => {
            inputProps.onChange(...args);
            if (select.onChange) select.onChange(...args);
          }}
        />
      ) : datePicker ? (
        <DatePickerField
          id={id}
          name={name}
          datePicker={datePicker}
          disabled={inputProps.disabled}
        />
      ) : dateRangePicker ? (
        <DateRangePickerField
          id={id}
          name={name}
          dateRangePicker={dateRangePicker}
          disabled={inputProps.disabled}
        />
      ) : inputProps.type === 'photo' ? (
        <PhotoField {...inputProps} />
      ) : inputProps.type === 'textarea' ? (
        <textarea className="form-control" rows={5} {...inputProps} />
      ) : (
        inputProps.type !== 'checkbox' && (
          <input className="form-control" {...inputProps} />
        )
      )}
      {meta.touched && typeof meta.error === 'string' && (
        <span id={`${id}-help-block`} className="help-block">
          {meta.error}
        </span>
      )}
    </div>
  );
};

export const Heading = ({ title }) => (
  <div className="col-xs-12">
    <h4 className="field_heading">{title}</h4>
  </div>
);

export const EditForm = ({
  data,
  onSubmit,
  children,
  title,
  validate,
  loading,
  submitText = data.id ? 'Update' : 'Submit',
}) => {
  const [message, setMessage] = React.useState(null);
  const mounted = React.useRef(true);

  React.useEffect(() => () => (mounted.current = false), []);

  return (
    <Form
      validate={validate}
      mutators={arrayMutators}
      onSubmit={values =>
        onSubmit({
          ...values,
          // send a null for keys that were removed from the initial values
          ...Object.fromEntries(
            Object.keys(data || {})
              .filter(key => !(key in values))
              .map(key => [key, null]),
          ),
        })
          .then(errors => {
            if (mounted.current && !errors) {
              setMessage({
                type: values.id ? 'info' : 'success',
                value: `${title} ${
                  values.id ? 'updated' : 'added'
                } successfully`,
              });
            }

            return errors;
          })
          .catch(xhrOrError => {
            console.log(xhrOrError);
            const errorMessage =
              xhrOrError &&
              (xhrOrError.getResponseHeader
                ? xhrOrError.getResponseHeader('X-TBMS-Message')
                : xhrOrError.message);

            return { [FORM_ERROR]: `Error: ${errorMessage}` };
          })
      }
      initialValues={data}
      render={({ handleSubmit, submitting, pristine, ...rest }) => (
        <FormControllerContext.Provider value={{ loading }}>
          <form className="tgt-form" onSubmit={handleSubmit}>
            <div className="row">
              {message && (
                <div className="col-xs-12">
                  <Alert
                    msg={message.value}
                    type={message.type}
                    closeHandler={() => setMessage(null)}
                  />
                </div>
              )}
              <FormError setSubmissionError={setMessage} />
              {children}
              <div className="col-xs-12 form-footer">
                <button
                  type="button"
                  className="btn btn-default fclose"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={submitting || isInvalid(rest) || pristine}
                >
                  {submitting ? 'Loading' : submitText}
                </button>
              </div>
            </div>
          </form>
        </FormControllerContext.Provider>
      )}
    />
  );
};

EditForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  validate: PropTypes.func,
  title: PropTypes.string,
  loading: PropTypes.bool,
  submitText: PropTypes.string,
};

EditForm.defaultProps = {
  title: 'Item',
};

export const FilterForm = withRouter(
  ({ data, children, location, history, dataTableSearch, loading }) => (
    <Form
      mutators={arrayMutators}
      onSubmit={(values, form) => {
        const entries = Object.entries({
          ...values,
          'data-table-search': dataTableSearch,
        }).filter(([key, value]) => value);

        const params = new URLSearchParams(Object.fromEntries(entries));

        history.push([location.pathname, params].join('?'));
      }}
      initialValues={React.useMemo(
        () => ({
          ...(data || {}),
          ...Object.fromEntries(new URLSearchParams(location.search).entries()),
        }),
        [data, location.search],
      )}
      render={({
        values,
        handleSubmit,
        submitting,
        pristine,
        form,
        ...rest
      }) => (
        <FormControllerContext.Provider value={{ loading }}>
          <form className="tgt-form tgt-form--filter" onSubmit={handleSubmit}>
            <div className="row">
              {children}
              <button
                type="submit"
                className="btn btn-primary btn-sm tgt-form--filter--action"
                disabled={submitting || isInvalid(rest) || pristine}
              >
                <i className="fa fa-filter" />{' '}
                {submitting ? 'Loading' : 'Filter'}
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm tgt-form--filter--action"
                disabled={submitting || isEqual(data || {}, values)}
                onClick={() => {
                  if (location.search) {
                    history.push(location.pathname);
                  } else {
                    form.reset();
                  }
                }}
              >
                {submitting ? 'Loading' : 'Clear Filter'}
              </button>
            </div>
          </form>
        </FormControllerContext.Provider>
      )}
    />
  ),
);

FilterForm.propTypes = {
  data: PropTypes.object,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

const FormError = ({ setSubmissionError }) => {
  const { errors, submitErrors, submitting } = useFormState({
    subscription: { errors: true, submitErrors: true, submitting: true },
  });

  const submissionError = submitErrors && submitErrors[FORM_ERROR];

  React.useEffect(() => {
    setSubmissionError(oldError => {
      if (submitting && oldError === submissionError) {
        return null;
      } else if (submissionError && !submitting) {
        return {
          type: 'danger',
          value: submissionError,
        };
      }
    });
  }, [submitting, submissionError, setSubmissionError]);

  const error = errors && errors[FORM_ERROR];

  if (!error) return null;

  return (
    <div className="col-xs-12">
      <Alert msg={error} type="danger" />
    </div>
  );
};

const WizardPageTab = ({
  controller,
  form,
  updateRefs,
  page,
  isLastPage,
  active,
  loading,
}) => {
  const [message, setMessage] = React.useState(null);

  React.useEffect(() => {
    updateRefs(page, form, controller);
  });

  const isFinish = page.props.isFinish || isLastPage;
  const isUpdate = form.values && form.values.id;

  let submitText = 'Next';
  if (loading || controller.submitting || form.submitting) {
    submitText = 'Loading';
  } else if (isUpdate) {
    submitText = 'Update';
  } else if (isFinish) {
    submitText = 'Finish';
  }

  return (
    <div className={classnames('tab-pane', { active })}>
      {page.props.before && page.props.before()}
      <form className="tgt-form" onSubmit={form.handleSubmit}>
        <div className="row">
          {message && (
            <div className="col-xs-12">
              <Alert
                type="danger"
                msg={message.value}
                closeHandler={() => setMessage(null)}
              />
            </div>
          )}
          <FormError setSubmissionError={setMessage} />
          {page}
          <div className="col-xs-12 form-footer">
            <button
              type="submit"
              className="btn btn-primary fsubmit"
              disabled={
                loading ||
                (isUpdate && form.pristine) ||
                controller.submitting ||
                form.submitting ||
                isInvalid(form) ||
                ((isUpdate || isFinish) && isInvalid(controller))
              }
            >
              {submitText}
            </button>
          </div>
        </div>
      </form>
      {page.props.after && page.props.after()}
    </div>
  );
};

export const Wizard = ({ children, onSubmitSuccess, data, loading }) => {
  const [message, setMessage] = React.useState(null);
  const pages = React.Children.toArray(children);
  const indices = Object.fromEntries(
    pages.map((page, index) => [page.props.id, index]),
  );

  const [pageIndex, setPageIndex] = React.useState(0);
  const lastPageIndex = pages.length - 1;

  const refs = React.useRef({
    forms: {},
    controller: null,
  });

  refs.current.next = () => setPageIndex(p => Math.min(p + 1, lastPageIndex));
  const accumulateValues = values => {
    refs.current.accumulatedValues = merge(
      {},
      refs.current.accumulatedValues,
      values,
    );

    return refs.current.accumulatedValues;
  };

  const updateRefs = (page, form, controller) => {
    if (
      refs.current.forms[page.props.id] &&
      refs.current.forms[page.props.id].values !== form.values
    ) {
      controller.form.change(`values.${page.props.id}`, form.values);
    }

    refs.current.forms[page.props.id] = form;
    refs.current.controller = controller;

    const isFormInvalid =
      (form.dirty || pageIndex >= indices[page.props.id]) && isInvalid(form);

    if (isFormInvalid !== controller.values.invalid[page.props.id]) {
      controller.form.change(`invalid.${page.props.id}`, isFormInvalid);
    }
  };

  const pageIds = JSON.stringify(pages.map(page => page.props.id));

  /* TODO:
   * - add warning about unsaved content before canceling
   * - add same warning before navigating away
   */

  return (
    <Form
      initialValues={React.useMemo(
        () => ({
          invalid: {},
          values: Object.fromEntries(
            JSON.parse(pageIds).map(pageId => [pageId, data]),
          ),
          data,
        }),
        [pageIds, data],
      )}
      onSubmit={async values => {
        refs.current.quitEarly = false;
        refs.current.accumulatedValues = values.data;
        const { submitting } = refs.current;

        let errors;

        // Not sure why this is getting triggered - it's used in the line below
        // eslint-disable-next-line no-unused-vars
        for (const page of pages) {
          if (submitting && submitting.page.props.id === page.props.id) {
            errors = await submitting.submitForm();
          } else {
            errors = await refs.current.forms[page.props.id].handleSubmit();
          }
          if (errors || refs.current.quitEarly) break;
        }

        if (!errors && !refs.current.quitEarly) {
          await onSubmitSuccess(refs.current.accumulatedValues.id);
        }

        refs.current.quitEarly = false;
        refs.current.accumulatedValues = null;
        refs.current.submitting = null;

        return errors;
      }}
    >
      {controller => (
        <FormControllerContext.Provider value={{ loading, controller }}>
          <div className="board board-flex">
            <div className="board-inner">
              <ul className="nav nav-tabs">
                {pages.map((page, index) => (
                  <FinalFormField
                    key={page.props.id}
                    name={`invalid.${page.props.id}`}
                    validate={value => value && !page.props.optional}
                  >
                    {() => (
                      <li
                        className={classnames({
                          invalid: controller.values.invalid[page.props.id],
                          active: index === pageIndex,
                        })}
                        title={page.props.title}
                      >
                        {/* Register values field to update dirtySinceLastSubmit */}
                        <FinalFormField
                          name={`values.${page.props.id}`}
                          render={() => null}
                        />
                        <button
                          type="button"
                          onClick={() => setPageIndex(index)}
                        >
                          <span className="round-tabs">
                            <i className={classnames('fa', page.props.icon)} />
                          </span>
                        </button>
                      </li>
                    )}
                  </FinalFormField>
                ))}
              </ul>
              {message && (
                <Alert
                  type="danger"
                  msg={message.value}
                  closeHandler={() => setMessage(null)}
                />
              )}
            </div>
            <FormError setSubmissionError={setMessage} />
            <div className="tab-content">
              {pages.map((page, index) => (
                <FinalFormField key={page.props.id} name={page.props.id}>
                  {() => (
                    <Form
                      mutators={arrayMutators}
                      initialValues={data}
                      validate={page.props.validate}
                      onSubmit={async (values, form) => {
                        const isFinish =
                          page.props.isFinish || index === lastPageIndex;

                        const submitForm = async () => {
                          const updatedValues = {};
                          Object.keys(form.getState().dirtyFields).forEach(
                            field => {
                              set(updatedValues, field, get(values, field));
                            },
                          );

                          accumulateValues(updatedValues);

                          if (
                            !page.props.optional &&
                            (form.invalid || (form.pristine && !values.id))
                          ) {
                            refs.current.quitEarly = true;
                            return { [FORM_ERROR]: 'This section is required' };
                          }

                          if (form.pristine && !isFinish) return;

                          let errors;
                          if (page.props.onSubmit) {
                            try {
                              errors = await page.props.onSubmit(
                                refs.current.accumulatedValues,
                                form,
                                accumulateValues,
                              );
                            } catch (error) {
                              errors = { [FORM_ERROR]: error.message };
                            }
                          }

                          refs.current.quitEarly = Boolean(errors);

                          return errors;
                        };

                        if (refs.current.accumulatedValues) {
                          return submitForm();
                        } else if (values.id || isFinish) {
                          refs.current.submitting = { submitForm, page };
                          refs.current.controller.handleSubmit();
                          return null;
                        } else {
                          return refs.current.next();
                        }
                      }}
                    >
                      {currentForm => (
                        <WizardPageTab
                          form={currentForm}
                          controller={controller}
                          page={page}
                          isLastPage={index === lastPageIndex}
                          updateRefs={updateRefs}
                          active={index === pageIndex}
                          loading={loading}
                        />
                      )}
                    </Form>
                  )}
                </FinalFormField>
              ))}
            </div>
          </div>
        </FormControllerContext.Provider>
      )}
    </Form>
  );
};

Wizard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onSubmitSuccess: PropTypes.func,
  loading: PropTypes.bool,
  // TODO: better type checking - all children must be `Wizard.Page`
  children: PropTypes.node.isRequired,
};

const WizardPage = ({ children }) => children;

WizardPage.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,

  children: PropTypes.node,
  disabled: PropTypes.bool,
  before: PropTypes.func,
  after: PropTypes.func,
};

Wizard.Page = WizardPage;
