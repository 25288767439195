import React, { Component } from 'react';

class Hidden extends Component {
  render() {
    const { config, data } = this.props;
    const { key, default_value } = config;
    const value = data != null ? data[key] : '';
    return (
      <input
        type="hidden"
        name={key}
        value={value || default_value}
        className="hidden"
      />
    );
  }
}
export default Hidden;
