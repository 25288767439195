import * as React from 'react';
import {
  SingleDatePicker,
  DateRangePicker as ReactDatesDateRangePicker,
} from 'react-dates';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useUUID } from '../modules/Hooks';

export const DatePicker = ({
  startDate,
  endDate,
  onDateChange,
  onBlur,
  ...props
}) => {
  const [focused, setFocus] = React.useState(undefined);

  React.useEffect(() => {
    if (focused === false && onBlur) onBlur();
  }, [focused, onBlur]);

  return (
    <SingleDatePicker
      displayFormat="YYYY-MM-DD"
      date={startDate}
      id={useUUID()}
      onDateChange={onDateChange}
      focused={focused}
      onFocusChange={e => setFocus(e.focused)}
      {...props}
    />
  );
};

export const DateRangePicker = ({
  startDate,
  endDate,
  onDatesChange,
  onFocusChange,
  onBlur,
  ...props
}) => {
  const [focused, setFocus] = React.useState(undefined);

  React.useEffect(() => {
    if (focused === null && onBlur) onBlur();
  }, [focused, onBlur]);

  return (
    <ReactDatesDateRangePicker
      displayFormat="YYYY-MM-DD"
      startDate={startDate}
      startDateId={useUUID()}
      endDate={endDate}
      endDateId={useUUID()}
      onDatesChange={onDatesChange}
      focusedInput={focused}
      onFocusChange={focusState => {
        if (onFocusChange) onFocusChange(focusState);
        setFocus(focusState);
      }}
      {...props}
    />
  );
};

DateRangePicker.propTypes = {
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
  onDatesChange: PropTypes.func.isRequired,
};
