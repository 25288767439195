/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import { InitISelect2js } from '../../modules/Event';
import { TimeLocale, UniqueId, GetArgs } from '../../modules/Helper';
import { Api } from '../../modules/Service';

class ContractSelectBike2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uniqueId: UniqueId(),
      // dataoriginal:[],
      dataselect: [],
      location_id: '',
      bike_type: GetArgs('bike_type'),
      loading: false,
    };
  }
  componentDidMount() {
    const { loading } = this.state;
    this.renderData();
    $('select[name="start_location_id"]').change(ev => {
      var loc_id = ev.target.value;
      if (loc_id != '') {
        if (!loading) {
          this.setState({
            location_id: loc_id,
          });
          this.renderData();
        }
      }
    });
    $('select[name="f-bike_type"]').change(ev => {
      const { bike_type } = this.state;
      var bike_type_id = ev.target.value;
      if (bike_type_id != '' && bike_type_id != bike_type) {
        if (!loading) {
          this.setState({
            bike_type: bike_type_id,
          });
          this.renderData();
        }
      }
    });
  }
  renderData() {
    const { location_id, bike_type } = this.state;
    var lookup_url = '/bike/list';
    var query = [];
    query.push('ready_for_hire=true');
    if (location_id != '') query.push('location_id=' + location_id);
    if (bike_type != '') query.push('type_id=' + bike_type);
    this.setState({ loading: true });
    Api(lookup_url + '?' + query.join('&')).done(resp => {
      this.setState({
        dataselect: resp,
        loading: false,
      });
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const { dataselect } = this.state;
    const { key } = this.props.config;
    if (dataselect != null) {
      InitISelect2js(key);
    }
  }
  render() {
    const { uniqueId, loading, dataselect } = this.state;
    const { config, data } = this.props;
    const {
      label,
      key,
      lookup_key,
      lookup_format,
      required,
      default_value,
    } = config;
    const value = data != null ? data[key] : '';

    return (
      <div key={uniqueId}>
        <label className="box-f-bike">{label}</label>

        <select
          name={key}
          id={'select2-' + key}
          data-value={value || default_value}
          required={required}
          className="form-control select2"
          defaultValue=""
        >
          {(() => {
            switch (true) {
              case loading:
                return (
                  <option value="" disabled>
                    Loading...
                  </option>
                );
              case dataselect.length > 0 && !loading:
                const retVal = [];
                retVal.push(
                  <option key="0" value="">
                    select item
                  </option>,
                );
                retVal.push(
                  dataselect.map(item => {
                    const opttext = lookup_format.replace(
                      /{(.*?)}/g,
                      ($1, $2) => {
                        if ($2 == 'timestamp') {
                          item[$2] = TimeLocale(item[$2]);
                        }
                        return item[$2] != null ? item[$2] : '-';
                      },
                    );
                    return (
                      <option key={item[lookup_key]} value={item[lookup_key]}>
                        {opttext}
                      </option>
                    );
                  }),
                );
                return retVal;
              default:
                return <option value="">Item not found</option>;
            }
          })()}
        </select>
      </div>
    );
  }
}
export default ContractSelectBike2;
