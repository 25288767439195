import React, { Component } from 'react';
import { InitDate } from '../../modules/Event';
import 'bootstrap-datepicker';
class StartDatePicker extends Component {
  componentDidMount() {
    InitDate();
  }
  render() {
    setTimeout(function() {
      $('.startdatepicker')
        .datepicker({
          format: 'yyyy-mm-dd',
          autoclose: true,
          todayHighlight: true,
          setDate: new Date(),
        })
        .on('changeDate', function(selected) {
          var startDate = new Date(selected.date.valueOf());
          var checkId = $(this)
            .parents('.tgt-form')
            .attr('id');
          if (checkId !== undefined) {
            $('#' + checkId + ' .enddatepicker').datepicker(
              'setStartDate',
              startDate,
            );
            const ID = $("input[name='id']").val();
            if (ID === '') {
              $('#' + checkId + ' .enddatepicker').val(
                $('#' + checkId + ' .startdatepicker').val(),
              );
            }
          } else {
            var endDatePicker = $(this)
              .parents('.tgt-form')
              .find('.enddatepicker')
              .attr('data-value');
            $('.' + endDatePicker).datepicker('setStartDate', startDate);
            const ID = $("input[name='id']").val();
            if (ID === '') {
              $('.enddatepicker').val($('.startdatepicker').val());
            }
          }
        })
        .on('clearDate', function(selected) {
          var checkId = $(this)
            .parents('.tgt-form')
            .attr('id');
          var endDatePicker = $(this)
            .parents('.tgt-form')
            .find('.enddatepicker')
            .attr('data-value');
          if (checkId !== undefined) {
            $('#' + checkId + ' .enddatepicker').datepicker(
              'setStartDate',
              null,
            );
          } else {
            $('.' + endDatePicker).datepicker('setStartDate', null);
          }
        });
    }, 1000);
    const { config, data, className, required } = this.props;
    const { label, key, default_value } = config;
    const value = data != null ? data[key] : '';
    return [
      <label key="0">{label}</label>,
      <div key="1" className="input-group date">
        <div className="input-group-addon">
          <i className="fa fa-calendar" />
        </div>
        <input
          data-value="startdatepicker"
          type="text"
          autocomplete="off"
          name={key}
          defaultValue={value || default_value}
          required={required}
          className={
            'form-control startdatepicker pull-right ' + (className || '')
          }
        />
      </div>,
    ];
  }
}
export default StartDatePicker;
