import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Box from '../../components/Box';
import MCustomer from '../../models/MCustomer';
import { BtnAdd, BtnEdit, BtnDelete } from '../../components/Btn';
import Table from '../../components/Table';
import { Modal } from '../../components/Modal';
import { CustomerForm } from './CustomerForm';
import { GetModal, ShowModal } from '../../modules/Event';
class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      modalId: 'mdl-form',
      modalOpened: false,
      editData: null,
    };
  }
  renderData() {
    MCustomer.list()
      .done(resp => {
        resp = Array.isArray(resp) ? resp : [resp];
        this.setState({
          data: resp,
        });
      })
      .catch(xhr => {
        this.setState({
          data: [],
        });
      });
  }
  componentDidMount() {
    const { modalId } = this.state;
    this.renderData();
    GetModal(modalId).on('hidden.bs.modal', () => {
      this.setState({ modalOpened: false });
    });
  }
  addItem(ev) {
    ev.preventDefault();
    const { modalId } = this.state;
    this.setState({ editData: null, modalOpened: true });
    ShowModal(modalId);
  }
  editItem(ev, id) {
    ev.preventDefault();
    const { modalId } = this.state;
    const elBtn = $(ev.target);
    elBtn.addClass('loading');
    MCustomer.get(id)
      .done(resp => {
        this.setState({
          modalOpened: true,
          editData: resp,
        });
        elBtn.removeClass('loading');
        ShowModal(modalId);
      })
      .catch(xhr => {
        elBtn.removeClass('loading');
        alert(
          'Edit error #' + id + '.' + xhr.getResponseHeader('X-TBMS-Message'),
        );
      });
  }
  deleteItem(ev, id) {
    ev.preventDefault();
    if (window.confirm(`Delete Customer #${id}?`)) {
      const elBtn = $(ev.target);
      elBtn.addClass('loading');
      MCustomer.delete(id)
        .done(() => {
          elBtn.removeClass('loading');
          this.setState(prevState => ({
            data: prevState.data.filter(customer => customer.id !== id),
          }));
        })
        .catch(xhr => {
          elBtn.removeClass('loading');
          alert(xhr.getResponseHeader('X-TBMS-Message'));
        });
    }
  }
  render() {
    const { data, modalId, modalOpened, editData } = this.state;

    return (
      <Fragment>
        <Box
          title="Customer lists"
          rightBox={<BtnAdd clickHandler={e => this.addItem(e)} />}
        >
          <Table
            loading={!data}
            data={data}
            columns={[
              'id',
              'name',
              'nationality',
              'phone_number',
              'email',
              {
                key: 'requests',
                disableSort: true,
                cellRenderer: ({ row }) => (
                  <Link to={`contract-requests?customer=${row.id}`}>View</Link>
                ),
              },
              {
                key: 'contracts',
                disableSort: true,
                cellRenderer: ({ row }) => (
                  <Link to={`contracts?customer=${row.id}`}>View</Link>
                ),
              },
              {
                key: 'action',
                cellRenderer: ({ row: customer }) => (
                  <Fragment>
                    <BtnEdit
                      clickHandler={e => this.editItem(e, customer.id)}
                    />
                    <BtnDelete
                      clickHandler={e => this.deleteItem(e, customer.id)}
                    />
                  </Fragment>
                ),
              },
            ]}
          />
        </Box>
        <Modal
          id={modalId}
          title={
            editData != null ? 'Edit Customer #' + editData.id : 'Add Customer'
          }
          size="md"
        >
          {modalOpened && (
            <CustomerForm
              modalId={modalId}
              data={editData}
              RefreshParentData={() => this.renderData()}
            />
          )}
        </Modal>
      </Fragment>
    );
  }
}
export default Customers;
