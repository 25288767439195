import React, { Component } from 'react';

class ReadonlyNumber extends Component {
  render() {
    const { config, data } = this.props;
    const { label, key, className } = config;
    const value = data != null ? data[key] : 0;
    return [
      <label key="1">{label}</label>,
      <input
        key="2"
        type="text"
        name={key}
        defaultValue={value}
        className={'form-control ' + (className || '')}
        readOnly={true}
      />,
    ];
  }
}
export default ReadonlyNumber;
