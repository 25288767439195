import * as React from 'react';
import PropTypes from 'prop-types';
import textContent from 'react-addons-text-content';

import Table from './Table';
import { useUUID } from '../modules/Hooks';

const disabledSortColumns = [
  'Return Location Firm',
  'Return Date Open',
  'Action',
  'Contracts',
  'Requests',
  'City Rental',
];

const TableWrapper = ({ loading, children, thead, tableId }) => {
  const uuid = useUUID();
  const [deletedRows, setDeletedRows] = React.useState({});

  const [domNode, setDomNode] = React.useState(null);
  const domRef = React.useCallback(node => setDomNode(node), []);

  React.useEffect(() => {
    if (domNode) {
      const callback = e => {
        setDeletedRows(deleted => ({
          ...deleted,
          [e.detail]: true,
        }));
      };

      domNode.addEventListener('delete-row', callback);

      return () => domNode.removeEventListener('delete-row', callback);
    }
  }, [domNode]);

  const rows = Array.isArray(children)
    ? children
        .filter(node => node && !deletedRows[node.props.id])
        .map(NODE => ({
          NODE,
          ...Object.fromEntries(
            thead.map((name, index) => [
              name,
              Array.isArray(NODE.props.children)
                ? textContent(NODE.props.children[index])
                : null,
            ]),
          ),
        }))
    : [];

  return (
    <div
      ref={domRef}
      className="tgt-datatable"
      id={tableId || uuid}
      style={{ display: 'contents' }}
    >
      <Table
        loading={loading}
        renderRow={row => row.NODE}
        columns={thead.map(name => ({
          key: name,
          label: name,
          headerClassName: name,
          disableSort: disabledSortColumns.includes(name),
        }))}
        data={rows}
      />
    </div>
  );
};

export const Datatable = props =>
  props.useDatatable ? (
    <TableWrapper {...props} />
  ) : (
    <table className="tgt-datatable table table-bordered table-hover">
      <thead>
        <tr>
          {props.thead.map(item => {
            return (
              <th className={item} key={item}>
                {item}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {props.loading ? (
          <tr className="tr-loading">
            <td className="text-center" colSpan={props.thead.length}>
              <div className="tgt-loader" />
            </td>
          </tr>
        ) : (
          props.children
        )}
      </tbody>
    </table>
  );

Datatable.propTypes = {
  thead: PropTypes.array.isRequired,
};
Datatable.defaultProps = {
  useDatatable: true,
};
