import { Api } from '../modules/Service';

const MCategory = {
  list: () => {
    return Api('/stock_category/list');
  },
  add: data => {
    return Api('/stock_category/new', JSON.stringify(data), 'POST', 'text');
  },
  update: data => {
    return Api(
      '/stock_category/set/' + data.id,
      JSON.stringify(data),
      'POST',
      'text',
    );
  },
  delete: id => {
    return Api('/stock_category/del/' + id, {}, 'GET', 'text');
  },
  get: id => {
    return Api('/stock_category/get/' + id);
  },
};
export default MCategory;
