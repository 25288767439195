import * as React from 'react';
import { useForm, useField } from 'react-final-form';

import MContractRequest from '../../models/MContractRequest';
import { Field, validators } from '../../components/FinalForm';
import { useEndpoint, useOptions } from '../../modules/Hooks';

const requestToOption = request => ({
  value: request.id,
  label: MContractRequest.label(request),
  request,
});

const ContractRequestField = props => {
  const form = useForm();

  // register request_bikes to feed it into bike type filter
  useField('request_bikes');

  const selectedId = form.getState().initialValues.contract_request_id;
  const selectedContractRequest = useEndpoint(
    selectedId && `/contract_request/get/${selectedId}`,
  );

  const availableOptions = useOptions(
    '/contract_request/list?state=planned',
    requestToOption,
  );

  const options = React.useMemo(
    () =>
      availableOptions && selectedContractRequest
        ? [
            requestToOption(selectedContractRequest),
            ...availableOptions.filter(
              option => option.value !== selectedContractRequest.id,
            ),
          ]
        : availableOptions,
    [selectedContractRequest, availableOptions],
  );

  return (
    <Field
      select={{
        options,
        isClearable: true,
        onChange: selected => {
          const { request } = selected || {};
          if (request) {
            form.getRegisteredFields().forEach(field => {
              if (['contract_request_id', 'id'].includes(field)) return;
              if (request[field]) {
                form.change(field, request[field]);
                form.blur(field);
              }
            });
            form.blur('start_date,return_date_estimated');
          } else {
            form.change('request_bikes', []);
            form.blur('request_bikes');
          }
        },
      }}
      {...props}
    />
  );
};

const ContractDatesWizardPage = ({ isCity }) => {
  const locationOptions = useOptions('/location/list');

  const returnDateName = isCity ? 'renew_date' : 'return_date_estimated';
  const returnDateLabel = isCity ? 'Renewal Date' : 'Estimated Return Date';

  return (
    <>
      {isCity ? (
        <Field
          className="col-md-6"
          name="start_location_id"
          label="City"
          select={{ options: locationOptions, isClearable: true }}
          validate={validators.required}
        />
      ) : (
        <ContractRequestField
          className="col-xs-12"
          name="contract_request_id"
        />
      )}
      <Field
        className={isCity ? 'col-md-6' : 'col-xs-12'}
        label="Referral Company"
        name="affiliate_id"
        select={{ endpoint: '/affiliate/list', isClearable: true }}
      />
      <div className="col-md-12">
        <div className="row">
          <Field
            className="col-md-6"
            label={
              isCity
                ? 'Start / Renewal Dates'
                : 'Start / Estimated Return Dates'
            }
            name={['start_date', returnDateName]}
            dateRangePicker={{
              startDatePlaceholderText: 'Start Date',
              endDatePlaceholderText: returnDateLabel,
            }}
            validate={(value, allValues) => {
              if (!allValues.start_date && !allValues[returnDateName]) {
                return 'Required';
              }

              if (!allValues.start_date) return 'Start Date required';
              if (!allValues[returnDateName])
                return `${returnDateLabel} required`;
            }}
          />
          <Field
            className="col-md-6"
            name="return_date"
            datePicker={{
              placeholder: 'Unset until contract is finished',
              showClearDate: false,
            }}
            disabled
          />
        </div>
      </div>
      {!isCity && (
        <>
          <div className="col-md-12">
            <div className="row">
              <Field
                className="col-md-6"
                name="start_location_id"
                label="Start Location"
                select={{ options: locationOptions, isClearable: true }}
                validate={validators.required}
              />
              <Field
                className="col-md-6"
                name="return_location_id"
                select={{ options: locationOptions, isClearable: true }}
                validate={validators.required}
              />
            </div>
          </div>
          <div className="col-md-6">
            <Field name="return_date_firm" type="checkbox" />
            <Field name="return_location_firm" type="checkbox" />
          </div>
        </>
      )}
    </>
  );
};

export default ContractDatesWizardPage;
