import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="main-footer text-center">
        <strong>Copyright © 2017.</strong> All rights reserved.
      </footer>
    );
  }
}
export default Footer;
