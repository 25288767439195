import * as React from 'react';

import MRoutes from '../../models/MRoutes';
import RoutesForm from './RoutesForm';

import List from '../../components/List';

export default () => (
  <List
    model={MRoutes}
    add
    edit
    delete
    editForm={RoutesForm}
    columns={[
      { key: 'start_location_name', label: 'Start Location' },
      { key: 'return_location_name', label: 'Return Location' },
      'minimum_duration',
      { key: 'cost_route_extra', label: 'Extra Cost', type: 'currency' },
      {
        key: 'cost_bike_transport',
        label: 'Bike Transport Cost',
        type: 'currency',
      },
    ]}
  />
);
