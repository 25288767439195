import { Api } from '../modules/Service';

const MLocation = {
  title: 'Location',
  list: ({ is_hidden } = {}) => {
    const params = new URLSearchParams();
    if (['true', 'all'].includes(is_hidden)) {
      params.set('is_hidden', is_hidden);
    }

    return Api(`/location/list?${params}`);
  },
  add: data => {
    return Api('/location/new', JSON.stringify(data), 'POST', 'text');
  },
  update: data => {
    return Api(
      '/location/set/' + data.id,
      JSON.stringify(data),
      'POST',
      'text',
    );
  },
  delete: id => Api(`/location/del/${id}`, {}, 'GET', 'text'),
  restore: id =>
    MLocation.get(id).then(item =>
      MLocation.update({ ...item, is_hidden: false }),
    ),
  get: id => {
    return Api('/location/get/' + id);
  },
};

export default MLocation;
