/* eslint-disable eqeqeq */

import { Api } from '../modules/Service';
import { GetArgs } from '../modules/Helper';

const MBike = {
  list: () => {
    var url = '/bike/list';
    const bike = GetArgs('bike');
    const type = GetArgs('type');
    const bike_type = GetArgs('bike_type_id');
    const model = GetArgs('model');
    const manufacturer = GetArgs('manufacturer');
    const transmission = GetArgs('transmission');
    //filter
    const purchase_date_start = GetArgs('f-purchase_date_start');
    const purchase_date_end = GetArgs('f-purchase_date_end');
    const color_id = GetArgs('f-color_id');
    const ready_for_hire = GetArgs('f-ready_for_hire');
    var query = [];
    if (purchase_date_start != '')
      query.push('purchase_date_start=' + purchase_date_start);
    if (purchase_date_end != '')
      query.push('purchase_date_end=' + purchase_date_end);
    if (color_id != '') query.push('color_id=' + color_id);
    if (ready_for_hire == 'true') query.push('ready_for_hire=true');
    if (bike_type != '') query.push('bike_type_id=' + bike_type);

    if (bike != '') {
      url = '/bike/get/' + bike;
    } else if (type != '') {
      url = '/bike/get/type/' + type;
    } else if (model != '') {
      url = '/bike/get/model/' + model;
    } else if (manufacturer != '') {
      url = '/bike/get/manufacturer/' + manufacturer;
    } else if (transmission != '') {
      url = '/bike/get/transmission/' + transmission;
    }
    return Api(url + '?' + query.join('&'));
  },
  DeletedBikes: () => {
    var url = '/bike/list?is_hidden=true';
    return Api(url);
  },
  add: data => {
    return Api('/bike/new', JSON.stringify(data), 'POST', 'text');
  },
  update: data => {
    return Api('/bike/set/' + data.id, JSON.stringify(data), 'POST', 'text');
  },
  delete: id => {
    return Api('/bike/del/' + id, {}, 'GET', 'text');
  },
  get: id => {
    return Api('/bike/get/' + id);
  },
};

export default MBike;
