/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import MCategory from '../../models/MCategory';
import Alert from '../../components/Alert';
import { UniqueId } from '../../modules/Helper';
import { RenderForm } from '../../components/Form';
import { FormToObject } from '../../modules/Service';
import { GetModal, HideModal } from '../../modules/Event';
export class CategoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uniqueId: UniqueId(),
      msg: '',
      submitLoading: false,
    };
  }
  submitForm(ev) {
    ev.preventDefault();
    const { modalId, RefreshParentData } = this.props;
    this.setState({ submitLoading: true });
    const formData = FormToObject($(ev.target).serializeArray());
    if (formData.id == '') {
      MCategory.add(formData)
        .done(resp => {
          GetModal(modalId).scrollTop(0);
          RefreshParentData();
          HideModal(modalId);
          this.setState({
            uniqueId: UniqueId(),
            submitLoading: false,
            msg: (
              <Alert
                msg="Category added successfully."
                type="success"
                closeHandler={() => {
                  this.setState({ msg: '' });
                }}
              />
            ),
          });
        })
        .catch(xhr => this.catchError(xhr));
    } else {
      MCategory.update(formData)
        .done(resp => {
          GetModal(modalId).scrollTop(0);
          RefreshParentData();
          HideModal(modalId);
          this.setState({
            submitLoading: false,
            msg: (
              <Alert
                msg="Category updated successfully."
                type="info"
                closeHandler={() => {
                  this.setState({ msg: '' });
                }}
              />
            ),
          });
        })
        .catch(xhr => this.catchError(xhr));
    }
  }
  catchError(xhr) {
    const { modalId } = this.props;
    GetModal(modalId).scrollTop(0);
    this.setState({
      submitLoading: false,
      msg: (
        <Alert
          msg={xhr.getResponseHeader('X-TBMS-Message')}
          type="danger"
          closeHandler={() => {
            this.setState({ msg: '' });
          }}
        />
      ),
    });
  }
  render() {
    const { submitLoading, msg, uniqueId } = this.state;
    const { data } = this.props;
    const fields = [
      {
        'col-sm-12 1': [
          {
            key: 'id',
            type: 'hidden',
          },
          {
            key: 'name',
            type: 'text',
            label: 'Name',
          },
        ],
      },
    ];
    return (
      <RenderForm
        key={uniqueId}
        data={data}
        msg={msg}
        config={fields}
        submitLoading={submitLoading}
        submitHandler={e => this.submitForm(e)}
      />
    );
  }
}
