import React, { Component } from 'react';
import MUser from '../../models/MUser';
import Alert from '../../components/Alert';
import { RenderForm } from '../../components/Form';
import { FormToObject } from '../../modules/Service';
import { GetModal } from '../../modules/Event';
export class SetPassForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: '',
      submitLoading: false,
    };
  }
  submitForm(ev) {
    ev.preventDefault();
    const { modalId } = this.props;
    const formData = FormToObject($(ev.target).serializeArray());
    var tempMsg = '';
    if (!formData.password) {
      tempMsg = (
        <Alert
          msg="New password empty !"
          type="warning"
          closeHandler={() => {
            this.setState({ msg: '' });
          }}
        />
      );
    }
    if (formData.password !== formData.password_confirmation) {
      tempMsg = (
        <Alert
          msg="Password confirmation not match !"
          type="warning"
          closeHandler={() => {
            this.setState({ msg: '' });
          }}
        />
      );
    }
    if (tempMsg) {
      this.setState({ msg: tempMsg });
      GetModal(modalId).scrollTop(0);
      return false;
    }
    this.setState({ submitLoading: true });
    if (formData.id) {
      MUser.update(formData)
        .done(resp => {
          GetModal(modalId).scrollTop(0);
          this.setState({
            submitLoading: false,
            msg: (
              <Alert
                msg="Password changed successfully."
                type="info"
                closeHandler={() => {
                  this.setState({ msg: '' });
                }}
              />
            ),
          });
        })
        .catch(xhr => this.catchError(xhr));
    }
  }
  catchError(xhr) {
    const { modalId } = this.props;
    GetModal(modalId).scrollTop(0);
    this.setState({
      submitLoading: false,
      msg: (
        <Alert
          msg={xhr.getResponseHeader('X-TBMS-Message')}
          type="danger"
          closeHandler={() => {
            this.setState({ msg: '' });
          }}
        />
      ),
    });
  }
  render() {
    const { submitLoading, msg } = this.state;
    const { data } = this.props;
    const fields = [
      {
        'col-sm-12 1': [
          {
            key: 'id',
            type: 'hidden',
          },
          {
            key: 'username',
            type: 'username',
            label: 'Username',
          },
          {
            key: 'password',
            type: 'password',
            label: 'Password',
          },
          {
            key: 'password_confirmation',
            type: 'password',
            label: 'Confirm Password',
          },
        ],
      },
    ];
    return (
      <RenderForm
        data={data}
        msg={msg}
        config={fields}
        submitLoading={submitLoading}
        submitHandler={e => this.submitForm(e)}
      />
    );
  }
}
