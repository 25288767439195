import * as React from 'react';

import { EditForm, Field, validators } from '../../components/FinalForm';
import { isValidURL } from '../../modules/Helper';

export default props => (
  <EditForm {...props}>
    <div className="col-sm-12">
      <Field name="name" label="Location" validate={validators.required} />
      <Field name="color" type="color" />
      <Field name="address" type="textarea" />
      <Field name="public_hide" type="checkbox" label="Hide from Public" />
      <Field
        name="review_url"
        label="Review URL"
        type="url"
        validate={url => (!url || isValidURL(url) ? undefined : 'Invalid URL')}
      />
    </div>
  </EditForm>
);
