import React from 'react';
import TableView from '../../components/TableView';
import ImgView from '../../components/ImgView';
import { Money, YesNo } from '../../modules/Helper';

export const BikeView = ({ data }) => {
  const detailData = [
    {
      title: 'ID',
      value: '#' + data.id,
    },
    {
      title: 'Number Plate',
      value: data.numberplate,
    },
    {
      title: 'Type',
      value:
        data.manufacturer_name +
        ' ' +
        data.model_name +
        ' ' +
        data.engine_capacity +
        'cc (' +
        data.transmission_name +
        ')',
    },
    {
      title: 'Purchase Date',
      value: data.purchase_date,
    },
    {
      title: 'Purchase Price (VND)',
      value: Money(data.purchase_price_vnd_x100, 100),
    },
    {
      title: 'Purchase Price (USD)',
      value: Money(data.purchase_price_usd_x100, 100),
    },
    {
      title: 'Registration Date',
      value: data.manufacture_date,
    },
    {
      title: 'Registered Owner',
      value: data.reg_owner,
    },
    {
      title: 'Registered Address',
      value: data.reg_address,
    },
    {
      title: 'Bluecard Front Photo',
      value: () => <ImgView id={data.bluecard_front_photo_id} size="100px" />,
    },
    {
      title: 'Bluecard Back Photo',
      value: () => <ImgView id={data.bluecard_back_photo_id} size="100px" />,
    },
    {
      title: 'Ready for Hire ?',
      value: () => YesNo(data.ready_for_hire),
    },
    {
      title: 'Odometer(km)',
      value: data.odometer_x10,
    },
    {
      title: 'Location',
      value: data.location_name,
    },
  ];

  return <TableView data={detailData} labelWidth="200px" />;
};
