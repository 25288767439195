/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Box from '../../components/Box';
import MContract from '../../models/MContract';
import MUser from '../../models/MUser';
import { AppendUrl, GetArgs } from '../../modules/Helper';
import { BtnDelete, BtnEdit, BtnAdd } from '../../components/Btn';
import { Datatable } from '../../components/Datatable';
import { Modal } from '../../components/Modal';
import { CommunicationForm } from './CommunicationForm';
import { ShowModal, DatatableDelRow } from '../../modules/Event';
class RecordContacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: null,
      data2: null,
      UserData: null,
      modalId: 'mdl-bikes-form',
      modalId2: 'md2-bikes-form',
      modalOpened: false,
      viewModalId: 'mdl-view',
      BikeModalId: 'md2-view',
      viewModalOpened: false,
      editData: null,
      contactType: '',
      communicationData: null,
      comType: null,
    };
  }
  renderData() {
    const ContractID = this.props.match.params.finishid;
    MContract.get(ContractID)
      .done(resp1 => {
        resp1 = Array.isArray(resp1) ? resp1 : [resp1];
        this.setState({
          loading: false,
          data2: resp1[0],
          ContractID: ContractID,
        });
      })
      .catch(xhr => {
        this.setState({
          loading: false,
          data2: [],
        });
      });

    MContract.CommunicationByContractId(ContractID)
      .done(resp => {
        resp = Array.isArray(resp) ? resp : [resp];
        this.setState({
          loading: false,
          data: resp,
        });

        if (resp != '') {
          MUser.get(resp[0].user_id).done(resp2 => {
            resp2 = Array.isArray(resp2) ? resp2 : [resp2];
            this.setState({
              loading: false,
              UserName: resp2[0].username,
            });
          });
          MContract.ContactMethodlist().done(resp3 => {
            resp3 = Array.isArray(resp3) ? resp3 : [resp3];
            this.setState({
              loading: false,
              communication: resp3,
            });
          });
        }
      })
      .catch(xhr => {
        this.setState({
          loading: false,
          data: [],
        });
      });
  }
  componentDidMount() {
    this.renderData();
    this.ContractData();
    if (GetArgs('new') == 'true') {
      ShowModal('mdl-bikes-form');
    }
  }
  componentWillReceiveProps(nextProps) {
    //url query changes
    if (nextProps.location.search != this.props.location.search) {
      this.setState({
        loading: true,
      });
      this.renderData();
    }
  }

  addContact(ev, id) {
    ev.preventDefault();
    const { modalId } = this.state;
    this.setState({
      communicationData: null,
      modalOpened: true,
      comType: null,
    });
    ShowModal(modalId);
    jQuery('.alert.fade.in.alert-dismissable.alert-info').remove();
  }

  ContractData() {
    var contractID = this.props.match.params.finishid;
    MContract.get(contractID)
      .done(resp => {
        this.setState({
          modalOpened: true,
          editData: resp,
          contactType: 'Edit',
        });
      })
      .catch(xhr => {
        alert(
          'Edit error #' +
            contractID +
            '.' +
            xhr.getResponseHeader('X-TBMS-Message'),
        );
      });
  }

  EditContract(ev, id) {
    ev.preventDefault();
    const { modalId } = this.state;
    const elBtn = $(ev.target);
    elBtn.addClass('loading');
    MContract.getCommunication(id)
      .done(ComRes => {
        var Comdata = JSON.parse(ComRes);
        this.setState({
          communicationData: Comdata,
          communicationID: id,
          comType: 'Edit',
        });

        elBtn.removeClass('loading');
        jQuery('.alert.fade.in.alert-dismissable.alert-info').remove();
        ShowModal(modalId);
      })
      .catch(xhr => {
        elBtn.removeClass('loading');
        alert(
          'Edit error #' + id + '.' + xhr.getResponseHeader('X-TBMS-Message'),
        );
      });
  }

  deleteItem(ev, id) {
    ev.preventDefault();
    if (window.confirm('Delete Communication #' + id + ' ?')) {
      const elBtn = $(ev.target);
      elBtn.addClass('loading');
      MContract.DeleteContact(id)
        .done(() => {
          elBtn.removeClass('loading');
          const dtTbl = elBtn.closest('.tgt-datatable').attr('id');
          DatatableDelRow(dtTbl, id);
        })
        .catch(xhr => {
          elBtn.removeClass('loading');
          alert(xhr.getResponseHeader('X-TBMS-Message'));
        });
    }
  }

  submitForm(ev) {
    ev.preventDefault();
    var str1 = AppendUrl(this);
    var newString = str1.substr(0, str1.length - 1);
    var final_url = newString + '/add/';
    this.props.history.push(final_url);
  }

  render() {
    console.log(this.state.data2);
    const {
      loading,
      data,
      modalId,
      modalOpened,
      editData,
      communicationID,
      communicationData,
      comType,
    } = this.state;
    const thead = [
      'ID',
      'UserName',
      'Timestamp',
      'Renew Date',
      'Communication Method',
      'Notes',
      'Customer Responded',
      'Action',
    ];
    let customer = '';
    let customerEmail = '';
    let customer_phone_number = '';
    let Start_EndLocation = '';
    let Alldates = '';
    if (this.state.data2 != null) {
      customer = this.state.data2.customer_name;
      customerEmail = this.state.data2.customer_email;
      customer_phone_number = this.state.data2.customer_phone_number;
      Alldates =
        this.state.data2.start_date +
        '/' +
        this.state.data2.return_date_estimated +
        '/' +
        this.state.data2.renew_date;
    }

    let userName = '';
    if (this.state.UserName != '') {
      userName = this.state.UserName;
    }

    let redirect = '/cityrental';
    if (GetArgs('redirect') != '') {
      redirect = '/' + GetArgs('redirect');
    }

    return [
      <Box
        key="0"
        title="Contract Communication"
        rightBox={<BtnAdd clickHandler={e => this.addContact(e)} />}
      >
        <div className="col-lg-12 col-sm-12 commmunication-header">
          <label>Customer Name</label>: {customer} <br />
          <label>Customer Email</label>: {customerEmail} <br />
          <label>Start / Return locations </label>: {Start_EndLocation} <br />
          <label>Start / Return / Renew dates </label>: {Alldates} <br />
          <Link className="btn btn-sm btn-success backbtn" to={redirect}>
            {' '}
            Back To List
          </Link>
        </div>
        <Datatable loading={loading} thead={thead}>
          {data != null &&
            !loading &&
            data.map((item, idx) => {
              const {
                id,
                customer_responded,
                notes,
                renew_date,
                communication_method_id,
                timestamp,
              } = item;
              var Newtimestamp = moment(timestamp).format('h:mm D MMM YYYY');
              let communicationMethod = '';
              if (this.state.communication != null) {
                communicationMethod = {};
                var MethodData = this.state.communication;
                for (var i = 0; i < MethodData.length; i++) {
                  if (communication_method_id == MethodData[i].id) {
                    communicationMethod = MethodData[i].name;
                  }
                }
              }

              return (
                <tr key={idx} id={'dt-item-' + id}>
                  <td style={{ width: 50 }}>{id}</td>
                  <td>{userName}</td>
                  <td>{Newtimestamp}</td>
                  <td>{renew_date}</td>
                  <td>{communicationMethod}</td>
                  <td>{notes}</td>
                  <td>
                    {' '}
                    {customer_responded == 1 && (
                      <div className="allsteps">
                        <i className="fa fa-check" aria-hidden="true" />{' '}
                      </div>
                    )}{' '}
                    {customer_responded == 0 && (
                      <div className="allsteps"> </div>
                    )}{' '}
                  </td>
                  <td className="box-action" style={{ width: 200 }}>
                    <BtnEdit
                      clickHandler={e => this.EditContract(e, id)}
                      parentThis={this}
                    />
                    <BtnDelete clickHandler={e => this.deleteItem(e, id)} />
                  </td>
                </tr>
              );
            })}
        </Datatable>
      </Box>,
      <div>
        <Modal
          key="1"
          id={modalId}
          title={
            comType != null
              ? 'Edit Communication #' + communicationID
              : 'Add Communication'
          }
          size="lg"
        >
          <div className="col-lg-12 col-sm-12 commmunication-popup-header">
            <label>Customer Email</label>:{customerEmail} <br />
            <label>Customer Contact</label>:{customer_phone_number}
          </div>
          {modalOpened && (
            <CommunicationForm
              modalId={modalId}
              data={editData}
              ContractID={this.props.match.params.finishid}
              communicationData={communicationData}
              RefreshParentData={() => this.renderData()}
            />
          )}
        </Modal>
      </div>,
    ];
  }
}
export default RecordContacts;
