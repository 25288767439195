import React, { Component } from 'react';
import 'bootstrap-datepicker';
class EndDatePicker extends Component {
  componentDidMount() {
    //InitDate1();
  }

  render() {
    setTimeout(function() {
      $('.enddatepicker')
        .datepicker({
          format: 'yyyy-mm-dd',
          autoclose: true,
          setDate: new Date(),
          todayHighlight: true,
        })
        .on('clearDate', function(selected) {
          var checkId = $(this)
            .parents('.tgt-form')
            .attr('id');
          if (checkId !== undefined) {
            $('#' + checkId + ' .startdatepicker').datepicker(
              'setStartDate',
              null,
            );
          } else {
            var StartDatePicker = $(this)
              .parents('.tgt-form')
              .find("input[name='start_date']")
              .attr('data-value');
            $('.' + StartDatePicker).datepicker('setEndDate', null);
          }
        });
    }, 1000);

    const { config, data, className, required } = this.props;
    const { label, key, default_value } = config;
    const value = data != null ? data[key] : '';
    return [
      <label key="0">{label}</label>,
      <div key="1" className="input-group date">
        <div className="input-group-addon">
          <i className="fa fa-calendar" />
        </div>
        <input
          data-value="enddatepicker"
          type="text"
          autocomplete="off"
          name={key}
          defaultValue={value || default_value}
          required={required}
          className={
            'form-control enddatepicker pull-right ' + (className || '')
          }
        />
      </div>,
    ];
  }
}
export default EndDatePicker;
