import { Api } from '../modules/Service';
import { GetArgs } from '../modules/Helper';

const MCustomer = {
  list: () => {
    const customer = GetArgs('customer');

    return Api(customer ? `/customer/get/${customer}` : '/customer/list');
  },
  add: data => {
    return Api('/customer/new', JSON.stringify(data), 'POST', 'text');
  },
  update: data => {
    return Api(
      '/customer/set/' + data.id,
      JSON.stringify(data),
      'POST',
      'text',
    );
  },
  delete: id => {
    return Api('/customer/del/' + id, {}, 'GET', 'text');
  },
  get: id => {
    return Api('/customer/get/' + id);
  },
  getBy: (type, val) => {
    return Api('/customer/get/' + type + '/' + val);
  },
};
export default MCustomer;
