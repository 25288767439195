import BaseModel from './BaseModel';
import { Api } from '../modules/Service';

const MContract = {
  ...BaseModel,

  endpoint: '/contract',
  title: 'Travel Contracts',

  defaultListValues: {
    state: 'running',
  },

  validListFilters: ['state', 'date'],

  modifyListParams(search) {
    const query = new URLSearchParams({
      ...Object.fromEntries(search),
      city_rental: 'false',
      by: 'start',
      order: 'desc',
    });

    if (search.get('date')) {
      query.set('by', 'created');
      query.set('limit_past', 0);
      query.set('limit_future', 0);
    }

    return query;
  },

  list(filters) {
    if (filters.customer) {
      return Api(
        `/contract/get/customer/${encodeURIComponent(filters.customer)}`,
      );
    }

    return BaseModel.list.call(this, filters);
  },

  finish: data =>
    Api(`/contract/finish/${data.id}`, JSON.stringify(data), 'POST', 'text'),

  ContactMethodlist: () => {
    return Api('/communication_method/list');
  },
  CommunicationByContractId: id => {
    return Api('/contract_communication/get/contract/' + id);
  },

  swap_bike: data => {
    return Api(
      '/contract/swap_bike/' + data.id,
      JSON.stringify(data),
      'POST',
      'text',
    );
  },

  addCommunication: data => {
    return Api(
      '/contract_communication/new',
      JSON.stringify(data),
      'POST',
      'text',
    );
  },
  updateCommunication: data => {
    return Api(
      '/contract_communication/set/' + data.id,
      JSON.stringify(data),
      'POST',
      'text',
    );
  },
  DeleteContact: id => {
    return Api('/contract_communication/del/' + id, {}, 'GET', 'text');
  },
  getCommunication: id => {
    return Api('/contract_communication/get/' + id, {}, 'GET', 'text');
  },
};
export default MContract;
