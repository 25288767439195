import React from 'react';
import { useLocation } from 'react-router-dom';
import { omit, isEqual } from 'lodash';

import MCustomer from '../../models/MCustomer';
import MContract from '../../models/MContract';
import MContractRequest from '../../models/MContractRequest';
import { Wizard } from '../../components/FinalForm';
import { saveItem } from '../../modules/FormHelper';

import ContractCustomerWizardPage from './ContractCustomerWizardPage';
import ContractDatesLocationsWizardPage from './ContractDatesLocationsWizardPage';
import ContractBikeWizardPage from './ContractBikeWizardPage';
import ContractSpecificsWizardPage from './ContractSpecificsWizardPage';

const booleanFields = [
  'return_date_firm',
  'return_location_firm',
  'retained_passport',
  'return_passport',
  'release_bluecard',
  'return_bluecard',
  'retained_cash_deposit',
  'return_cash_deposit',
];

const ContractForm = ({ data, ...props }) => {
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [loadedData, setLoadedData] = React.useState(data);
  const isCity = Boolean(
    data && data.id ? data.city_rental : location.pathname === '/cityrental',
  );

  const loadData = React.useCallback(
    contract => {
      setLoading(true);

      let currentRequest = true;

      (async () => {
        let contractRequest;

        if (contract.contract_request_id && !contract.id) {
          contractRequest = await MContractRequest.get(
            contract.contract_request_id,
          );
        }

        const customer = Object.fromEntries(
          Object.entries({ ...contractRequest, ...contract })
            .map(([key, value]) =>
              key.startsWith('customer_')
                ? [key.replace(/^customer_/, ''), value]
                : null,
            )
            .filter(Boolean),
        );

        if (currentRequest) {
          setLoadedData({
            release_keys_quantity: 2,
            ...omit(contractRequest, 'id'),
            ...contract,
            customer,
            ...Object.fromEntries(
              booleanFields.map(field => [field, Boolean(contract[field])]),
            ),
            city_rental: isCity,
          });

          setLoading(false);
        }
      })();

      return () => {
        currentRequest = false;
      };
    },
    [isCity],
  );

  React.useEffect(() => {
    loadData(data);
  }, [loadData, data]);

  return (
    <Wizard {...props} loading={loading} data={loadedData}>
      <Wizard.Page
        id="customer"
        icon="fa-user-o"
        title="Customer"
        onSubmit={({ customer, originalCustomer }, form, updateData) => {
          if (!customer || !form.getState().dirtyFields.customer) return;

          const updater = ({ id }) =>
            updateData({ customer_id: id, customer: { ...customer, id } });

          if (!customer.id || !isEqual(customer, originalCustomer)) {
            updater(customer);

            return saveItem({ model: MCustomer, transform: () => customer })(
              customer,
              form,
              updater,
            );
          } else {
            updater(customer);
          }
        }}
      >
        <ContractCustomerWizardPage isCity={isCity} />
      </Wizard.Page>
      <Wizard.Page
        id="datesLocations"
        icon="fa-calendar"
        title="Dates & Locations"
      >
        <ContractDatesLocationsWizardPage isCity={isCity} />
      </Wizard.Page>
      <Wizard.Page id="bike" icon="fa-motorcycle" title="Bike">
        <ContractBikeWizardPage isCity={isCity} />
      </Wizard.Page>
      <Wizard.Page
        id="details"
        icon="fa-newspaper-o"
        title="Details"
        onSubmit={saveItem({
          model: MContract,
          transform: values => values,
        })}
      >
        <ContractSpecificsWizardPage isCity={isCity} />
      </Wizard.Page>
    </Wizard>
  );
};

export default ContractForm;
