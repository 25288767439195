import React from 'react';

import Cache from '../../modules/Cache';
import { useOptions } from '../../modules/Hooks';
import { FilterForm, Field } from '../../components/FinalForm';

const bikeTypeToOption = ({ id, model_name }) => ({
  value: id,
  label: model_name,
});

const LocationField = () => {
  const options = useOptions('/location/list');
  const { default_location_id } = Cache.get('userLogin') || {};

  return (
    <Field
      label="Location"
      name="start_location_id"
      select={{
        options: options && [{ value: 'all', label: 'All' }, ...options],
      }}
      defaultValue={default_location_id}
    />
  );
};

const CityRentalFilters = props => {
  return (
    <FilterForm {...props}>
      <LocationField />
      <Field
        label="Model"
        name="bike_type_id"
        select={{
          isClearable: true,
          endpoint: '/bike_type/list',
          toOption: bikeTypeToOption,
        }}
      />
      <Field
        name="referal-company"
        select={{
          isClearable: true,
          endpoint: '/affiliate/list',
        }}
      />
    </FilterForm>
  );
};

export default CityRentalFilters;
