import * as React from 'react';

import { FilterForm, Field } from '../../components/FinalForm';

export default ({ contracts, contractRequests, tableData, ...props }) => (
  <FilterForm {...props}>
    <Field
      className="wide"
      name="contract_request_id"
      label="Contract Request"
      select={{ options: contractRequests, isClearable: true }}
    />
    <Field
      className="wide"
      name="contract_id"
      label="Contract"
      select={{ options: contracts, isClearable: true }}
    />
  </FilterForm>
);
