import { TimeLocale } from '../../modules/Helper';

export const contractLabel = contract =>
  contract &&
  [
    contract.customer_email,
    contract.customer_name,
    contract.timestamp && TimeLocale(contract.timestamp),
  ]
    .filter(Boolean)
    .join(' / ');
