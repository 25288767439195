/* eslint-disable eqeqeq */

import 'bootstrap';
import 'admin-lte';
import 'datatables.net';
import 'datatables.net-bs';
import 'admin-lte/plugins/iCheck/icheck.js';
import 'select2';
import 'bootstrap-datepicker';
import { Api } from './Service';

export const InitDatatable = tblId => {
  const scrollX = $(window).width() < 768 ? true : false;
  if (typeof tblId != 'undefined') {
    $('#' + tblId).DataTable({
      scrollX: scrollX,
      aaSorting: [],
      language: {
        lengthMenu: 'Perpage _MENU_',
        search: 'Search',
      },
    });
    return false;
  }
  $.each($('.tgt-datatable'), function() {
    if (!$(this).hasClass('dataTable')) {
      $(this).DataTable({
        scrollX: scrollX,
        aaSorting: [],
        language: {
          lengthMenu: 'Perpage _MENU_',
          search: 'Search',
        },
      });
    }
  });
};
export const DatatableDelRow = (dtTbl, id) => {
  $('#' + dtTbl)
    .DataTable()
    .row('#dt-item-' + id)
    .remove()
    .draw(false);
};
export const InitModal = () => {
  $('[data-toggle="modal"]').modal();
};
export const GetModal = id => {
  return $('#' + id);
};
export const ShowModal = id => {
  $('#' + id).modal('show');
};
export const HideModal = id => {
  $('#' + id).modal('hide');
};
export const InitIcheck = () => {
  $('input[type="checkbox"].icheck').iCheck({
    checkboxClass: 'icheckbox_minimal-green',
    radioClass: 'iradio_minimal-green',
  });
};
export const InitISelect2js = key => {
  const elem = $('.contract_req');
  const val = elem.val() != '' ? elem.val() : elem.attr('data-value');
  // if(elem.hasClass('select2-hidden-accessible')){
  // elem.val( val ).trigger('change');
  //return false;
  // }
  elem
    .select2({
      placeholder: 'select item',
    })
    .val(val)
    .trigger('change');
};

export const InitISelect3js = key => {
  const elem = $('.contract_loc');
  const val = elem.val() != '' ? elem.val() : elem.attr('data-value');
  // if(elem.hasClass('select2-hidden-accessible')){
  // elem.val( val ).trigger('change');
  //return false;
  // }
  elem
    .select2({
      placeholder: 'select item',
    })
    .val(val)
    .trigger('change');
};

export const InitISelectBikejs = key => {
  // const elem = $('.bikes');
  // const val = elem.val() != '' ? elem.val() : elem.attr('data-value');
  // if(elem.hasClass('select2-hidden-accessible')){
  // elem.val( val ).trigger('change');
  //return false;
  // }
  // elem.select2({
  // placeholder: 'select item'
  // }).val( val ).trigger('change');
};

export const InitISelectNumberjs = key => {
  const elem = $('.bikeNumber');
  const val = elem.val() != '' ? elem.val() : elem.attr('data-value');
  if (elem.hasClass('select2-hidden-accessible')) {
    elem.val(val).trigger('change');
    return false;
  }
  elem
    .select2({
      placeholder: 'select item',
    })
    .val(val)
    .trigger('change');
};

export const InitISelect2jsAjax = (
  key,
  url,
  lookup_format,
  value,
  url_single,
) => {
  $(
    '#' +
      localStorage.getItem('formID') +
      " select[name='customer_nationality']",
  ).attr('disabled', true);
  const elem = $('.customer_select');
  $('button[title="Add New Customer"]').css('display', 'hide');
  elem.select2({
    placeholder: 'select item',
    ajax: {
      url: function(params) {
        return url + '/' + (params.term || '') + '?limit=10';
      },
      data: function(params) {
        localStorage.setItem('customerSearch', params.term);
        if (params.term != undefined) {
          $('.customerbtn').remove();
          $('button[title="Add New Customer"]').after(
            "<input type='hidden' value='" +
              params.term +
              "' class='customerbtn'>",
          );
        }
        return {};
      },
      processResults: function(data) {
        if (data.length == 0) {
          $('button[title="Add New Customer"]').css('display', 'block');
        }
        return {
          results: data,
        };
      },
      delay: 300,
      dataType: 'json',
      cache: false,
      crossDomain: true,
      xhrFields: {
        withCredentials: true,
      },
    },
    minimumInputLength: 1,
    escapeMarkup: function(markup) {
      return markup;
    },
    templateResult: function(res) {
      $('a.btn.btn-sm.btn-success.btn-customer').remove();
      if (res.loading) {
        return res.text;
      }
      var opttext = lookup_format.replace(/{(.*?)}/g, ($1, $2) => {
        return res[$2] != null ? res[$2] : '-';
      });
      var markup = '<span>' + opttext + '</span>';
      return markup;
    },
    templateSelection: function(res) {
      if (res.id == '' || res.selected == true) return res.text;
      var opttext = lookup_format.replace(/{(.*?)}/g, ($1, $2) => {
        return res[$2] != null ? res[$2] : '-';
      });
      $(
        '#' + localStorage.getItem('formID') + " input[name='customer_name']",
      ).val(res.name);
      $(
        '#' + localStorage.getItem('formID') + " input[name='customer_email']",
      ).val(res.email);
      $(
        '#' +
          localStorage.getItem('formID') +
          " select[name='customer_nationality']",
      )
        .find("option[value='" + res.nationality + "']")
        .attr('selected', true);
      $(
        '#' +
          localStorage.getItem('formID') +
          " select[name='customer_nationality']",
      ).attr('disabled', true);

      return opttext;
    },
  });

  if (value != '' && value != '0') {
    var loadingOpt = new Option('loading', '0', false, false);
    elem.append(loadingOpt).trigger('change');

    Api(url_single + '/' + value)
      .done(resp => {
        if (resp[0] != undefined) {
          if (resp[0].email != undefined) {
            var optArgs = {
              value: resp[0].id,
              text: resp[0].email,
              selected: true,
            };
            elem.append($('<option>', optArgs)).trigger('change');
            $(
              '#' +
                localStorage.getItem('formID') +
                " input[name='customer_email']",
            ).val(resp[0].email);
            $(
              '#' +
                localStorage.getItem('formID') +
                " input[name='customer_name']",
            ).val(resp[0].name);
            $(
              '#' +
                localStorage.getItem('formID') +
                " input[name='customer_phone_number']",
            ).val(resp[0].phone_number);
            $(
              '#' +
                localStorage.getItem('formID') +
                " select[name='customer_nationality']",
            )
              .find("option[value='" + resp[0].nationality + "']")
              .attr('selected', true);
            $(
              '#' +
                localStorage.getItem('formID') +
                " select[name='customer_nationality']",
            ).attr('disabled', true);
          }
        }
      })
      .catch(xhr => {
        alert(`error: ${xhr.getResponseHeader('X-TBMS-Message')}`);
      });
  }
};
export const InitDate = () => {
  $('.datepicker').datepicker({
    autoclose: true,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
  });
};
