import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import 'react-dates/initialize';

// Styles
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'admin-lte/plugins/iCheck/all.css';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import 'datatables.net-bs/css/dataTables.bootstrap.css';
import 'select2/dist/css/select2.min.css';
import 'admin-lte/dist/css/AdminLTE.min.css';
import 'rc-slider/assets/index.css';
import 'react-dates/lib/css/_datepicker.css';
// SCSS
import './scss/fonts.scss';
import './scss/style.scss';

import './globals';

import Init from './Init';
import Login from './views/Login';
import Logout from './views/Logout';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/login" exact name="Login" component={Login} />
      <Route path="/logout" exact name="Logout" component={Logout} />
      <Route path="/" name="Home" component={Init} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root'),
);

if (module.hot && process.env.REACT_APP_HMR) module.hot.accept();
