import { Api } from '../modules/Service';

const MReferal = {
  list: () => {
    return Api('/affiliate/list');
  },

  add: data => {
    return Api('/affiliate/new', JSON.stringify(data), 'POST', 'text');
  },
  update: data => {
    return Api(
      '/affiliate/set/' + data.id,
      JSON.stringify(data),
      'POST',
      'text',
    );
  },
  delete: id => {
    return Api('/affiliate/del/' + id, {}, 'GET', 'text');
  },
  get: id => {
    return Api('/affiliate/get/' + id);
  },
};
export default MReferal;
