import 'bootstrap';
import 'admin-lte';
import 'datatables.net';
import 'datatables.net-bs';
import 'admin-lte/plugins/iCheck/icheck.js';
import 'select2';
import 'bootstrap-datepicker';
import { Api } from './Service';
export const InitDatatable = (tblId, Pagi) => {
  const scrollX = $(window).width() < 768 ? true : false;
  if (typeof tblId != 'undefined') {
    $('#' + tblId).DataTable({
      scrollX: scrollX,
      aaSorting: [],
      bPaginate: Pagi,
      language: {
        lengthMenu: 'Perpage _MENU_',
        search: 'Search',
      },
    });
    return false;
  }
  $.each($('.tgt-datatable'), function() {
    if (!$(this).hasClass('dataTable')) {
      $(this).DataTable({
        scrollX: scrollX,
        aaSorting: [],
        bPaginate: Pagi,
        language: {
          lengthMenu: 'Perpage _MENU_',
          search: 'Search',
        },
      });
    }
  });
};

export const DatatableDelRow = (dtTbl, id) => {
  const node = document.getElementById(dtTbl);

  if (node) {
    const event = new CustomEvent('delete-row', { detail: `dt-item-${id}` });

    node.dispatchEvent(event);
  }
};

export const InitModal = () => {
  $('[data-toggle="modal"]').modal();
};
export const GetModal = id => {
  return $('#' + id);
};
export const ShowModal = id => {
  $('#' + id).modal('show');
};
export const HideModal = id => {
  $('#' + id).modal('hide');
};
export const InitIcheck = () => {
  $('input[type="checkbox"].icheck').iCheck({
    checkboxClass: 'icheckbox_minimal-green',
    radioClass: 'iradio_minimal-green',
  });
};
export const InitISelect2js = (key, configOptions = {}) => {
  const elem = $('#select2-' + key);
  const val = elem.val() || elem.attr('data-value');
  if (elem.hasClass('select2-hidden-accessible')) {
    elem.val(val).trigger('change');
    return false;
  }
  elem
    .select2({
      placeholder: 'select item',
      ...configOptions,
    })
    .val(val)
    .trigger('change');
};
export const InitISelect2jsAjax = (
  key,
  url,
  lookup_format,
  value,
  url_single,
) => {
  const elem = $('#select2-' + key);
  if (elem.hasClass('select2-hidden-accessible')) {
    return false;
  }
  elem.select2({
    placeholder: 'select item',
    ajax: {
      url: function(params) {
        return url + '/' + (params.term || '') + '?limit=10';
      },
      data: function(params) {
        return {};
      },
      processResults: function(data) {
        return {
          results: data,
        };
      },
      delay: 300,
      dataType: 'json',
      cache: false,
      crossDomain: true,
      xhrFields: {
        withCredentials: true,
      },
    },
    minimumInputLength: 1,
    escapeMarkup: function(markup) {
      return markup;
    },
    templateResult: function(res) {
      if (res.loading) {
        return res.text;
      }
      var opttext = lookup_format.replace(/{(.*?)}/g, ($1, $2) => {
        return res[$2] != null ? res[$2] : '-';
      });
      var markup = '<span>' + opttext + '</span>';
      return markup;
    },
    templateSelection: function(res) {
      if (res.selected || !res.id) return res.text;

      var opttext = lookup_format.replace(/{(.*?)}/g, ($1, $2) => {
        return res[$2] != null ? res[$2] : '-';
      });
      return opttext;
    },
  });
  if (value) {
    var loadingOpt = new Option('loading', '0', true, true);
    elem.append(loadingOpt).trigger('change');
    Api(url_single + '/' + value)
      .done(resp => {
        var opttext = lookup_format.replace(/{(.*?)}/g, ($1, $2) => {
          return resp[$2] != null ? resp[$2] : '-';
        });
        var optArgs = { value: resp.id, text: opttext, selected: true };
        elem.append($('<option>', optArgs)).trigger('change');
      })
      .catch(xhr => {
        alert(`error:\n\n${xhr.getResponseHeader('X-TBMS-Message')}`);
      });
  }
};
export const InitDate = data => {
  $('.datepicker').datepicker({
    autoclose: true,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
  });
};
