import React, { Component } from 'react';

class Textarea extends Component {
  render() {
    const { config, data } = this.props;
    const { label, key, required, className, heightRow } = config;
    const value = data != null ? data[key] : '';
    return [
      <label key="0">{label}</label>,
      <textarea
        key="1"
        name={key}
        defaultValue={value}
        rows={heightRow || 5}
        required={required}
        className={'form-control ' + (className || '')}
      />,
    ];
  }
}
export default Textarea;
