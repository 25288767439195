/* eslint-disable eqeqeq */

import * as React from 'react';
import moment from 'moment';

import { Link } from 'react-router-dom';
import MBikeCheckin from '../../models/MBikeCheckin';
import BikeCheckinForm from './BikeCheckinForm';
import BikeCheckinFilter from './BikeCheckinFilter';
import List from '../../components/List';
import { column as bikePriorityColumn } from '../../components/BikeCheckinPriority';

export default () => (
  <List
    model={MBikeCheckin}
    add
    edit
    delete
    editForm={BikeCheckinForm}
    filters={BikeCheckinFilter}
    columns={[
      'id',
      {
        key: 'timestamp',
        label: 'Time',
        toText: row => moment(row.timestamp).format('YYYY-MM-DD hh:mm:ss A'),
        cellRenderer: ({ cell }) => moment(cell).format('YYYY-MM-DD hh:mm A'),
      },
      { key: 'bike_numberplate', label: 'Bike' },
      { key: 'location_name', label: 'Location' },
      { key: 'odometer_x10', label: 'End of Contract Odometer (km)' },
      { key: 'ready_for_hire', label: 'Available?', type: 'yesno' },
      {
        key: 'username',
        label: 'Author',
        cellRenderer: ({ row }) => (
          <Link to={`/users?user=${row.user_id}`}>{row.username}</Link>
        ),
      },
      bikePriorityColumn,
    ]}
  />
);
