import * as React from 'react';
import { useForm, useField } from 'react-final-form';

import MBikeType from '../../models/MBikeType';
import {
  Field,
  Select,
  useWizardPageValues,
  validators,
} from '../../components/FinalForm';
import { useEndpoint, useOptions, useUUID } from '../../modules/Hooks';

const bikeTypeToOption = bikeType => ({
  value: bikeType.id,
  label: MBikeType.label(bikeType),
});

const useBikeTypeOptions = ({ isCity, contractRequestId }) => {
  const {
    request_bikes: requestBikes = [],
  } = useWizardPageValues().datesLocations;

  const contractRequestOptions = React.useMemo(() => {
    if (!requestBikes || !requestBikes.length) return [];

    return [
      {
        value: '-',
        label: '--- Contract Request Types ---',
        isDisabled: true,
      },
      ...requestBikes.map(bikeTypeToOption),
      { value: '-', label: '--- All Bike Types ---', isDisabled: true },
    ];
  }, [requestBikes]);

  const bikeTypeOptions = useOptions('/bike_type/list', bikeTypeToOption);

  return React.useMemo(
    () =>
      !contractRequestOptions || !bikeTypeOptions
        ? null
        : [...contractRequestOptions, ...bikeTypeOptions],
    [contractRequestOptions, bikeTypeOptions],
  );
};

const toBikeOption = bike => ({
  value: bike.id,
  label: `${bike.numberplate} / ${MBikeType.label(bike, false)}`,
  bike,
});

const ReadOnlyBikeField = React.memo(() => {
  const { values } = useForm().getState();

  return (
    <>
      <h5 className="col-xs-12">
        <b>Note:</b>{' '}
        <i>To change the bike, please use the "Swap Bike" function</i>
      </h5>
      <Field
        className="col-xs-12"
        name="bike_id"
        readOnly
        select={{
          options: [
            {
              value: values.bike_id,
              label: `${values.bike_numberplate} / ${MBikeType.label(
                values,
                false,
              )}`,
            },
          ],
        }}
        validate={validators.required}
      />
    </>
  );
});

const BikeFieldAndFilter = ({ isCity }) => {
  const bikeTypeSelectId = useUUID();
  const preselectedBikeTypeId = useField('bike_type_id').input.value;

  const [bikeTypeId, setBikeTypeId] = React.useState();

  React.useEffect(() => {
    setBikeTypeId(
      preselectedBikeTypeId ? Number(preselectedBikeTypeId) : undefined,
    );
  }, [preselectedBikeTypeId]);

  const pages = useWizardPageValues();
  const startLocationId =
    Number(pages.datesLocations.start_location_id) || undefined;
  const selectedId = useField('bike_id').input.value;
  const locationSummary = useEndpoint('/bike_location_report/summary');
  const allBikeOptions = useOptions('/bike/list', toBikeOption);

  const bikeTypeOptions = useBikeTypeOptions({
    isCity,
    contractRequestId: pages.datesLocations.contract_request_id,
  });

  const bikeOptions = React.useMemo(() => {
    if (!allBikeOptions) return null;
    if (!locationSummary) return null;

    if (!selectedId && !startLocationId) {
      return [
        {
          value: '-',
          label: 'You must first select a city',
          isDisabled: true,
        },
      ];
    }

    return allBikeOptions.filter(({ bike }) => {
      if (bike.id === selectedId) return true;
      if (bikeTypeId && bikeTypeId !== bike.type_id) return false;
      const { location_id, status, ready_for_hire } =
        locationSummary.bikes[bike.id] || {};

      return (
        location_id === startLocationId && ready_for_hire && status.checkin
      );
    });
  }, [
    allBikeOptions,
    locationSummary,
    selectedId,
    startLocationId,
    bikeTypeId,
  ]);

  return (
    <>
      <div className="form-group col-xs-12">
        <label htmlFor={bikeTypeSelectId}>Bike Type Filter</label>
        <Select
          id={bikeTypeSelectId}
          options={bikeTypeOptions}
          value={bikeTypeId}
          onChange={option => setBikeTypeId(option && option.value)}
          isClearable
        />
      </div>
      <Field
        className="col-xs-12"
        name="bike_id"
        select={{ options: bikeOptions }}
        validate={validators.required}
      />
    </>
  );
};

const ContractBikeWizardPage = ({ isCity }) => {
  const readonlyProps = useField('return_date').input.value
    ? { readOnly: true }
    : { readOnly: true, value: '' };

  const bikeIsReadOnly = Boolean(useField('id').input.value);

  return (
    <>
      {bikeIsReadOnly ? (
        <ReadOnlyBikeField />
      ) : (
        <BikeFieldAndFilter isCity={isCity} />
      )}
      <div className="col-sm-12">
        <div className="row">
          <Field
            className="col-sm-6"
            label="Start of Contract Odometer"
            name="bike_odometer_x10"
            type="number"
            validate={validators.composeValidators(
              validators.minValue(0),
              validators.integer,
            )}
          />
          <Field
            className="col-sm-6"
            label="End of Contract Odometer"
            name="return_checkin_odometer_x10"
            type="number"
            {...readonlyProps}
          />
        </div>
      </div>
    </>
  );
};

export default ContractBikeWizardPage;
