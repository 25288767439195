import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Cache from '../../modules/Cache';
import logoMiniSrc from './logo-mini.png';
import Logo from '../Logo';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: Cache.get('userLogin'),
    };
  }

  componentDidMount() {
    $('[data-toggle="push-menu"]').pushMenu();
  }

  render() {
    const { user } = this.state;
    return (
      <header className="main-header">
        <Link to="/" className="logo">
          <span className="logo-mini">
            <img src={logoMiniSrc} alt="Tigit Motorbikes" />
          </span>
          <span className="logo-lg">
            <Logo />
          </span>
        </Link>
        <nav className="navbar navbar-static-top">
          <button className="sidebar-toggle" data-toggle="push-menu" />
          <div className="navbar-custom-menu">
            <ul className="nav navbar-nav">
              <li className="dropdown user user-menu">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                  <span id="user-login-name" className="hidden-xs">
                    {user ? 'Hi, ' + user.username : ''}{' '}
                    <i className="fa fa-caret-down " />
                  </span>
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link to="/my-profile">My Profile</Link>
                  </li>
                  <li>
                    <Link to="/logout">Logout</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}
export default Header;
