import * as React from 'react';
import { orderBy, startCase } from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
import FuzzySearch from 'fuzzy-search';

import { YesNo } from '../modules/Helper';
import { useTableRegistration } from './Box';
import { usePropState, useThrottledState } from '../modules/Hooks';

const headerHeight = 38;
const pageSize = 50;
const pageBuffer = 10;

const presets = {
  date: {
    className: 'text-nowrap',
    headerStyle: { width: 50 },
    cellRenderer: ({ cell }) =>
      cell && moment(cell).isValid() ? moment(cell).format('YYYY-MM-DD') : cell,
  },
  datetime: {
    cellRenderer: ({ cell }) =>
      cell && moment(cell).isValid()
        ? moment(cell).format('YYYY-MM-DD hh:mm A')
        : cell,
  },
  check: {
    className: 'text-center',
    headerStyle: { width: 20 },
    disableSearch: true,
    cellRenderer: ({ cell }) =>
      Boolean(cell) && <i className="fa fa-check" aria-hidden="true" />,
  },
  yesno: {
    headerStyle: { width: 20 },
    disableSearch: true,
    cellRenderer: ({ cell }) => YesNo(cell),
  },
  number: {
    toText: (row, column) => {
      const cell = row[column.key];
      return cell == null ? cell : numeral(cell).format('0,0');
    },
  },
  currency: {
    cellRenderer: ({ cell }) =>
      cell != null && numeral(cell).format('$0,0[.]00'),
  },
};

const getColumn = column => {
  if (typeof column === 'string') {
    column = { key: column };
  }

  if (column.key === 'id') {
    column = {
      label: 'ID',
      headerStyle: { width: 50 },
      sortType: 'number',
      ...column,
    };
  } else if (column.key === 'action') {
    column = {
      headerClassName: 'text-center',
      className: 'box-action',
      disableSort: true,
      headerStyle: { width: 100, minWidth: 100 },
      ...column,
    };
  }

  if (column.type && presets[column.type]) {
    return {
      ...presets[column.type],
      ...column,
    };
  }

  return column;
};

const Table = ({
  loading,
  columns: rawColumns,
  data,
  renderRow,
  getRowKey = row => row.id,
  getRowClassName = () => '',
  filter,
  setFilter,
  className,
  hideControls = false,
}) => {
  useTableRegistration();

  React.useEffect(() => {
    if (filter === undefined && setFilter) {
      throw new Error('If `setFilter` is provided, `filter` must be defined');
    } else if (filter !== undefined && !setFilter) {
      throw new Error(
        'If `filter` is undefined, you cannot provide a `setFilter` method',
      );
    }
  }, [filter, setFilter]);

  const [searchInput, setSearchInput] = usePropState(filter || '');
  const [throttledSearchInput, setThrottledSearchInput] = useThrottledState(
    searchInput,
    150,
  );

  React.useEffect(() => {
    setThrottledSearchInput(searchInput);
  }, [searchInput, setThrottledSearchInput]);

  const [sortInfo, setSortInfo] = React.useState({});
  const [estimatedRowHeight, setEstimatedRowHeight] = React.useState(52);
  const [firstIndex, setFirstIndex] = React.useState(0);

  const columns = React.useMemo(() => rawColumns.map(getColumn), [rawColumns]);

  const rawData = React.useMemo(() => {
    return Array.isArray(loading || data) ? data : [];
  }, [loading, data]);

  const indices = React.useMemo(
    () => new Map(rawData.map((row, index) => [row, index])),
    [rawData],
  );

  const filteredData = React.useMemo(() => {
    if (!throttledSearchInput.trim()) return rawData;

    const searchableColumns = columns.filter(
      column => !column.disableSearch && !column.disableSort && column.key,
    );

    const searchableData = rawData.map((item, index) => ({
      index,
      ...Object.fromEntries(
        searchableColumns.map(column => [
          column.key,
          `${(column.toText ? column.toText(item, column) : item[column.key]) ||
            ''}`,
        ]),
      ),
    }));

    const searcher = new FuzzySearch(
      searchableData,
      searchableColumns.map(({ key }) => key),
      { sort: true },
    );

    return searcher
      .search(throttledSearchInput)
      .map(({ index }) => rawData[index]);
  }, [columns, rawData, throttledSearchInput]);

  const sortedData = React.useMemo(() => {
    if (!sortInfo.sortBy || !sortInfo.sortDirection) return filteredData;
    const column = columns.find(({ key }) => key === sortInfo.sortBy);

    const sortField = (column && column.sortBy) || sortInfo.sortBy;

    return orderBy(
      filteredData,
      [column.sortType === 'number' ? o => Number(o[sortField]) : sortField],
      [sortInfo.sortDirection],
    );
  }, [filteredData, sortInfo, columns]);

  const container = React.useRef(null);

  const toggleSort = ({ key }) => () =>
    setSortInfo(({ sortBy, sortDirection }) => ({
      sortBy: key,
      sortDirection: sortBy === key && sortDirection === 'asc' ? 'desc' : 'asc',
    }));

  const page = sortedData.slice(firstIndex, firstIndex + pageSize);
  const beforeHeight = estimatedRowHeight * firstIndex;
  const afterHeight =
    estimatedRowHeight * (sortedData.length - page.length - firstIndex);

  const adjustPage = () => {
    if (!container.current) return;

    const { scrollTop, scrollHeight } = container.current;

    if (page.length > 0) {
      const newEstimate =
        (scrollHeight - headerHeight - beforeHeight - afterHeight) /
        page.length;

      if (Math.abs(newEstimate - estimatedRowHeight) > 2) {
        setEstimatedRowHeight(newEstimate);
      }
    }

    const estimatedIndexOffset = Math.floor(
      (scrollTop - beforeHeight - headerHeight) / estimatedRowHeight,
    );

    if (
      firstIndex >= sortedData.length ||
      (estimatedIndexOffset < pageBuffer && firstIndex > 0) ||
      estimatedIndexOffset > 2 * pageBuffer
    ) {
      setFirstIndex(
        Math.max(
          0,
          Math.min(
            sortedData.length - pageSize,
            firstIndex + estimatedIndexOffset - pageBuffer,
          ),
        ),
      );
    }
  };

  // if sortedData changes, the container height or estimated row height could change
  React.useEffect(() => {
    adjustPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedData]);

  let recordCount = 'Loading...';

  if (!loading) {
    recordCount = `${rawData.length || 'No'} record`;
    if (rawData.length !== 1) recordCount += 's';
    if (sortedData.length !== rawData.length) {
      recordCount = `Showing ${sortedData.length} of ${recordCount}`;
    }
  }

  return (
    <div className={classnames('tgt-table', className)}>
      {!hideControls && (
        <div className="tgt-table--controls">
          <div className="tgt-table--record-count">{recordCount}</div>
          <label className="tgt-table--search">
            Search
            <input
              value={searchInput}
              onChange={e => {
                if (setFilter) {
                  setFilter(e.target.value);
                } else {
                  setSearchInput(e.target.value);
                }
                setSortInfo({});
              }}
            />
          </label>
        </div>
      )}
      <div
        className="tgt-table-container"
        ref={container}
        onScroll={adjustPage}
      >
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              {columns.map(column => (
                <th
                  key={column.key}
                  style={column.headerStyle}
                  className={classnames(
                    {
                      'tgt-table--sortable-column': !column.disableSort,
                    },
                    column.headerClassName,
                  )}
                  aria-sort={
                    sortInfo.sortBy === column.key
                      ? `${sortInfo.sortDirection}ending`
                      : null
                  }
                  onClick={column.disableSort ? null : toggleSort(column)}
                >
                  {column.label || startCase(column.key)}
                  {!column.disableSort && (
                    <div className="tgt-table--sort-icon" />
                  )}
                </th>
              ))}
            </tr>
          </thead>
          {!loading && (
            <tbody>
              <tr style={{ height: beforeHeight }} />
              {page.map((row, index) =>
                renderRow ? (
                  renderRow(row)
                ) : (
                  <tr key={getRowKey(row)} className={getRowClassName(row)}>
                    {columns.map(column => (
                      <td
                        key={column.key}
                        style={column.style}
                        className={column.className}
                      >
                        {column.cellRenderer
                          ? column.cellRenderer({
                              row,
                              column,
                              cell: row[column.key],
                              index: index + firstIndex,
                              originalIndex: indices.get(row),
                            })
                          : column.toText
                          ? column.toText(row, column)
                          : row[column.key]}
                      </td>
                    ))}
                  </tr>
                ),
              )}
              <tr style={{ height: afterHeight }} />
            </tbody>
          )}
        </table>
      </div>
      {loading && <div className="loading" />}
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.node,
        cellRenderer: PropTypes.func,
        disableSearch: PropTypes.bool,
        disableSort: PropTypes.bool,
        style: PropTypes.object,
        headerStyle: PropTypes.object,
        className: PropTypes.string,
        headerClassName: PropTypes.string,
        toText: PropTypes.func,
      }),
    ]),
  ).isRequired,
};

export default Table;
