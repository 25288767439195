/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import { InitISelectBikejs, InitISelectNumberjs } from '../../modules/Event2';
import { UniqueId, GetArgs } from '../../modules/Helper';
import { Api } from '../../modules/Service';
import Select2js from './Select2Bikejs';

class ContractSelectBike extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uniqueId: UniqueId(),
      // dataoriginal:[],
      dataselect: [],
      location_id: '',
      bike_type: GetArgs('bike_type'),
      loading: false,
    };
  }
  componentDidMount() {
    const { loading } = this.state;
    $('select[name="start_location_id"]').change(ev => {
      var loc_id = ev.target.value;
      if (loc_id != '') {
        if (!loading) {
          this.setState({
            location_id: loc_id,
          });
          this.renderData();
        }
      }
    });

    $('select[name="location_id"]').change(ev => {
      var loc_id = ev.target.value;
      if (loc_id != '') {
        if (!loading) {
          this.setState({
            location_id: loc_id,
          });
          this.renderData();
        }
      }
    });

    $('select[name="f-bike_type"]').change(ev => {
      const { bike_type } = this.state;
      var bike_type_id = ev.target.value;
      if (bike_type_id != '' && bike_type_id != bike_type) {
        if (!loading) {
          this.setState({
            bike_type: bike_type_id,
          });
          this.renderData();
        }
      }
    });
  }
  renderData() {
    const { location_id } = this.state;

    const bikeType = Number(this.state.bike_type);
    var lookup_url = '/bike_location_report/list';
    var query = [];
    query.push('ready_for_hire=true');
    if (location_id != '') query.push('location_id=' + location_id);
    if (bikeType) query.push(`type_id=${bikeType}`);
    this.setState({ loading: true });
    Api(lookup_url + '?' + query.join('&')).done(resp => {
      this.setState({
        dataselect: resp,
        loading: false,
      });
    });
    if (bikeType) {
      Api(`/bike_type/get/${bikeType}`).done(Bikeresp => {
        localStorage.setItem('bikeData', JSON.stringify(Bikeresp));
        this.setState({
          dataBikeTypeselect: Bikeresp,
          loading: false,
        });
      });
    }
    if (GetArgs('contract_request') != '') {
      var lookup_url2 = '/contract_request/get/' + GetArgs('contract_request');
      Api(lookup_url2).done(resp2 => {
        // console.log(resp2.request_bikes);
        this.setState({
          requestBikes: resp2.request_bikes,
          loading: false,
        });
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { dataselect } = this.state;
    const { key } = this.props.config;
    if (dataselect != null) {
      InitISelectBikejs(key);
      InitISelectNumberjs(key);
    }
  }

  render() {
    const { uniqueId, loading, dataselect, requestBikes } = this.state;

    const { config, data } = this.props;
    if (data != null) {
      var bike_type_id = data.bike_type_id;
      var bike_id = data.bike_id;
      var customerID = data.customer_id;
      setTimeout(() => {
        if (bike_type_id != undefined) {
          $('select#select2-f-bike_type')
            .find('option[value="' + bike_type_id + '"]')
            .prop('selected', true);
        }
        if (bike_id != undefined) {
          $('select#select2-bike_id')
            .find('option[value="' + bike_id + '"]')
            .prop('selected', true);
        }
        if (customerID != undefined) {
          $('.customer_select.select2-hidden-accessible')
            .find('option[value="0"]')
            .val(customerID);
        }
        // $("a#contract_1,a.btn.btn-sm.btn-success.AddContract").remove();
        $('span.Collapsible__trigger.is-closed').trigger('click');
      }, 1000);
    }
    const { label, key, lookup_key, required, default_value } = config;
    const value = data != null ? data[key] : '';
    return (
      <div key={uniqueId}>
        <Select2js
          requestBikes={requestBikes}
          config={{
            label: 'Bike',
            key: 'f-bike_type',
            placeholder: 'select bike type',
            default_value: GetArgs('bike_type'),
            lookup_url: '/bike_type/list',
            lookup_key: 'id',
            lookup_format:
              '{manufacturer_name} {model_name} {engine_capacity} {transmission_name}',
          }}
        />

        <label className="box-f-bike">
          <br />
          Plate
        </label>

        <select
          name={key}
          id={'select2-' + key}
          data-value={value || default_value}
          required={required}
          className="form-control bikeNumber"
          defaultValue=""
        >
          {(() => {
            switch (true) {
              case loading:
                return (
                  <option value="" disabled>
                    Loading...
                  </option>
                );
              case dataselect.length > 0 && !loading:
                const retVal = [];
                retVal.push(
                  <option key="0" value="">
                    select item
                  </option>,
                );
                retVal.push(
                  dataselect.map(item => (
                    <option
                      key={item.bike[lookup_key]}
                      value={item.bike[lookup_key]}
                      data-value={item.odometer_x10}
                    >
                      {item.bike.numberplate}
                    </option>
                  )),
                );
                return retVal;
              default:
                return <option value="">Item not found</option>;
            }
          })()}
        </select>
      </div>
    );
  }
}
export default ContractSelectBike;
