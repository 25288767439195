import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import fullMenu from '../../config.menu';

const Li = props => {
  const { name, icon, url } = props.data;
  return (
    <li>
      <Link to={url}>
        <i className={icon} /> <span>{name}</span>
      </Link>
    </li>
  );
};

const LiHasChild = props => {
  var UserData = localStorage.getItem('userLogin');
  var parsUserData = JSON.parse(UserData);
  var permission_level = parsUserData.permission_level;
  const { name, icon, children } = props.data;

  return (
    <li className="treeview">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#">
        <i className={icon} /> <span>{name}</span>
        <span className="pull-right-container">
          <i className="fa fa-angle-left pull-right" />
        </span>
      </a>
      <ul className="treeview-menu">
        {children.map((childItem, idx) => {
          if (permission_level < 15 && childItem.url === '/deleted-bikes') {
            return false;
          }
          if (typeof childItem.separate !== 'undefined') {
            return <LiSeparate key={idx} />;
          }
          return <Li key={idx} data={childItem} />;
        })}
      </ul>
    </li>
  );
};

const LiSeparate = () => {
  return (
    <li className="separate">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
      <a />
    </li>
  );
};
class Nav extends Component {
  componentDidMount() {
    $('[data-widget="tree"]').tree();
  }

  render() {
    var UserData = localStorage.getItem('userLogin');
    var parsUserData = JSON.parse(UserData);
    var permission_level = parsUserData.permission_level;
    let menu = fullMenu;

    if (permission_level === 1) {
      menu = {
        items: [
          { name: 'Dashboard', url: '/' },
          {
            name: 'Bikes',
            url: '#',
            icon: 'fa fa-motorcycle',
            children: [
              {
                name: 'Bike Maintenance',
                url: '/bike-maintenance',
                icon: 'fa fa-wrench',
              },
            ],
          },
        ],
      };
    } else if (permission_level === 5) {
      menu = {
        items: [
          { name: 'Dashboard', url: '/' },
          {
            name: 'Report',
            url: '#',
            icon: 'fa fa-bar-chart',
            children: [
              {
                name: 'Bike Location',
                url: '/report-bike-location',
                icon: 'fa fa-adjust',
              },
              { name: 'Returns', url: '/report-returns', icon: 'fa fa-random' },
              { name: 'City Rental', url: '/cityrental', icon: 'fa fa-random' },
            ],
          },
          {
            name: 'Transport',
            url: '#',
            icon: 'fa fa-truck',
            children: [
              { name: 'Transport', url: '/transport', icon: 'fa fa-random' },
            ],
          },
          {
            name: 'Customers',
            url: '#',
            icon: 'fa fa-address-book',
            children: [
              {
                name: 'Contract Requests',
                url: '/contract-requests',
                icon: 'fa fa-bullhorn',
              },
              { name: 'Contracts', url: '/contracts', icon: 'fa fa-book' },
            ],
          },
        ],
      };
    }

    return (
      <ul className="sidebar-menu" data-widget="tree">
        <li className="header">MAIN MENU</li>
        {menu.items.map((item, idx) => {
          if (typeof item.separate !== 'undefined') {
            return <LiSeparate key={idx} />;
          }
          if (typeof item.children === 'undefined') {
            return <Li key={idx} data={item} />;
          } else {
            return <LiHasChild key={idx} data={item} />;
          }
        })}
      </ul>
    );
  }
}
export default Nav;
