import React, { Component } from 'react';

class heading extends Component {
  render() {
    const { config } = this.props;
    const { label } = config;
    return [
      <h4 className="field_heading" key="1">
        {label}
      </h4>,
    ];
  }
}
export default heading;
