/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ImgView from '../../components/ImgView';
import { BtnCon } from '../../components/Btn';
const TableList = props => {
  setTimeout(function() {
    var Div1 = $('div#tgt-tab-1 table tbody tr').length;
    $('.rescount1').html('(' + Div1 + ')');
    var Div2 = $('div#tgt-tab-2 table tbody tr').length;
    $('.rescount2').html('(' + Div2 + ')');
    var Div3 = $('div#tgt-tab-3 table tbody tr').length;
    $('.rescount3').html('(' + Div3 + ')');
  }, 1000);

  const { data, locationid } = props;
  const datalist = data.filter(itmloc => {
    return itmloc.return_location_id === locationid;
  });

  return (
    <table className="table table-stripe table-hover table-bordered">
      <thead>
        <tr>
          <th>Contract</th>
          <th>Bike</th>
          <th>Start Date</th>
          <th>Return Date(est)</th>
          <th>Customer Email</th>
          <th>Contract Photo</th>
          <th>Damage Report Photo</th>
          <th>Notes</th>
          <th>Return Location Firm</th>
          <th>Return Date Firm</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {datalist.map((itmctr, idctr) => {
          const {
            id,
            bike_manufacturer_name,
            bike_model_name,
            bike_numberplate,
            bike_id,
            start_date,
            return_date_estimated,
            customer_id,
            customer_email,
            contract_photo_id,
            damage_report_photo_id,
            notes,
            return_location_firm,
            return_date_open,
            city_rental,
          } = itmctr;

          var CurrentDate = moment(new Date()).format('YYYY-MM-DD');
          var rDate = moment(return_date_estimated).format('YYYY-MM-DD');
          const rowClass = rDate < CurrentDate ? 'overdue' : '';

          return (
            <tr className={rowClass} key={idctr}>
              <td>
                <Link to={'/contracts?id=' + id}>#{id}</Link>
              </td>
              <td>
                <Link to={'/bikes?bike=' + bike_id}>
                  {bike_manufacturer_name} {bike_model_name} {bike_numberplate}
                </Link>
              </td>
              <td>{start_date}</td>
              <td>{return_date_estimated}</td>
              <td>
                <Link to={'/customers?customer=' + customer_id}>
                  {customer_email}
                </Link>
              </td>
              <td>
                <ImgView id={contract_photo_id} size="75px" />
              </td>
              <td>
                <ImgView id={damage_report_photo_id} size="75px" />
              </td>
              <td>{notes}</td>
              <td>
                {' '}
                {return_location_firm == 1 && (
                  <div className="allsteps">
                    <i className="fa fa-check" aria-hidden="true" />{' '}
                  </div>
                )}{' '}
                {return_location_firm == 0 && <div className="allsteps"> </div>}{' '}
              </td>

              <td>
                {' '}
                {return_date_open == 1 && (
                  <div className="allsteps">
                    <i className="fa fa-check" aria-hidden="true" />{' '}
                  </div>
                )}{' '}
                {return_date_open == 0 && <div className="allsteps"> </div>}{' '}
              </td>

              <td>
                <BtnCon
                  linkTo={`/${
                    city_rental ? 'cityrental' : 'contracts'
                  }/finish/${id}`}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
class ReturnsRender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      msg: '',
      data: null,
    };
  }

  render() {
    const { datalocation, data } = this.props;
    if (datalocation == null || datalocation.length < 0) {
      return <p className="text-center">Location not found !</p>;
    }
    return (
      <div className="box-block">
        <ul className="nav nav-tabs">
          {datalocation.map((itmloc, idloc) => {
            const { id, name } = itmloc;
            var UserData = localStorage.getItem('userLogin');
            var parsUserData = JSON.parse(UserData);
            var permission_level = parsUserData.permission_level;

            let location = 0;
            if (permission_level === 5) {
              location = parsUserData.default_location_id - 1;
              if (id != parsUserData.default_location_id) {
                return false;
              }
            }

            //console.log(idloc);
            return (
              <li key={idloc} className={idloc == location ? 'active' : ''}>
                <a data-toggle="tab" href={'#tgt-tab-' + id}>
                  {name}{' '}
                </a>
              </li>
            );
          })}
        </ul>
        <div className="tab-content">
          {datalocation.map((itmloc, idloc) => {
            const { id } = itmloc;
            var UserData = localStorage.getItem('userLogin');
            var parsUserData = JSON.parse(UserData);
            var permission_level = parsUserData.permission_level;
            const location =
              permission_level === 5 ? parsUserData.default_location_id - 1 : 0;

            return (
              <div
                key={idloc}
                id={'tgt-tab-' + id}
                className={
                  'tab-pane fade in ' + (idloc == location ? 'active' : '')
                }
              >
                <TableList locationid={id} data={data} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
export default ReturnsRender;
