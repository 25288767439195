/* eslint-disable eqeqeq */

import { Api } from '../modules/Service';
import { GetArgs } from '../modules/Helper';

const MBikeModel = {
  list: () => {
    var url = '/bike_model/list';
    const manufacturer = GetArgs('manufacturer');
    if (manufacturer != '') {
      url = '/bike_model/get/manufacturer/' + manufacturer;
    }
    return Api(url);
  },
  add: data => {
    return Api('/bike_model/new', JSON.stringify(data), 'POST', 'text');
  },
  update: data => {
    return Api(
      '/bike_model/set/' + data.id,
      JSON.stringify(data),
      'POST',
      'text',
    );
  },
  delete: id => {
    return Api('/bike_model/del/' + id, {}, 'GET', 'text');
  },
  get: id => {
    return Api('/bike_model/get/' + id);
  },
};
export default MBikeModel;
