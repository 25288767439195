import React from 'react';
import classnames from 'classnames';

import { useUUID } from '../modules/Hooks';

const BoxContext = React.createContext();

export const useTableRegistration = () => {
  const { registerTable } = React.useContext(BoxContext) || {};
  const tableID = useUUID();

  React.useEffect(() => {
    if (registerTable) return registerTable(tableID);
  }, [registerTable, tableID]);
};

const Box = ({ children, title, subheader, rightBox, className }) => {
  const [tables, setTables] = React.useState(new Set());

  const context = React.useMemo(
    () => ({
      registerTable(tableID) {
        setTables(ids => new Set([...ids, tableID]));

        return () =>
          setTables(ids => {
            const set = new Set([...ids]);
            set.delete(tableID);
            return set;
          });
      },
    }),
    [],
  );

  return (
    <BoxContext.Provider value={context}>
      <div
        className={classnames(
          'box',
          { '--contains-table': tables.size > 0 },
          className,
        )}
      >
        <div className="box-header">
          <h2 className="box-title">{title}</h2>
          <div className="pull-right">{rightBox}</div>
        </div>
        {Boolean(subheader) && (
          <div className="box-header box-subheader">{subheader}</div>
        )}
        <div className="box-body">{children}</div>
      </div>
    </BoxContext.Provider>
  );
};

export default Box;
