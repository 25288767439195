import React, { Component } from 'react';
class TableView extends Component {
  render() {
    const { data, labelWidth, valueWidth } = this.props;
    return (
      <table className="tgt-tableview table table-stripe table-hover">
        <tbody>
          {data.map((item, idx) => {
            const { title, value } = item;
            return (
              <tr key={idx}>
                <td width={labelWidth}>{title}</td>
                <td width="10px" className="text-center">
                  {' '}
                  :{' '}
                </td>
                <td width={valueWidth}>
                  {typeof value == 'function' ? value() : value}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}
export default TableView;
TableView.defaultProps = {
  labelWidth: '',
  valueWidth: '',
};
