import React from 'react';
import { Link } from 'react-router-dom';
import MContractRequest from '../../models/MContractRequest';
import { ViewNote } from '../../components/Btn';
import ContractRequestCheckout from './ContractRequestCheckout';
import List from '../../components/List';
import ContractRequestFilters from './ContractRequestFilters';

export default () => {
  const [showingNotes, setShowingNotes] = React.useState({});
  const toggleNote = id =>
    setShowingNotes(showing => ({ ...showing, [id]: !showing[id] }));

  return (
    <List
      model={MContractRequest}
      add
      edit
      delete
      filters={ContractRequestFilters}
      editForm={ContractRequestCheckout}
      beforeActions={[
        ({ row }) => ({
          id: 'view-payments',
          linkTo: `/payments?contract_request_id=${row.id}`,
          type: 'info',
          icon: 'fa-dollar',
        }),
        ({ row }) => ({
          id: 'add-payment',
          linkTo: `/payments?auto_click=new&contract_request_id=${row.id}`,
          type: 'success',
          icon: 'fa-dollar',
        }),
      ]}
      columns={[
        {
          key: 'start_date',
          type: 'date',
          label: 'Date',
        },
        {
          key: 'return_date_estimated',
          type: 'date',
          label: 'Return Date',
        },
        {
          key: 'details',
          label: 'Location/Details',
          toText: row =>
            [row.start_location_name, row.return_location_name].join(' '),
          cellRenderer: ({ row }) => (
            <React.Fragment>
              <div className="nowrap">
                {row.start_location_name || '-'} to{' '}
                {row.return_location_name || '-'}
              </div>
              <ViewNote
                visible={showingNotes[row.id]}
                onClick={() => toggleNote(row.id)}
              />
              {showingNotes[row.id] && (
                <div className="notes">
                  <b>Details</b>
                  <br />
                  {row.notes}
                </div>
              )}
            </React.Fragment>
          ),
        },
        {
          key: 'customer_email',
          label: 'Email',
          cellRenderer: ({ row }) => (
            <Link to={`/customers?customer=${row.customer_id}`}>
              {row.customer_email}
            </Link>
          ),
        },
        {
          key: 'request_bikes',
          label: 'Bike(s)',
          toText: row =>
            row.request_bikes
              .flatMap(bike => [
                bike.quantity,
                'x',
                bike.manufacturer_name,
                bike.model_name,
                `${bike.engine_capacity}cc`,
              ])
              .join(' '),
          cellRenderer: ({ row: contractRequest, cell: bikes }) =>
            bikes.map(bike => {
              const {
                quantity,
                manufacturer_name,
                model_name,
                engine_capacity,
              } = bike;

              const params = new URLSearchParams({
                contract_request_id: contractRequest.id,
                auto_click: 'new',
              });

              return (
                <div key={bike.id} className="contract-request-bikes">
                  {quantity} x {manufacturer_name} {model_name}{' '}
                  {engine_capacity}cc{' '}
                  <Link
                    to={`/contracts?${params}`}
                    className="btn btn-sm btn-default"
                    title="Make Contract"
                  >
                    <i className="fa fa-bookmark-o" />
                  </Link>
                </div>
              );
            }),
        },
      ]}
      modalSize="lg"
    />
  );
};
