/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import { InitISelect2js } from '../../modules/Event';
import { Api } from '../../modules/Service';

class TransportSelect2js extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataselect: [],
      loading: true,
    };
  }
  componentDidMount() {
    const { loading } = this.state;
    const { lookup_url } = this.props.config;
    if (loading) {
      Api(lookup_url).done(resp => {
        this.setState({
          dataselect: resp,
          loading: false,
        });
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { dataselect } = this.state;
    const { key } = this.props.config;
    if (dataselect != null) {
      InitISelect2js(key);
    }
  }
  render() {
    const { loading, dataselect } = this.state;
    const { config, data } = this.props;
    const { label, key, required, default_value } = config;
    const value = data != null ? data[key] : '';
    if (loading == false) {
      jQuery('.transport-select').attr('disabled', false);
    }
    return [
      <label key="0">{label}</label>,
      <select
        key="1"
        name={key}
        id={'select2-' + key}
        data-value={value || default_value}
        required={required}
        className="form-control select2 transport-select"
        defaultValue=""
        disabled="true"
      >
        {(() => {
          switch (true) {
            case loading:
              return (
                <option value="" disabled>
                  Loading...
                </option>
              );
            case dataselect.length > 0 && !loading:
              const retVal = [];
              retVal.push(
                <option key="0" value="">
                  select item
                </option>,
              );
              retVal.push(
                dataselect.map(item => (
                  <option key={item.bike.id} value={item.bike.id}>
                    {item.bike.numberplate}
                  </option>
                )),
              );
              return retVal;
            default:
              return <option value="">Item not found</option>;
          }
        })()}
      </select>,
    ];
  }
}
export default TransportSelect2js;
