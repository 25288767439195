import * as React from 'react';

import { EditForm, Field, validators } from '../../components/FinalForm';
import List from '../../components/List';
import MTransportType from '../../models/MTransportType';

const TransportTypeForm = props => (
  <EditForm {...props}>
    <Field
      className="col-xs-12"
      name="name"
      label="Transport Type"
      validate={validators.required}
    />
  </EditForm>
);

export default () => (
  <List
    model={MTransportType}
    add
    edit
    delete
    editForm={TransportTypeForm}
    columns={['id', { key: 'name', label: 'Transport Type' }]}
  />
);
