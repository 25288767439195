import React, { Fragment, Component } from 'react';
import ImgView from '../ImgView';
import { ApiUpload } from '../../modules/Service';

class PhotoUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      loading: false,
    };
  }
  upload(e) {
    this.setState({ loading: true });
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('photo', file);
    ApiUpload('/photo/new', formData)
      .done((resp, txtStatus, req) => {
        const value = req.getResponseHeader('X-TBMS-New-ID');

        this.setState({ loading: false, value });
        if (this.props.onChange) this.props.onChange(value);
      })
      .catch(() => {
        alert('Upload error!');
      });
  }
  removeImage(ev) {
    ev.preventDefault();
    this.setState({ value: '' });
    if (this.props.onChange) this.props.onChange(undefined);
  }
  renderImage(newvalue) {
    if (this.state.loading) {
      return (
        <div className="progress active" style={{ width: '100%' }}>
          <div
            className="progress-bar progress-bar-success progress-bar-striped"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: '100%' }}
          />
        </div>
      );
    }

    if (newvalue) {
      return (
        <Fragment>
          <ImgView id={newvalue} size="260px" />
          <button
            className="btn btn-danger btn-sm"
            onClick={e => this.removeImage(e)}
          >
            Remove
          </button>
        </Fragment>
      );
    }

    return (
      <input
        type="file"
        name={`img_${this.props.config.key}`}
        required={this.props.config.required}
        className="form-control photo-upload"
        accept="image/*"
        onChange={e => this.upload(e)}
      />
    );
  }

  render() {
    const { value } = this.state;
    const { config, data } = this.props;
    const { label, key } = config;

    let newvalue;
    if (config.default_value !== undefined) {
      newvalue = config.default_value;
    } else {
      newvalue = data != null && value === null ? data[key] : value;
    }

    return (
      <Fragment>
        <label>{label}</label>
        <div className="box-upload text-center">
          <input
            type="hidden"
            name={key}
            value={newvalue || ''}
            className="hidden"
          />
          {this.renderImage(newvalue)}
        </div>
      </Fragment>
    );
  }
}
export default PhotoUpload;
