import React from 'react';
import { useFormState } from 'react-final-form';

import {
  EditForm,
  Field,
  Heading,
  validators,
} from '../../components/FinalForm';

const CalculatedValueField = ({ value, ...props }) => {
  const { values } = useFormState({ subscription: { values: true } });

  return <Field {...props} value={value(values)} />;
};

const maxCost = (numMonths, dailyCost, ...monthlyMaxes) => {
  if (dailyCost > 0) {
    let currentMonthlyMax = 30 * Number(dailyCost);

    let totalMax = 0;

    for (let month = 0; month < numMonths; month++) {
      currentMonthlyMax = Number(
        monthlyMaxes[month] > 0 ? monthlyMaxes[month] : currentMonthlyMax,
      );

      totalMax += currentMonthlyMax;
    }

    const prices = [numMonths * 30 * dailyCost, totalMax].filter(
      number => number > 0,
    );

    if (prices.length) return Math.min(...prices);
  }

  return 'Invalid Input';
};

const modelToOption = ({ id, manufacturer_name, name }) => ({
  value: id,
  label: `${manufacturer_name || '-'} / ${name || '-'}`,
});

const transmissionToOption = ({ id, name }) => ({
  value: id,
  label: name || '-',
});

export default props => (
  <EditForm {...props}>
    <div className="col-sm-6">
      <Field
        name="model_id"
        label="Model"
        validate={validators.required}
        select={{
          endpoint: '/bike_model/list',
          toOption: modelToOption,
        }}
      />
      <Field
        name="transmission_id"
        label="Transmission"
        validate={validators.required}
        select={{
          endpoint: '/bike_transmission/list',
          toOption: transmissionToOption,
        }}
      />
    </div>
    <div className="col-sm-6">
      <Field
        name="engine_capacity"
        label="Engine Capacity (cc)"
        type="number"
        validate={validators.composeValidators(
          validators.required,
          validators.minValue(0),
        )}
      />
    </div>

    <Heading title="Pricing" />
    <Field
      className="col-sm-6"
      name="cost_duration_max_months"
      label="Max Months"
      type="number"
    />
    <Field
      className="col-sm-6"
      name="cost_deposit"
      label="Deposit"
      type="number"
    />

    <Heading title="Rental" />
    <Field
      className="col-sm-6"
      name="cost_rental_per_day"
      label="Day Rate"
      type="number"
    />
    <CalculatedValueField
      className="col-sm-6"
      readOnly
      disabled
      label="30 Day Cost"
      value={values => maxCost(1, values.cost_rental_per_day)}
    />
    <Field
      className="col-sm-6"
      name="cost_rental_max_month_1"
      label="Max Month 1"
      type="number"
    />
    <CalculatedValueField
      className="col-sm-6"
      readOnly
      disabled
      label="1 Month Cost"
      value={values =>
        maxCost(1, values.cost_rental_per_day, values.cost_rental_max_month_1)
      }
    />
    <Field
      className="col-sm-6"
      name="cost_rental_max_month_n"
      label="Max Month n"
      type="number"
    />
    <CalculatedValueField
      className="col-sm-6"
      readOnly
      disabled
      label="2 Month Cost"
      value={values =>
        maxCost(
          2,
          values.cost_rental_per_day,
          values.cost_rental_max_month_1,
          values.cost_rental_max_month_n,
        )
      }
    />

    <Heading title="Damage Waiver" />
    <Field
      className="col-sm-6"
      name="cost_damage_per_day"
      label="Day Rate"
      type="number"
    />
    <CalculatedValueField
      className="col-sm-6"
      readOnly
      disabled
      label="30 Day Cost"
      value={values => maxCost(1, values.cost_damage_per_day)}
    />
    <Field
      className="col-sm-6"
      name="cost_damage_max_month_1"
      label="Max Month 1"
      type="number"
    />
    <CalculatedValueField
      className="col-sm-6"
      readOnly
      disabled
      label="1 Month Cost"
      value={values =>
        maxCost(1, values.cost_damage_per_day, values.cost_damage_max_month_1)
      }
    />
    <Field
      className="col-sm-6"
      name="cost_damage_max_month_2"
      label="Max Month 2"
      type="number"
    />
    <CalculatedValueField
      className="col-sm-6"
      readOnly
      disabled
      label="2 Month Cost"
      value={values =>
        maxCost(
          2,
          values.cost_damage_per_day,
          values.cost_damage_max_month_1,
          values.cost_damage_max_month_2,
        )
      }
    />
    <Field
      className="col-sm-6"
      name="cost_damage_max_month_n"
      label="Max Month n"
      type="number"
    />
    <CalculatedValueField
      className="col-sm-6"
      readOnly
      disabled
      label="3 Month Cost"
      value={values =>
        maxCost(
          3,
          values.cost_damage_per_day,
          values.cost_damage_max_month_1,
          values.cost_damage_max_month_2,
          values.cost_damage_max_month_n,
        )
      }
    />

    <Heading title="Stock Prediction" />

    <Field
      className="col-sm-6"
      label="Warning Threshold"
      name="stock_warning"
      type="number"
    />

    <Field
      className="col-sm-6"
      label="Emergency Threshold"
      name="stock_emergency"
      type="number"
    />
  </EditForm>
);
