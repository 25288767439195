import BaseModel from './BaseModel';

const MBikeCheckin = {
  ...BaseModel,

  title: 'Bike Checkin',
  endpoint: '/bike_checkin',

  validListFilters: [
    'location_id',
    'limit_past',
    'numberplate',
    'bike_type_id',
    'id',
    'ready_for_hire',
  ],

  modifyListParams(params) {
    if (params.get('limit_past') === 'none') params.delete('limit_past');

    return params;
  },

  serialize({ priority, ...data }) {
    return BaseModel.serialize(data);
  },
};
export default MBikeCheckin;
