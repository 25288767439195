import React, { Component } from 'react';
import { Money, UniqueId } from '../../modules/Helper';
import Box from '../../components/Box';
import MInventoryItem from '../../models/MInventoryItem';
import { BtnAdd, BtnEdit, BtnDelete } from '../../components/Btn';
import { Datatable } from '../../components/Datatable';
import { Modal } from '../../components/Modal';
import { InventoryItemForm } from './InventoryItemForm';
import { GetModal, ShowModal, DatatableDelRow } from '../../modules/Event';
class InventoryItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uniqueId: UniqueId(),
      loading: true,
      data: null,
      modalId: 'mdl-form',
      modalOpened: false,
      editData: null,
    };
  }
  renderData() {
    MInventoryItem.list()
      .done(resp => {
        resp = Array.isArray(resp) ? resp : [resp];
        this.setState({
          uniqueId: UniqueId(),
          loading: false,
          data: resp,
        });
      })
      .catch(xhr => {
        this.setState({
          uniqueId: UniqueId(),
          loading: false,
          data: [],
        });
      });
  }
  componentDidMount() {
    const { modalId } = this.state;
    this.renderData();
    GetModal(modalId).on('hidden.bs.modal', () => {
      this.setState({ modalOpened: false });
    });
  }
  addItem(ev) {
    ev.preventDefault();
    const { modalId } = this.state;
    this.setState({ editData: null, modalOpened: true });
    ShowModal(modalId);
  }
  editItem(ev, id) {
    ev.preventDefault();
    const { modalId } = this.state;
    const elBtn = $(ev.target);
    elBtn.addClass('loading');
    MInventoryItem.get(id)
      .done(resp => {
        this.setState({
          modalOpened: true,
          editData: resp,
        });
        elBtn.removeClass('loading');
        ShowModal(modalId);
      })
      .catch(xhr => {
        elBtn.removeClass('loading');
        alert(
          'Edit error #' + id + '.' + xhr.getResponseHeader('X-TBMS-Message'),
        );
      });
  }
  deleteItem(ev, id) {
    ev.preventDefault();
    if (window.confirm('Delete Item #' + id + ' ?')) {
      const elBtn = $(ev.target);
      elBtn.addClass('loading');
      MInventoryItem.delete(id)
        .done(() => {
          elBtn.removeClass('loading');
          const dtTbl = elBtn.closest('.tgt-datatable').attr('id');
          DatatableDelRow(dtTbl, id);
        })
        .catch(xhr => {
          elBtn.removeClass('loading');
          alert(xhr.getResponseHeader('X-TBMS-Message'));
        });
    }
  }
  render() {
    const {
      uniqueId,
      loading,
      data,
      modalId,
      modalOpened,
      editData,
    } = this.state;
    const thead = ['ID', 'Name', 'Brand', 'Sale Price', 'Rentable', 'Action'];
    return [
      <Box
        key={uniqueId}
        title="Item lists"
        rightBox={<BtnAdd clickHandler={e => this.addItem(e)} />}
      >
        <Datatable loading={loading} thead={thead}>
          {data != null &&
            !loading &&
            data.map((item, idx) => {
              const { id, name, brand, sale_price_vnd_x100 } = item;
              return (
                <tr key={idx} id={'dt-item-' + id}>
                  <td style={{ width: 50 }}>{id}</td>
                  <td>{name}</td>
                  <td>{brand}</td>
                  <td>{Money(sale_price_vnd_x100, 100)}</td>
                  <td>
                    <div className="allsteps">
                      <i className="fa fa-check" aria-hidden="true" />{' '}
                    </div>
                  </td>
                  <td className="box-action" style={{ width: 80 }}>
                    <BtnEdit clickHandler={e => this.editItem(e, id)} />
                    <BtnDelete clickHandler={e => this.deleteItem(e, id)} />
                  </td>
                </tr>
              );
            })}
        </Datatable>
      </Box>,
      <Modal
        key="1"
        id={modalId}
        title={
          editData != null
            ? 'Edit InventoryItem #' + editData.id
            : 'Add InventoryItem'
        }
        size="mm"
      >
        {modalOpened && (
          <InventoryItemForm
            modalId={modalId}
            data={editData}
            RefreshParentData={() => this.renderData()}
          />
        )}
      </Modal>,
    ];
  }
}
export default InventoryItems;
