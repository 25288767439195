import React, { Component } from 'react';
import Box from '../../components/Box';
import { UniqueId } from '../../modules/Helper';
import {
  BtnHire,
  BtnWorkshop,
  BtnWorkshopPriority,
} from '../../components/Btn';
import { Datatable } from '../../components/Datatable';
import { Modal } from '../../components/Modal';
import Transport from '../../models/MTransport';
import TransportCheckout from './TransportCheckout';
import Input from '../../components/Fields/Input';
import MBikeCheckin from '../../models/MBikeCheckin';
import Select2js from '../../components/Fields/Select2js1';
class TransportCheckoutFinish extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uniqueId: UniqueId(),
      loading: true,
      data: null,
      modalId: 'mdl-form',
      modalOpened: false,
      editData: null,
    };
  }
  renderData() {
    const transportID = this.props.match.params.finishid || '';
    Transport.get(transportID)
      .done(data => {
        this.setState({
          uniqueId: UniqueId(),
          loading: false,
          data,
        });
      })
      .catch(xhr => {
        this.setState({
          uniqueId: UniqueId(),
          loading: false,
          data: null,
        });
      });
  }

  ReadyForHire(ev, item) {
    console.log(item);
    ev.preventDefault();
    this.setState({ submitLoading: true });
    var tr = '#dt-item-' + item.id;
    var odometer_x10 = $(tr + ' input[name="odometer_x10"]').val();
    var arrival_location = $(tr + ' .select2 option:selected').val();
    var formData = [];
    formData.push({
      id: '',
      timestamp: null,
      bike_id: item.id,
      location_id: arrival_location,
      odometer_x10: odometer_x10,
      notes_office: null,
      ready_for_hire: true,
      priority_numeric: 3,
    });
    MBikeCheckin.add(formData[0])
      .done(resp => {
        this.renderData();
        this.setState({
          uniqueId: UniqueId(),
          submitLoading: false,
        });
      })
      .catch(xhr => {
        alert(
          'Error #' +
            item.numberplate +
            '.  ' +
            xhr.getResponseHeader('X-TBMS-Message'),
        );
      });
  }

  Workshop(ev, item) {
    ev.preventDefault();
    this.setState({ submitLoading: true });
    var formData = [];
    var tr = '#dt-item-' + item.id;
    var odometer_x10 = $(tr + ' input[name="odometer_x10"]').val();
    var arrival_location = $(tr + ' .select2 option:selected').val();
    formData.push({
      id: '',
      timestamp: null,
      bike_id: item.id,
      location_id: arrival_location,
      odometer_x10: odometer_x10,
      notes_office: null,
      ready_for_hire: false,
      priority_numeric: 3,
    });
    MBikeCheckin.add(formData[0])
      .done(resp => {
        $(window).scrollTop(0);
        this.setState({
          submitLoading: false,
        });
        this.renderData();
      })
      .catch(xhr => {
        alert(
          'Error #' +
            item.numberplate +
            '.  ' +
            xhr.getResponseHeader('X-TBMS-Message'),
        );
      });
  }

  WorkshopPriority(ev, item) {
    ev.preventDefault();
    this.setState({ submitLoading: true });
    var formData = [];
    var tr = '#dt-item-' + item.id;
    var odometer_x10 = $(tr + ' input[name="odometer_x10"]').val();
    var arrival_location = $(tr + ' .select2 option:selected').val();
    formData.push({
      id: '',
      timestamp: null,
      bike_id: item.id,
      location_id: arrival_location,
      odometer_x10: odometer_x10,
      notes_office: null,
      ready_for_hire: false,
      priority_numeric: 1,
    });
    MBikeCheckin.add(formData[0])
      .done(resp => {
        $(window).scrollTop(0);
        this.setState({
          submitLoading: false,
        });
        this.renderData();
      })
      .catch(xhr => {
        alert(
          'Error #' +
            item.numberplate +
            '.  ' +
            xhr.getResponseHeader('X-TBMS-Message'),
        );
      });
  }

  componentDidMount() {
    this.renderData();
  }

  render() {
    const {
      uniqueId,
      loading,
      data,
      modalId,
      modalOpened,
      editData,
    } = this.state;
    const thead = [
      'Bike Numberplate ',
      'Bike Model',
      'Bike Odometer',
      'Return Location',
      'Check In',
    ];
    return [
      <Box key={uniqueId} title="Confirm Transport">
        <Datatable loading={loading} thead={thead}>
          {this.state.data != null &&
            !loading &&
            data.bikes.map((item, idx) => {
              const {
                id,
                numberplate,
                model_name,
                odometer_x10,
                manufacturer_name,
              } = item;
              var unconfirmed = data.bike_ids_unconfirmed;
              var unconfirmedBikes = unconfirmed.indexOf(id);
              if (unconfirmedBikes > -1) {
                return (
                  <tr key={idx} id={'dt-item-' + id}>
                    <td>{numberplate}</td>
                    <td>
                      {manufacturer_name} {model_name}
                    </td>
                    <td>
                      <Input
                        config={{
                          label: '',
                          key: 'odometer_x10',
                          default_value: odometer_x10,
                        }}
                      />
                    </td>

                    <td>
                      <Select2js
                        config={{
                          label: '',
                          key: 'return_location_id',
                          default_value: this.state.data.arrival_location_id,
                          lookup_url: '/location/list',
                          lookup_key: 'id',
                          lookup_format: '{name}',
                        }}
                      />
                    </td>
                    <td className="box-action" style={{ width: 320 }}>
                      <BtnHire clickHandler={e => this.ReadyForHire(e, item)} />
                      <BtnWorkshop clickHandler={e => this.Workshop(e, item)} />
                      <BtnWorkshopPriority
                        clickHandler={e => this.WorkshopPriority(e, item)}
                      />
                    </td>
                  </tr>
                );
              }

              return null;
            })}
        </Datatable>
      </Box>,
      <Modal
        key="1"
        id={modalId}
        title={
          editData != null ? 'Edit Transport #' + editData.id : 'Transport'
        }
        size="mm"
      >
        {modalOpened && (
          <TransportCheckout
            modalId={modalId}
            data={editData}
            RefreshParentData={() => this.renderData()}
          />
        )}
      </Modal>,
    ];
  }
}
export default TransportCheckoutFinish;
