import React, { Component } from 'react';
import { InitISelect2jsAjax } from '../../modules/Event';
import { getFullURL } from '../../modules/Service';

class Select2jsAjax extends Component {
  componentDidMount() {
    const { data } = this.props;
    const {
      lookup_url,
      lookup_url_single,
      lookup_format,
      key,
    } = this.props.config;
    const value = data != null ? data[key] : '';

    InitISelect2jsAjax(
      key,
      getFullURL(lookup_url),
      lookup_format,
      value,
      lookup_url_single,
    );
  }
  render() {
    const { config, data } = this.props;
    const { label, key, required, default_value } = config;
    const value = data != null ? data[key] : '';
    return [
      <label key="0">{label}</label>,
      <select
        key="1"
        name={key}
        id={'select2-' + key}
        data-value={value || default_value}
        required={required}
        className="form-control select2"
        defaultValue=""
      />,
    ];
  }
}
export default Select2jsAjax;
