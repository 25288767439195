import * as React from 'react';
import { useField } from 'react-final-form';

import { Modal } from '../../components/Modal';
import {
  EditForm,
  Field,
  Select,
  validators,
} from '../../components/FinalForm';
import {
  usePropState,
  useEndpoint,
  useOptions,
  useUUID,
} from '../../modules/Hooks';
import { saveItem } from '../../modules/FormHelper';
import MContract from '../../models/MContract';
import MBikeType from '../../models/MBikeType';
import { options as priorityOptions } from '../../components/BikeCheckinPriority';

const bikeTypeToOption = bikeType => ({
  value: bikeType.id,
  label: MBikeType.label(bikeType),
});

const BikeFields = () => {
  const bikeTypeOptions = useOptions('/bike_type/list', bikeTypeToOption);
  const bikeTypeSelectId = useUUID();
  const preselectedBikeTypeId = useField('bike_type_id').input.value;

  const [bikeTypeId, setBikeTypeId] = React.useState();

  React.useEffect(() => {
    setBikeTypeId(
      preselectedBikeTypeId ? Number(preselectedBikeTypeId) : undefined,
    );
  }, [preselectedBikeTypeId]);

  const startLocationId =
    Number(useField('location_id').input.value) || undefined;

  const newBikeId = useField('new_bike_id').input.value;
  const locationSummary = useEndpoint('/bike_location_report/summary');
  const allBikeOptions = useOptions('/bike/list', bike => ({
    value: bike.id,
    label: `${bike.numberplate} / ${MBikeType.label(bike, false)}`,
    bike,
  }));

  const newBikeOptions = React.useMemo(() => {
    if (!allBikeOptions) return null;
    if (!locationSummary) return null;

    if (!newBikeId && !startLocationId) {
      return [
        {
          value: '-',
          label: 'You must first select a city',
          isDisabled: true,
        },
      ];
    }

    return allBikeOptions.filter(({ bike }) => {
      if (bike.id === newBikeId) return true;
      if (bikeTypeId && bikeTypeId !== bike.type_id) return false;
      const { location_id, status, ready_for_hire } =
        locationSummary.bikes[bike.id] || {};

      return (
        location_id === startLocationId && ready_for_hire && status.checkin
      );
    });
  }, [allBikeOptions, locationSummary, newBikeId, startLocationId, bikeTypeId]);

  return (
    <>
      <div className="form-group col-xs-12">
        <label htmlFor={bikeTypeSelectId}>Bike Type Filter</label>
        <Select
          id={bikeTypeSelectId}
          options={bikeTypeOptions}
          value={bikeTypeId}
          onChange={option => setBikeTypeId(option && option.value)}
          isClearable
        />
      </div>
      <div className="col-xs-12">
        <div className="row">
          <Field
            className="col-xs-12 col-sm-6"
            name="new_bike_id"
            select={{
              options: newBikeOptions,
              isClearable: true,
            }}
            validate={validators.required}
          />
          <Field
            className="col-xs-12 col-sm-6"
            label="New Bike Odometer (km)"
            name="new_bike_odometer_x10"
            type="number"
            validate={validators.composeValidators(
              validators.required,
              validators.integer,
              validators.minValue(0),
            )}
          />
        </div>
      </div>
      <div className="col-xs-12">
        <div className="row">
          <Field
            className="col-xs-12 col-sm-6"
            name="old_bike_id"
            readOnly
            select={{ options: allBikeOptions }}
          />
          <Field
            className="col-xs-12 col-sm-6"
            label="Old Bike Odometer (km)"
            name="odometer_x10"
            type="number"
            validate={validators.composeValidators(
              validators.required,
              validators.integer,
              validators.minValue(0),
            )}
          />
        </div>
      </div>
    </>
  );
};

const ChangeBikeForm = ({ contract, resolve, afterClose }) => {
  const [open, setOpen] = usePropState(Boolean(contract));

  const formData = React.useMemo(
    () => ({
      id: contract.id,
      old_bike_id: contract.bike_id,
      bike_type_id: contract.bike_type_id,
      location_id: contract.start_location_id,
    }),
    [contract],
  );

  return (
    <Modal
      open={open}
      title={contract ? `Change Contract Bikes #${contract.id}` : ''}
      onClose={() => resolve && resolve()}
      afterClose={afterClose}
      size="lg"
    >
      {contract && (
        <EditForm
          data={formData}
          onSubmit={async (values, form) => {
            const errors = await saveItem({
              model: MContract,
              method: 'swap_bike',
              transform: () => values,
            })(values, form);

            if (errors) return errors;

            resolve({ refreshData: true });
            setOpen(false);
          }}
          submitText="Finish"
        >
          <Field
            className="col-xs-12"
            label="Swap Location"
            name="location_id"
            select={{ endpoint: '/location/list' }}
          />

          <BikeFields />

          <Field
            className="col-xs-12 col-md-6"
            label="Old Notes for Mechanic"
            name="notes_mechanic"
            type="textarea"
            rows={4}
          />
          <Field
            className="col-xs-12 col-md-6"
            label="Old Notes for Office"
            name="notes_office"
            type="textarea"
            rows={4}
          />

          <Field
            className="col-xs-12"
            label="Priority"
            name="priority_numeric"
            select={{ options: priorityOptions }}
            defaultValue={3}
          />

          <Field className="col-xs-12" name="ready_for_hire" type="checkbox" />
        </EditForm>
      )}
    </Modal>
  );
};

export default ChangeBikeForm;
