/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import { InitISelect2js } from '../../modules/Event';
import { UniqueId, GetArgs } from '../../modules/Helper';
import Select2js from './Select2js';

class ContractSelectBike extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uniqueId: UniqueId(),
      // dataoriginal:[],
      dataselect: [],
      location_id: '',
      bike_type: GetArgs('bike_type'),
      loading: false,
    };
  }
  componentDidMount() {
    const { loading } = this.state;
    $('select[name="start_location_id"]').change(ev => {
      var loc_id = ev.target.value;
      if (loc_id != '') {
        if (!loading) {
          this.setState({
            location_id: loc_id,
          });
          this.renderData();
        }
      }
    });
    $('select[name="f-bike_type"]').change(ev => {
      const { bike_type } = this.state;
      var bike_type_id = ev.target.value;
      if (bike_type_id != '' && bike_type_id != bike_type) {
        if (!loading) {
          this.setState({
            bike_type: bike_type_id,
          });
          //this.renderData();
        }
      }
    });
  }
  // renderData(){
  // const {location_id, bike_type} = this.state;
  // var lookup_url = '/bike/list';
  // var query = [];
  // query.push('ready_for_hire=true');
  // if(location_id != '')
  // query.push('location_id='+location_id);
  // if(bike_type != '')
  // query.push('type_id='+bike_type);
  // this.setState({ loading:true });
  // Api(lookup_url+'?'+query.join('&')).done((resp) => {
  // this.setState({
  // dataselect:resp,
  // loading:false
  // });
  // });
  // }
  componentDidUpdate(prevProps, prevState) {
    const { dataselect } = this.state;
    const { key } = this.props.config;
    if (dataselect != null) {
      InitISelect2js(key);
    }
  }
  render() {
    const { uniqueId } = this.state;
    const { config } = this.props;
    const { label } = config;
    return (
      <div key={uniqueId}>
        <label className="box-f-bike">{label}</label>
        <Select2js
          config={{
            label: '',
            key: 'f-bike_type',
            placeholder: 'select bike type',
            default_value: GetArgs('bike_type'),
            lookup_url: '/bike_type/list',
            lookup_key: 'id',
            lookup_format: '{model_name}',
          }}
        />
      </div>
    );
  }
}
export default ContractSelectBike;
