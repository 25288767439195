import React, { Component } from 'react';
import MLocation from '../../models/MLocation';
import Box from '../../components/Box';
import MReport from '../../models/MReport';
import Alert from '../../components/Alert';
import ReturnsRender from './ReturnsRender';
import { Form, FormGroup } from '../../components/Form';
import ModelSelect2js from '../../components/Fields/ModelSelect2js';
import Select2jsAjax from '../../components/Fields/Select2jsAjax';
import DatePicker from '../../components/Fields/DatePicker';
import { AppendUrl, GetArgs } from '../../modules/Helper';
import Select from '../../components/Fields/Select';

import Input from '../../components/Fields/Input';
class RReturns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      msg: '',
      datalocation: null,
      data: null,
      listStatus: {
        all: 'All',
        before: 'Before',
        after: 'After',
      },
    };
  }
  renderData() {
    var params =
      'date=' +
      GetArgs('date') +
      '&f-type=' +
      GetArgs('f-type') +
      '&bike_type_id=' +
      GetArgs('bike_type_id') +
      '&customer_id=' +
      GetArgs('customer_id') +
      '&numberplate=' +
      GetArgs('numberplate');

    MLocation.list()
      .done(resp => {
        MReport.returns(params)
          .done(resp2 => {
            this.setState({
              loading: false,
              datalocation: resp,
              data: resp2,
            });
          })
          .catch(xhr => {
            this.setState({
              msg: (
                <Alert
                  msg={xhr.getResponseHeader('X-TBMS-Message')}
                  type="danger"
                  closeHandler={() => {
                    this.setState({ msg: '' });
                  }}
                />
              ),
              loading: false,
              data: null,
            });
          });
      })
      .catch(xhr => {
        this.setState({
          msg: (
            <Alert
              msg={xhr.getResponseHeader('X-TBMS-Message')}
              type="danger"
              closeHandler={() => {
                this.setState({ msg: '' });
              }}
            />
          ),
          loading: false,
          datalocation: null,
        });
      });
  }
  componentDidMount() {
    this.renderData();
    $('body').on('click', '.clearfilter', function(ev) {
      $('.clearfilterfrom').trigger('click');
    });
  }

  submitForm(ev) {
    ev.preventDefault();
    const formData = $(ev.target).serializeArray();
    this.props.history.push(AppendUrl(formData));
    this.renderData();
  }

  clearFilters(ev) {
    ev.preventDefault();
    this.props.history.push('/report-returns');
    this.renderData();
  }
  render() {
    const {
      loading,
      data,
      datalocation,
      msg,
      submitLoading,
      listStatus,
    } = this.state;
    return (
      <Box className="report-page">
        <div className="returns-report clearfix">
          <h2 className="text-center">Returns Report (next 2 weeks)</h2>
          <br />

          <Form
            className="filter row contract"
            submitHandler={e => this.submitForm(e)}
          >
            <FormGroup className="col-lg-2 col-sm-3">
              <DatePicker
                config={{
                  label: 'Return Date',
                  key: 'date',
                  default_value: GetArgs('date'),
                }}
              />
            </FormGroup>

            <FormGroup className="col-lg-2 col-sm-3">
              <Select
                config={{
                  label: 'Type',
                  key: 'f-type',
                  default_value: GetArgs('f-type'),
                  options: listStatus,
                }}
              />
            </FormGroup>

            <FormGroup className="col-lg-2 col-sm-3">
              <ModelSelect2js
                config={{
                  label: 'Modal',
                  key: 'bike_type_id',
                  default_value: GetArgs('bike_type_id'),
                  lookup_url: '/bike_type/list',
                  lookup_key: 'id',
                  lookup_format: '{model_name}',
                }}
              />
            </FormGroup>

            <FormGroup className="col-lg-2 col-sm-3">
              <Select2jsAjax
                config={{
                  label: 'Email',
                  key: 'customer_id',
                  default_value: GetArgs('customer_id'),
                  lookup_url: '/customer/get/text',
                  lookup_key: 'id',
                  lookup_format: '{email}({name})',
                }}
              />
            </FormGroup>

            <FormGroup className="col-lg-2 col-sm-3">
              <Input
                config={{
                  label: 'Numberplate',
                  key: 'numberplate',
                  default_value: GetArgs('numberplate'),
                }}
              />
            </FormGroup>

            <FormGroup className="col-lg-2 col-sm-3">
              <button
                type="submit"
                className="btn btn-primary btn-sm filteraction"
                disabled={submitLoading}
              >
                <i className="fa fa-filter" />{' '}
                {submitLoading ? 'Loading' : 'Filter'}
              </button>

              <button
                type="button"
                className="btn btn-primary btn-sm clearfilter"
                onClick={this.clearFilters}
              >
                {' '}
                {submitLoading ? 'Loading' : 'Clear Filter'}
              </button>
            </FormGroup>
          </Form>
          <Form
            submitHandler={e => this.clearFilters(e)}
            className="Contract Workshop filter col-sm-2 contract removeborder displaynone"
          >
            <FormGroup className="col-lg-12 col-sm-12">
              <button
                type="submit"
                className="btn btn-primary btn-sm clearfilterfrom"
              >
                {' '}
                {submitLoading ? 'Loading' : 'Clear Filter'}
              </button>
            </FormGroup>
          </Form>
          {msg && msg}
          {loading && (
            <div className="text-center">
              <div className="tgt-loader" />
            </div>
          )}
          {!loading && (
            <ReturnsRender data={data} datalocation={datalocation} />
          )}
        </div>
      </Box>
    );
  }
}
export default RReturns;
