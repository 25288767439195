import * as React from 'react';

import { FilterForm, Field } from '../../components/FinalForm';

const bikeTypeToOption = ({ id, model_name }) => ({
  value: id,
  label: model_name,
});

const BikeCheckinFilter = props => (
  <FilterForm {...props}>
    <Field
      label="Location"
      name="location_id"
      select={{ endpoint: '/location/list' }}
    />
    <Field
      label="Show Records"
      name="limit_past"
      defaultValue={3}
      select={{
        options: [
          { value: 3, label: 'Latest 3 days' },
          { value: 7, label: 'Latest 7 days' },
          { value: 30, label: 'Latest 30 days' },
          { value: 'none', label: 'All Records' },
        ],
      }}
    />
    <Field
      label="Model"
      name="bike_type_id"
      select={{
        endpoint: 'bike_type/list',
        toOption: bikeTypeToOption,
      }}
    />
    <Field name="numberplate" />
    <Field
      label="Ready For Hire"
      name="ready_for_hire"
      defaultValue="Yes"
      select={{
        options: [
          { value: 'Yes', label: 'Yes' },
          { value: 'all', label: 'Any' },
          { value: 'No', label: 'No' },
        ],
      }}
    />
  </FilterForm>
);

BikeCheckinFilter.defaultValues = {
  limit_past: 3,
  ready_for_hire: 'Yes',
};

export default BikeCheckinFilter;
