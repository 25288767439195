import { Api } from '../modules/Service';

const MContractRequestBike = {
  list: id => {
    return Api('/contract_request_bike/get/request/' + id);
  },
  add: data => {
    return Api(
      '/contract_request_bike/new',
      JSON.stringify(data),
      'POST',
      'text',
    );
  },
  update: data => {
    return Api(
      '/contract_request_bike/set/' + data.id,
      JSON.stringify(data),
      'POST',
      'text',
    );
  },
  delete: id => {
    return Api('/contract_request_bike/del/' + id, {}, 'GET', 'text');
  },
  get: id => {
    return Api('/contract_request_bike/get/' + id);
  },
};
export default MContractRequestBike;
