import { Api } from '../modules/Service';
import { filterObjNulls } from '../modules/Helper';

const MPayment = {
  title: 'Payments',
  list: ({ contract_id, contract_request_id }) => {
    const params = new URLSearchParams(
      filterObjNulls({
        contract_id,
        contract_request_id,
      }),
    );

    return Api(`/payment/list?${params}`);
  },
  add: data => Api('/payment/new', JSON.stringify(data), 'POST', 'text'),
  update: data =>
    Api(`/payment/set/${data.id}`, JSON.stringify(data), 'POST', 'text'),
  delete: id => Api(`/payment/del/${id}`, {}, 'GET', 'text'),
  get: id => Api(`/payment/get/${id}`),
};

export default MPayment;
