import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useOptions } from '../../modules/Hooks';
import { FilterForm, Field } from '../../components/FinalForm';
import MBikeType from '../../models/MBikeType';

const bikeTypeToOption = type => ({
  value: type.id,
  label: MBikeType.label(type, false),
});

const TransportFilters = props => {
  const match = useRouteMatch('/:report');
  const isPlanning = match.params.report === 'transport-plan';

  const locationOptions = useOptions('/location/list');
  const showOptions = isPlanning
    ? [
        { value: 'planned', label: 'Planned' },
        { value: 'allocated', label: 'Allocated' },
        { value: 'all', label: 'All' },
      ]
    : [
        { value: 'incomplete', label: 'Incomplete' },
        { value: 'complete', label: 'Completed' },
        { value: 'all', label: 'All' },
      ];

  return (
    <FilterForm {...props}>
      <Field
        label="Departure City"
        name="departure_location_id"
        select={{ options: locationOptions, isClearable: true }}
      />
      <Field
        label="Arrival City"
        name="arrival_location_id"
        select={{ options: locationOptions, isClearable: true }}
      />
      <Field
        name="state"
        select={{ options: showOptions }}
        defaultValue={showOptions[0].value}
      />
      {isPlanning && (
        <Field
          name="bike_type_id"
          select={{
            endpoint: '/bike_type/list',
            toOption: bikeTypeToOption,
            isClearable: true,
          }}
        />
      )}
    </FilterForm>
  );
};

export default TransportFilters;
