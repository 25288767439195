import * as React from 'react';
import { createSelector } from 'reselect';
import { identity } from 'lodash';
import { useField } from 'react-final-form';
import groupBy from 'lodash/fp/groupBy';

import MTransportPlanning from '../../models/MTransportPlanning';

import Table from '../../components/Table';
import { Modal } from '../../components/Modal';
import { EditForm, Field } from '../../components/FinalForm';
import { usePropState } from '../../modules/Hooks';

export const groupKey = plan =>
  [plan.departure_location_id, plan.arrival_location_id].join(':');

export const selectPlanGroups = createSelector(
  identity,
  groupBy(groupKey),
);

const SelectAll = ({ plans }) => {
  const field = useField('selected');
  const selectedPlans = React.useMemo(
    () =>
      field.input.value
        ? plans.filter(plan => field.input.value[`plan-${plan.id}`])
        : [],
    [plans, field.input.value],
  );

  const checked = selectedPlans.length === plans.length;
  const indeterminate = !checked && selectedPlans.length > 0;

  return (
    <div className="no-margin form-group checkbox icheck-like">
      <label>
        <input
          ref={checkbox => {
            if (checkbox) {
              checkbox.indeterminate = indeterminate;
            }
          }}
          type="checkbox"
          checked={checked}
          onChange={e => {
            if (e.target.checked) {
              field.input.onChange(
                Object.fromEntries(plans.map(({ id }) => [`plan-${id}`, true])),
              );
            } else {
              field.input.onChange({});
            }
          }}
        />
        <div className="styled-checkbox" />
      </label>
    </div>
  );
};

const MergeTransports = ({ columns, plan, plans, resolve, afterClose }) => {
  const [open, setOpen] = usePropState(Boolean(plan));
  const eligiblePlans = React.useMemo(
    () =>
      plan
        ? (selectPlanGroups(plans)[groupKey(plan)] || []).filter(
            ({ id }) => id !== plan.id,
          )
        : [],
    [plan, plans],
  );

  return (
    <Modal
      open={open}
      title="Merge Transport Plans"
      onClose={() => resolve && resolve()}
      afterClose={afterClose}
    >
      {plan && (
        <EditForm
          data={{ selected: {} }}
          onSubmit={values => {
            const selected = values.selected || {};

            const donorIds = eligiblePlans
              .map(({ id }) => id)
              .filter(id => selected[`plan-${id}`]);

            return MTransportPlanning.merge(plan.id, donorIds).then(() => {
              resolve({ refreshData: true });
              setOpen(false);
            });
          }}
          validate={values => {
            const selected = values.selected || {};
            if (!eligiblePlans.some(({ id }) => selected[`plan-${id}`])) {
              return { selected: 'Must select at least one plan' };
            }

            return undefined;
          }}
        >
          <div className="col-xs-12">
            <h4>Merging into:</h4>
            <Table
              data={plan ? [plan] : []}
              hideControls
              columns={columns.map(column => ({
                ...column,
                disableSort: true,
              }))}
            />
            <h4>Select plans to merge:</h4>
            <Table
              data={eligiblePlans}
              columns={[
                {
                  key: 'select',
                  disableSort: true,
                  headerStyle: { width: 50 },
                  label: <SelectAll plans={eligiblePlans} />,
                  cellRenderer: ({ row }) => (
                    <Field
                      label=""
                      name={`selected[plan-${row.id}]`}
                      type="checkbox"
                    />
                  ),
                },
                ...columns.filter(
                  ({ key }) =>
                    ![
                      'departure_location_name',
                      'arrival_location_name',
                    ].includes(key),
                ),
              ]}
            />
            <br />
          </div>
        </EditForm>
      )}
    </Modal>
  );
};

export default MergeTransports;
