import * as React from 'react';
import { useField } from 'react-final-form';

import { Field, validators } from '../../components/FinalForm';

const EmailSentField = props => {
  const changedSendDate = useField('review_email_send_date').meta.dirty;
  const emailSent = Boolean(useField('review_email_sent').input.value);

  return (
    <Field
      className="col-xs-12"
      label="Review Email Sent?"
      name="review_email_sent"
      type="checkbox"
      disabled
      {...props}
      checked={changedSendDate ? false : emailSent}
    />
  );
};

const ContractSpecificsWizardPage = ({ isCity }) => {
  const isFinished = Boolean(useField('return_date').input.value);
  const finishedCheckboxProps = isFinished
    ? {}
    : {
        readOnly: true,
        value: '',
      };

  const readonlyProps = useField('return_date').input.value
    ? { readOnly: true }
    : { readOnly: true, value: '' };

  const customer = useField('customer').input.value;

  return (
    <>
      <div className="col-sm-6">
        <Field
          name="release_bluecard"
          label="Bluecard Released?"
          type="checkbox"
        />
        <Field
          name="retained_cash_deposit"
          label="Cash Deposit Retained?"
          type="checkbox"
        />
        <Field
          label="Passport Retained?"
          name="retained_passport"
          type="checkbox"
        />
      </div>
      <div className="col-sm-6">
        <Field
          name="return_bluecard"
          label="Bluecard Returned?"
          type="checkbox"
          {...finishedCheckboxProps}
        />
        <Field
          name="return_cash_deposit"
          label="Cash Deposit Returned?"
          type="checkbox"
          {...finishedCheckboxProps}
        />
        <Field
          label="Passport Returned?"
          name="return_passport"
          type="checkbox"
          {...finishedCheckboxProps}
        />
      </div>
      <div className="col-sm-12">
        <div className="row">
          <Field
            className="col-sm-6"
            label="Number of Keys Released"
            name="release_keys_quantity"
            type="number"
            validate={validators.composeValidators(
              validators.required,
              validators.minValue(1),
              validators.integer,
            )}
            defaultValue={2}
          />
          <Field
            className="col-sm-6"
            label="Number of Keys Returned"
            name="return_keys_quantity"
            type="number"
            {...readonlyProps}
          />
        </div>
      </div>
      <div className="col-xs-12">
        <div className="row">
          <Field
            className="col-sm-6 col-md-4"
            name="contract_photo_id"
            type="photo"
          />
          <Field
            className="col-sm-6 col-md-4"
            name="damage_report_photo_id"
            type="photo"
          />
          <Field
            className="col-sm-6 col-md-4"
            name="crash_report_photo_id"
            type="photo"
          />
        </div>
      </div>
      <Field className="col-xs-12 col-md-6" name="notes" type="textarea" />
      <div className="col-xs-12 col-md-6">
        <div className="row">
          <Field
            className="col-xs-12"
            label="Send Review Email On..."
            name="review_email_send_date"
            datePicker
            disabled={!isFinished}
          />
          {customer && customer.disable_emails ? (
            <small className="col-xs-12 text-warning">
              <b>Warning:</b> All emails are disabled for this customer
            </small>
          ) : null}
          <EmailSentField className="col-xs-12" />
        </div>
      </div>
    </>
  );
};

export default ContractSpecificsWizardPage;
