import React from 'react';
import moment from 'moment';

export const YesNo = inp => {
  return (
    <div className="box-yesno">
      {inp ? (
        <span className="yes label label-success">Yes</span>
      ) : (
        <span className="no label label-danger">No</span>
      )}
    </div>
  );
};
export const Money = (num, x) => {
  return parseFloat(num / x).toFixed(2);
};
export const PermissionLevels = () => {
  return {
    0: 'Read Only',
    1: 'Workshop',
    5: 'Office',
    10: 'Admin',
    15: 'Super User',
  };
};
export const UserPermission = level => {
  const levels = PermissionLevels();
  return typeof levels[level] != 'undefined'
    ? levels[level] + ' (' + level + ')'
    : 'Unknown';
};
export const UniqueId = () => {
  return new Date().getTime();
};
export const DateTodayISO = () => {
  var now = new Date();

  var yyyy = now.getFullYear();

  var mm = now.getMonth() + 1;
  if (mm < 10) {
    mm = '0' + mm;
  }

  var dd = now.getDate();
  if (dd < 10) {
    dd = '0' + dd;
  }

  return yyyy + '-' + mm + '-' + dd;
};
export const TimeLocale = time => {
  var m = moment(time, 'YYYY-MM-DDTHH:mm:ss');
  m.add(moment().utcOffset(), 'minute');
  return m.format('DD MMM YYYY hh:mm a');
};
export const GetArgs = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return '';
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
export const AppendUrl = data => {
  var query = window.location.search;
  var queryObj = searchToObject(query);
  var newQuery = [];
  var newQueryObj = {};
  for (var i = 0; i < data.length; i++) {
    newQueryObj[data[i].name] = data[i].value;
    newQuery.push(data[i].name + '=' + data[i].value);
  }
  Object.keys(queryObj).forEach(function(key) {
    if (typeof newQueryObj[key] === 'undefined') {
      newQuery.unshift(key + '=' + queryObj[key]);
    }
  });
  var url = window.location.pathname;
  url +=
    url.indexOf('?') > -1 ? '&' + newQuery.join('&') : '?' + newQuery.join('&');
  return url;
};
export const searchToObject = () => {
  var pairs = window.location.search.substring(1).split('&'),
    obj = {},
    pair,
    i;
  for (i in pairs) {
    if (pairs[i] === '') continue;
    pair = pairs[i].split('=');
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }
  return obj;
};

export const isValidURL = string => {
  try {
    const url = new URL(string);
    return url.protocol.match(/^https?:$/);
  } catch (_) {
    return false;
  }
};

export const filterObjNulls = obj =>
  Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => value != null),
  );

export const xhrHelper = async xhr => {
  try {
    await xhr;
    return xhr.getResponseHeader('X-TBMS-New-ID');
  } catch (error) {
    throw new Error(xhr.getResponseHeader('X-TBMS-Message') || error.message);
  }
};
