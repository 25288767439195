import * as React from 'react';

import { EditForm, Field, validators } from '../../components/FinalForm';
import { useOptions } from '../../modules/Hooks';

export default props => {
  const locationOptions = useOptions('/location/list');

  return (
    <EditForm {...props}>
      <div className="col-sm-12">
        <Field
          title="Start City"
          name="start_location_id"
          validate={validators.required}
          disabled={Boolean(props.data.start_location_id)}
          select={{ options: locationOptions, isClearable: true }}
        />
        <Field
          title="Finish City"
          name="return_location_id"
          validate={validators.required}
          disabled={Boolean(props.data.return_location_id)}
          select={{ options: locationOptions, isClearable: true }}
        />
        <Field name="min_duration" defaultValue={0} type="number" />
        <Field
          title="Extra Cost"
          name="cost_route_extra"
          defaultValue={0}
          type="number"
        />
        <Field
          title="Bike Transport Cost"
          name="cost_bike_transport"
          defaultValue={0}
          type="number"
        />
        <Field title="Hide from Public" name="public_hide" type="checkbox" />
        <Field name="additional_info" type="textarea" rows={5} />
      </div>
    </EditForm>
  );
};
