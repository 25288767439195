import BaseModel from './BaseModel';

export default {
  ...BaseModel,

  endpoint: '/bike_transport',

  title: 'Transport',
  defaultListValues: { state: 'incomplete' },
  validListFilters: [
    'id',
    'departure_location_id',
    'arrival_location_id',
    'state',
  ],
};
