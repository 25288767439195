import React, { Component } from 'react';
import { Datatable } from '../../components/Datatable';
import Box from '../../components/Box';
export class ViewUserGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab2Data: [],
      loading: false,
    };
  }
  render() {
    const { data } = this.props;
    const { loading } = this.state;
    const thead = ['ID', 'Name'];
    var data1 = [data];
    return (
      <Box key="0" title="User Group">
        <Datatable loading={loading} thead={thead}>
          {data1 != null &&
            !loading &&
            data1.map((item, idx) => {
              const { id, name } = item;
              return (
                <tr key={idx} id={'dt-item-' + id}>
                  <td>{id}</td>
                  <td>{name}</td>
                </tr>
              );
            })}
        </Datatable>
      </Box>
    );
  }
}
