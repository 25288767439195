import React, { Component } from 'react';
import { InitISelect2js } from '../../modules/Event';
import { TimeLocale } from '../../modules/Helper';
import { Api } from '../../modules/Service';

class Select2js extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataselect: [],
      loading: true,
    };
  }
  componentDidMount() {
    const { loading } = this.state;
    const { lookup_url } = this.props.config;
    if (loading) {
      Api(lookup_url).done(resp => {
        this.setState({
          dataselect: resp,
          loading: false,
        });
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { dataselect } = this.state;
    const { key, select2Options } = this.props.config;
    if (dataselect != null) {
      InitISelect2js(key, select2Options);
    }
  }
  render() {
    const { loading, dataselect } = this.state;
    const { config, data } = this.props;
    const {
      label,
      key,
      lookup_key,
      lookup_format,
      required,
      default_value,
    } = config;
    const value = data != null ? data[key] : '';
    return [
      <label key="0">{label}</label>,
      <select
        key="1"
        name={key}
        id={'select2-' + key}
        data-value={value || default_value}
        required={required}
        className="form-control select2"
        defaultValue=""
      >
        {(() => {
          switch (true) {
            case loading:
              return (
                <option value="" disabled>
                  Loading...
                </option>
              );
            case dataselect.length > 0 && !loading:
              const retVal = [];
              retVal.push(
                <option key="0" value="">
                  select item
                </option>,
              );
              retVal.push(
                dataselect.map(item => {
                  const opttext = lookup_format.replace(
                    /{(.*?)}/g,
                    ($1, $2) => {
                      if ($2 === 'timestamp') {
                        item[$2] = TimeLocale(item[$2]);
                      }
                      return item[$2] != null ? item[$2] : '-';
                    },
                  );
                  return (
                    <option key={item[lookup_key]} value={item[lookup_key]}>
                      {opttext}
                    </option>
                  );
                }),
              );
              return retVal;
            default:
              return <option value="">Item not found</option>;
          }
        })()}
      </select>,
    ];
  }
}
export default Select2js;
