import * as React from 'react';
import { Link } from 'react-router-dom';

import MBikeType from '../../models/MBikeType';
import TypeForm from './TypeForm';
import List from '../../components/List';

export default () => (
  <List
    add
    edit
    delete
    model={MBikeType}
    editForm={TypeForm}
    columns={[
      'id',
      { label: 'Engine Capacity (cc)', key: 'engine_capacity' },
      { label: 'Manufacturer', key: 'manufacturer_name' },
      { label: 'Model', key: 'model_name' },
      { label: 'Transmission', key: 'transmission_name' },
      {
        key: 'bikes',
        disableSort: true,
        cellRenderer: ({ row }) => (
          <Link to={`/bikes?type=${row.id}`}>View</Link>
        ),
      },
    ]}
  />
);
