import React, { Fragment, Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Modules
import { Auth } from './modules/Middleware';

// Components
import Header from './components/Layouts/Header';
import Sidebar from './components/Layouts/Sidebar';
import Footer from './components/Layouts/Footer';

// config
import RouteList from './config.router';

const Page = ({ route, props }) => {
  React.useEffect(() => {
    $(window).trigger('resize'); // issues .content-wrapper height
  }, []);

  const title = [route.name, 'Tigit BMS'].filter(Boolean).join(' - ');

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <route.component {...props} />
    </Fragment>
  );
};

class Init extends Component {
  constructor(props) {
    super(props);
    Auth();
  }
  render() {
    return (
      <div>
        <div id="wrapper-body" className="wrapper">
          <Header />
          <Sidebar />
          <div className="content-wrapper">
            <main className="content">
              <Switch>
                {RouteList.map(route => (
                  <Route
                    key={route.url}
                    path={route.url}
                    exact={route.exact}
                    render={props => <Page route={route} props={props} />}
                  />
                ))}
              </Switch>
            </main>
          </div>
          <Footer />
        </div>
        <div className="auth-loader" />
      </div>
    );
  }
}

export default Init;
