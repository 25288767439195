import * as React from 'react';
import { Link } from 'react-router-dom';
import { useField } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import classnames from 'classnames';
import { keyBy, pick } from 'lodash';

import { Wizard, Field, validators } from '../../components/FinalForm';
import { useOptions } from '../../modules/Hooks';
import MContractRequest from '../../models/MContractRequest';
import MContractRequestBike from '../../models/MContractRequestBike';
import MBikeType from '../../models/MBikeType';
import MCustomer from '../../models/MCustomer';
import { AddBikes, BikeTable } from './ContractRequestBikeType';
import { xhrHelper } from '../../modules/Helper';
import { saveItem, saveChildren } from '../../modules/FormHelper';

const customerToEmailOption = ({ id, name, email }) => ({
  id,
  name,
  email,
  value: id,
  label: name ? `${name} (${email})` : email,
});

const EmailField = ({ options }) => {
  const customerID = useField('customer_id');
  const customerEmail = useField('customer_email');
  const customerName = useField('customer_name');

  const selectOption = option => {
    const { id, name, email } = option || {};

    customerID.input.onChange(id);
    customerName.input.onChange(name);
    customerEmail.input.onChange(email);
  };

  const emails = React.useMemo(() => keyBy(options, 'email'), [options]);

  const value =
    customerEmail.input.value &&
    customerToEmailOption({
      id: customerID.input.value,
      email: customerEmail.input.value,
      name: customerName.input.value,
    });

  return (
    <div className="col-md-6 col-md-offset-3 request-checkout-email-field">
      <Field
        name="customer_email"
        label="Email"
        select={{
          isClearable: true,
          creatable: true,
          options,
          value,
          onCreateOption: email => selectOption({ email }),
          onChange: selected => selectOption(selected),
          isValidNewOption: email => !emails[email] && !validators.email(email),
        }}
      />
      <Link
        className={classnames('btn btn-primary', { disabled: !value.id })}
        disabled={!value.id}
        target="_blank"
        title={value.label}
        to={value.id ? `/customers?customer=${value.id}` : '/customers'}
      >
        View
      </Link>
    </div>
  );
};

const idNameToOption = ({ id, name }) => ({ value: id, label: name });

const validateEmailPage = values => ({
  customer_email:
    validators.required(values.customer_email) ||
    (values.customer_id ? undefined : validators.email(values.customer_email)),
});

export default props => {
  const emailOptions = useOptions('/customer/list', customerToEmailOption);
  const locationOptions = useOptions('/location/list', idNameToOption);
  const bikeTypeOptions = useOptions('/bike_type/list', bikeType => ({
    value: bikeType.id,
    label: MBikeType.label(bikeType),
    bikeType,
  }));

  return (
    <Wizard {...props}>
      <Wizard.Page
        id="customer"
        icon="fa-user-o"
        title="Customer"
        validate={validateEmailPage}
        onSubmit={async ({ customer_email, customer_id }, form, updateData) => {
          if (customer_email && !customer_id) {
            try {
              updateData({
                customer_id: await xhrHelper(
                  MCustomer.add({ email: customer_email }),
                ),
              });
            } catch (error) {
              return {
                customer_email: error.message,
              };
            }
          }
        }}
      >
        <EmailField options={emailOptions} />
      </Wizard.Page>
      <Wizard.Page
        id="detail"
        icon="fa-newspaper-o"
        title="Request Detail"
        onSubmit={saveItem({
          model: MContractRequest,
          transform: (values, form) =>
            pick(values, ['id', 'customer_id', ...form.getRegisteredFields()]),
        })}
      >
        <div className="col-xs-12">
          <div className="row">
            <Field
              className="col-md-6"
              label="Date Range"
              name={['start_date', 'return_date_estimated']}
              validate={(value, allValues) => {
                if (!allValues.start_date) return 'Start Date required';
              }}
              dateRangePicker={{
                startDatePlaceholderText: 'Start Date',
                endDatePlaceholderText: 'Return Date (est.)',
              }}
            />
          </div>
        </div>

        <Field
          className="col-sm-6"
          label="Start Location"
          name="start_location_id"
          validate={validators.required}
          select={{ options: locationOptions }}
        />
        <Field
          className="col-sm-6"
          label="Return Location"
          name="return_location_id"
          select={{ options: locationOptions }}
        />

        <Field className="col-xs-12" type="textarea" name="notes" />
      </Wizard.Page>
      <Wizard.Page
        id="bikes"
        icon="fa-motorcycle"
        title="Bikes"
        before={() => <AddBikes options={bikeTypeOptions} />}
        onSubmit={saveChildren({
          parentModel: MContractRequest,
          parentChildKey: 'request_bikes',
          childModel: MContractRequestBike,
          childIdKey: 'bike_type_id',
          mapItem: (contract_request_id, { id, bike_type_id, quantity }) => ({
            id,
            contract_request_id,
            bike_type_id,
            quantity: Number(quantity),
          }),
        })}
      >
        <FieldArray
          name="request_bikes"
          validate={value =>
            value && value.length ? undefined : 'Must select at least one bike'
          }
        >
          {({ meta }) =>
            typeof meta.error === 'string' && (
              <b className="col-xs-12 text-danger">{meta.error}</b>
            )
          }
        </FieldArray>
        <BikeTable options={bikeTypeOptions} />
      </Wizard.Page>
    </Wizard>
  );
};
