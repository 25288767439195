import React, { Component } from 'react';
import Alert from '../../components/Alert';
import { Form, FormGroup } from '../../components/Form';
import { FormToObject } from '../../modules/Service';
import { GetModal } from '../../modules/Event';
import MUser from '../../models/MUser.js';
import Input from '../../components/Fields/Input';
import Hidden from '../../components/Fields/Hidden';
export class UserGroupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: '',
      submitLoading: false,
      access: {
        Test1: 'Test1',
        Test2: 'Test2',
      },
    };
  }

  componentDidMount() {
    setTimeout(function() {
      jQuery('input[name="status"]').append(
        '<div class="pull-right Photo_main" style="margin-top: 26px;"><a href="javascript:void(0)" id= "4" title="Add New" class="btn btn-sm btn-success addnewphonto"><i class="fa fa-plus"></i> Add New</a></div>',
      );
      $(document.body).on(
        'click',
        'a.btn.btn-sm.btn-success.addnewphonto',
        function(ev) {
          var numItems = $('.form-group.photo_upload').length;
          // var Items = parseInt(numItems);
          this.setState({ PhotoItem: parseInt(numItems) + 1 });

          jQuery('input[name="img_photo' + numItems + '"]')
            .parent()
            .after(
              '<div class="pull-right"><a href="javascript:void(0)" id="' +
                numItems +
                '" title="Add New" class="btn btn-sm btn-success removephonto"><i class="fa fa-minus"></i> Remove </a></div></div></div>',
            );
        }.bind(this),
      );
    }, 2000);
  }
  submitForm(ev) {
    ev.preventDefault();
    const { modalId, RefreshParentData } = this.props;
    this.setState({ submitLoading: true });
    const formData1 = FormToObject($(ev.target).serializeArray());

    var formData = {
      id: formData1.id,
      name: formData1.name,
      api_access: {
        '/bike/get/{id}': true,
        '/bike/new': true,
        '/bike/del/{id}': false,
        '/bike/set/{id}': true,
      },
      ui_access: { '/bikes': true },
    };
    if (!formData.id) {
      MUser.addGroup(formData)
        .done((resp, sts, xhr) => {
          GetModal(modalId).scrollTop(0);
          RefreshParentData();
          this.setState({
            submitLoading: false,
            msg: (
              <Alert
                msg="Group added successfully."
                type="success"
                closeHandler={() => {
                  this.setState({ msg: '' });
                }}
              />
            ),
          });
          this.setState({ submitLoading: false });
          $('.nav-tabs li:nth-child(2) a').trigger('click');
        })
        .catch(xhr => this.catchError(xhr));
    } else {
      MUser.updateGroup(formData)
        .done(resp => {
          localStorage.setItem(
            'departure_location',
            formData.departure_location_id,
          );
          GetModal(modalId).scrollTop(0);
          RefreshParentData();
          this.setState({
            submitLoading: false,
            msg: (
              <Alert
                msg="Group updated successfully."
                type="info"
                closeHandler={() => {
                  this.setState({ msg: '' });
                }}
              />
            ),
          });
          this.setState({ submitLoading: false });
          $('.nav-tabs li:nth-child(2) a').trigger('click');
        })
        .catch(xhr => this.catchError(xhr));
    }
  }
  catchError(xhr) {
    const { modalId } = this.props;
    GetModal(modalId).scrollTop(0);
    this.setState({
      submitLoading: false,
      msg: (
        <Alert
          msg={xhr.getResponseHeader('X-TBMS-Message')}
          type="danger"
          closeHandler={() => {
            this.setState({ msg: '' });
          }}
        />
      ),
    });
  }
  render() {
    const { submitLoading } = this.state;
    return (
      <div>
        <Form submitHandler={e => this.submitForm(e)} className="row">
          <FormGroup className="col-lg-3 col-sm-3">
            <Hidden config={{ label: '', key: 'id' }} />
            <Input config={{ label: 'Name', key: 'name' }} />
          </FormGroup>
          <FormGroup className="col-lg-2 col-sm-2checkinrow">
            <button
              type="submit"
              className="btn btn-primary btn-sm group-submit"
              disabled={submitLoading}
            >
              <i className="fa fa-filter" />{' '}
              {submitLoading ? 'Loading' : 'Submit'}
            </button>
          </FormGroup>
        </Form>
      </div>
    );
  }
}
