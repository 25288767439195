import React, { Component } from 'react';
import Cache from '../../modules/Cache';
import Box from '../../components/Box';
import MUser from '../../models/MUser';
import Alert from '../../components/Alert';
import { RenderForm } from '../../components/Form';
import { FormToObject } from '../../modules/Service';

class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg1: '',
      loading1: false,
      msg2: '',
      loading2: false,
      userData: Cache.get('userLogin'),
    };
  }
  submitForm1(ev) {
    ev.preventDefault();
    const { userData } = this.state;
    this.setState({ loading1: true });
    const formData = FormToObject($(ev.target).serializeArray());
    formData.id = userData.id;
    MUser.update(formData)
      .done(() => {
        MUser.get(userData.id).done(resp => {
          Cache.set('userLogin', resp);
          this.setState({
            loading1: false,
            msg1: (
              <Alert
                msg="Updated successfully."
                type="info"
                closeHandler={() => {
                  this.setState({ msg1: '' });
                }}
              />
            ),
          });
        });
      })
      .catch(xhr => this.catchError(xhr));
  }
  submitForm2(ev) {
    ev.preventDefault();
    const formData = FormToObject($(ev.target).serializeArray());
    var tempMsg = '';
    if (!formData.new_password) {
      tempMsg = (
        <Alert
          msg="New password empty !"
          type="warning"
          closeHandler={() => {
            this.setState({ msg2: '' });
          }}
        />
      );
    }
    if (formData.new_password !== formData.password_confirmation) {
      tempMsg = (
        <Alert
          msg="Password confirmation not match !"
          type="warning"
          closeHandler={() => {
            this.setState({ msg2: '' });
          }}
        />
      );
    }
    if (tempMsg) {
      this.setState({ msg2: tempMsg });
      return false;
    }
    this.setState({ loading2: true });
    MUser.setPassword(formData)
      .done(resp => {
        this.setState({
          loading2: false,
          msg2: (
            <Alert
              msg="Password changed successfully."
              type="info"
              closeHandler={() => {
                this.setState({ msg2: '' });
              }}
            />
          ),
        });
      })
      .catch(xhr => this.catchError(xhr));
  }
  catchError(xhr) {
    this.setState({
      loading1: false,
      loading2: false,
      msg2: (
        <Alert
          msg={xhr.getResponseHeader('X-TBMS-Message')}
          type="danger"
          closeHandler={() => {
            this.setState({ msg: '' });
          }}
        />
      ),
    });
  }
  render() {
    const { loading1, msg1, loading2, msg2, userData } = this.state;
    const fields1 = [
      {
        'col-sm-12 1': [
          {
            key: 'fullname',
            type: 'text',
            label: 'Full Name',
          },
          {
            key: 'email',
            type: 'email',
            label: 'Email',
          },
          {
            key: 'phonenumber',
            type: 'text',
            label: 'Phone Number',
          },
        ],
      },
    ];
    const fields2 = [
      {
        'col-sm-12 1': [
          {
            key: 'username',
            type: 'hidden',
          },
          {
            key: 'old_password',
            type: 'password',
            label: 'Current Password',
          },
          {
            key: 'new_password',
            type: 'password',
            label: 'New Password',
          },
          {
            key: 'password_confirmation',
            type: 'password',
            label: 'Confirm Password',
          },
        ],
      },
    ];
    return (
      <Box title="My Profile">
        <div className="row">
          <div className="col-sm-6">
            <h4>Profile data</h4>
            <RenderForm
              data={userData}
              msg={msg1}
              config={fields1}
              submitLoading={loading1}
              submitHandler={e => this.submitForm1(e)}
              wizardForm={true}
            />
          </div>
          <div className="col-sm-6">
            <h4>Change Password</h4>
            <RenderForm
              data={userData}
              msg={msg2}
              config={fields2}
              submitLoading={loading2}
              submitHandler={e => this.submitForm2(e)}
              wizardForm={true}
            />
          </div>
        </div>
      </Box>
    );
  }
}
export default MyProfile;
