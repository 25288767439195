/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import MInventory from '../../models/MInventory';
import Alert from '../../components/Alert';
import { UniqueId } from '../../modules/Helper';
import { RenderForm } from '../../components/Form';
import { FormToObject } from '../../modules/Service';
import { GetModal } from '../../modules/Event';
export class InventoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uniqueId: UniqueId(),
      msg: '',
      submitLoading: false,
    };
  }
  submitForm(ev) {
    ev.preventDefault();
    const { modalId, RefreshParentData } = this.props;
    this.setState({ submitLoading: true });
    const formData = FormToObject($(ev.target).serializeArray());
    if (formData.id == '') {
      MInventory.add(formData)
        .done(resp => {
          GetModal(modalId).scrollTop(0);
          RefreshParentData();
          this.setState({
            uniqueId: UniqueId(),
            submitLoading: false,
            msg: (
              <Alert
                msg="Inventory added successfully."
                type="success"
                closeHandler={() => {
                  this.setState({ msg: '' });
                }}
              />
            ),
          });
        })
        .catch(xhr => this.catchError(xhr));
    } else {
      MInventory.update(formData)
        .done(resp => {
          GetModal(modalId).scrollTop(0);
          RefreshParentData();
          this.setState({
            submitLoading: false,
            msg: (
              <Alert
                msg="Inventory updated successfully."
                type="info"
                closeHandler={() => {
                  this.setState({ msg: '' });
                }}
              />
            ),
          });
        })
        .catch(xhr => this.catchError(xhr));
    }
  }
  catchError(xhr) {
    let error = '';
    if (xhr.getResponseHeader('X-TBMS-Message') == null) {
      error = xhr.statusText;
    } else {
      error = xhr.getResponseHeader('X-TBMS-Message');
    }
    const { modalId } = this.props;
    GetModal(modalId).scrollTop(0);
    this.setState({
      submitLoading: false,
      msg: (
        <Alert
          msg={error}
          type="danger"
          closeHandler={() => {
            this.setState({ msg: 'sdfsfd' });
          }}
        />
      ),
    });
  }
  render() {
    const { submitLoading, msg, uniqueId } = this.state;
    const { data } = this.props;
    const fields = [
      {
        'col-sm-6 1': [
          {
            key: 'id',
            type: 'hidden',
          },
          {
            key: 'timestamp',
            type: 'hidden',
          },
          {
            key: 'stock_item_id',
            type: 'select2js',
            label: 'Stock Item',
            lookup_url: '/stock_item/list',
            lookup_key: 'id',
            lookup_format: '{brand} / {name}',
          },
          {
            key: 'supplier_id',
            type: 'select2js',
            label: 'Supplier',
            lookup_url: '/supplier/list',
            lookup_key: 'id',
            lookup_format: '{name}',
          },
          {
            key: 'purchase_cost_vnd_x100',
            type: 'number',
            label: 'Purchase Cost (VND)',
            num_multiplier: 0.01,
          },
          {
            key: 'other_cost_vnd_x100',
            type: 'number',
            label: 'Other Cost (VND)',
            num_multiplier: 0.01,
          },
        ],
      },
      {
        'col-sm-6 2': [
          {
            key: 'quantity_purchased',
            type: 'number',
            label: 'Quantity Purchased',
          },
          {
            key: 'quantity_gratis',
            type: 'number',
            label: 'Quantity Gratis',
          },
          {
            key: 'notes',
            type: 'textarea',
            label: 'Notes',
          },
        ],
      },
    ];
    return (
      <RenderForm
        key={uniqueId}
        data={data}
        msg={msg}
        config={fields}
        submitLoading={submitLoading}
        submitHandler={e => this.submitForm(e)}
      />
    );
  }
}
