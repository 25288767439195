import * as React from 'react';
import { Form, useFormState } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { get, keyBy, map } from 'lodash';

import MBikeType from '../../models/MBikeType';
import {
  validators,
  Field,
  useWizardPageValues,
  useFormSubmitting,
} from '../../components/FinalForm';
import { BtnDelete } from '../../components/Btn';
import Table from '../../components/Table';
import { useOptions } from '../../modules/Hooks';

const quantityFieldProps = {
  type: 'number',
  min: 1,
  step: 1,
  validate: validators.composeValidators(
    validators.minValue(1),
    validators.integer,
  ),
};

export const AddBikes = ({ bikeTypeOptions }) => {
  const { fields } = useFieldArray('bikes');
  const pages = useWizardPageValues();
  const locationId = get(pages, 'info.departure_location_id');

  const bikeOptions =
    useOptions(
      locationId && `/bike_location_report/get/location/${locationId}`,
      ({ bike }) => ({
        value: bike.id,
        label: `${bike.numberplate} / ${MBikeType.label(bike)}`,
        bike: { ...bike, bike_type_id: bike.type_id },
      }),
    ) || [];

  const bikesById = keyBy(map(bikeOptions, 'bike'), 'id');

  const requestedBikes = keyBy(get(pages, 'bikes.bikes', []), 'id');
  const filterTypes = get(pages, 'bikes.filterTypes', {});
  const shouldFilterByType = Object.values(filterTypes).find(Boolean);

  const filteredOptions = bikeOptions.filter(
    ({ value, bike }) =>
      !requestedBikes[value] &&
      (!shouldFilterByType || filterTypes[bike.type_id]),
  );

  return (
    <Form
      onSubmit={({ bike }) => bikesById[bike] && fields.push(bikesById[bike])}
    >
      {({ handleSubmit, invalid, values }) => (
        <form className="tgt-form bike-type-add-form" onSubmit={handleSubmit}>
          <Field
            className="bike-type-add-form--bike-type bike-type-add-form--bike"
            name="bike"
            select={{ options: filteredOptions }}
            disabled={!filteredOptions.length}
          />

          <button
            type="submit"
            className="btn btn-primary bike-type-add-form--submit"
            disabled={
              invalid ||
              !filteredOptions.length ||
              !values ||
              !values.bike ||
              requestedBikes[values.bike]
            }
          >
            Add
          </button>
        </form>
      )}
    </Form>
  );
};

export const AddBikeTypes = ({ bikeTypeOptions }) => {
  const { fields } = useFieldArray('bike_types');
  const formState = useFormState({ subscription: { values: true } });

  const requestedBikes = keyBy(
    formState.values.bike_types || [],
    'bike_type_id',
  );

  const filteredOptions = bikeTypeOptions.filter(
    ({ value }) => !requestedBikes[value],
  );

  return (
    <Form
      onSubmit={({ quantity, bike_type_id }) => {
        fields.push({ quantity, bike_type_id });
      }}
    >
      {({ handleSubmit, invalid, values }) => (
        <form className="tgt-form bike-type-add-form" onSubmit={handleSubmit}>
          <Field
            className="bike-type-add-form--quantity"
            name="quantity"
            label="Qty"
            initialValue={1}
            {...quantityFieldProps}
            disabled={!filteredOptions.length}
          />
          <Field
            className="bike-type-add-form--bike-type"
            name="bike_type_id"
            label="Bike Type"
            select={{ options: filteredOptions }}
            disabled={!filteredOptions.length}
          />

          <button
            type="submit"
            className="btn btn-primary bike-type-add-form--submit"
            disabled={
              invalid ||
              !filteredOptions.length ||
              !values.bike_type_id ||
              requestedBikes[values.bike_type_id]
            }
          >
            Add
          </button>
        </form>
      )}
    </Form>
  );
};

const useGetBikeTypeName = bikeTypeOptions =>
  React.useMemo(() => {
    const bikeTypesById = keyBy(bikeTypeOptions, 'value');

    return id =>
      bikeTypesById[id] ? bikeTypesById[id].label : 'Unknown Bike Type';
  }, [bikeTypeOptions]);

export const BikePlanTable = ({ bikeTypeOptions, transportPlanOptions }) => {
  const pages = useWizardPageValues();
  const planID = get(pages, 'info.bike_transport_plan_id');

  const getBikeTypeName = React.useMemo(() => {
    const bikeTypesById = keyBy(bikeTypeOptions, 'value');

    return id =>
      bikeTypesById[id] ? bikeTypesById[id].label : 'Unknown Bike Type';
  }, [bikeTypeOptions]);

  const transportPlansById = React.useMemo(
    () => keyBy(transportPlanOptions, 'value'),
    [transportPlanOptions],
  );

  const { plan } = (planID && transportPlansById[planID]) || {};

  if (!plan || !plan.bike_types || !plan.bike_types.length) return null;

  return (
    <div className="col-xs-12 tgt-table removeborder">
      <h3 style={{ marginTop: 0 }}>Plan</h3>
      <table className="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Filter</th>
            <th>Bike Type</th>
            <th>Quantity</th>
            <th>Assigned</th>
          </tr>
        </thead>
        <tbody>
          {plan.bike_types.map(({ quantity, bike_type_id }) => (
            <tr key={bike_type_id}>
              <td>
                <Field
                  name={`filterTypes.${bike_type_id}`}
                  label=""
                  type="checkbox"
                  defaultValue={true}
                />
              </td>
              <td>{getBikeTypeName(bike_type_id)}</td>
              <td>{quantity}</td>
              <td>
                {
                  get(pages, 'bikes.bikes', []).filter(
                    bike => bike.type_id === bike_type_id,
                  ).length
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h3>Bikes</h3>
    </div>
  );
};

export const BikeTable = ({ bikeTypeOptions, isPlanning }) => {
  const field = isPlanning ? 'bike_types' : 'bikes';
  const { fields } = useFieldArray(field);
  const { values } = useFormState({ subscription: { values: true } });
  const submitting = useFormSubmitting();

  const getBikeTypeName = useGetBikeTypeName(bikeTypeOptions);

  return (
    <Table
      className="col-xs-12 removeborder"
      getRowKey={row => row.id || row.bike_type_id}
      columns={[
        !isPlanning && 'id',
        !isPlanning && 'numberplate',
        {
          key: 'bike_type',
          toText: row => getBikeTypeName(row.bike_type_id),
        },
        isPlanning && {
          key: 'quantity',
          label: 'Qty',
          cellRenderer: ({ row, originalIndex }) => (
            <Field
              label={null}
              name={`${field}[${originalIndex}].quantity`}
              {...quantityFieldProps}
            />
          ),
        },
        {
          key: 'action',
          cellRenderer: ({ row, originalIndex }) => (
            <BtnDelete
              disabled={submitting}
              onClick={() => fields.remove(originalIndex)}
            />
          ),
        },
      ].filter(Boolean)}
      data={values[field]}
    />
  );
};
