import React, { Component } from 'react';
import { InitISelect3js } from '../../modules/Event2';
import { UniqueId } from '../../modules/Helper';
import { Api } from '../../modules/Service';
import Select2js from './Select2js';
class AffiliateSelect2js extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataselect: [],
      loading: true,
      uniqueId: UniqueId(),
    };
  }
  componentDidMount() {
    const { loading } = this.state;
    const { lookup_url } = this.props.config;
    if (loading) {
      Api(lookup_url).done(resp => {
        this.setState({
          dataselect: resp,
          loading: false,
        });
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { dataselect } = this.state;
    const { key } = this.props.config;
    if (dataselect != null) {
      InitISelect3js(key);
    }
  }
  render() {
    const { uniqueId } = this.state;
    const { data } = this.props;
    let affiliate_id = '';
    if (data != null) {
      affiliate_id = data.affiliate_id;
    }
    return (
      <div key={uniqueId}>
        <Select2js
          config={{
            label: 'Referal Company',
            key: 'affiliate_id',
            placeholder: 'select bike type',
            default_value: affiliate_id,
            lookup_url: '/affiliate/list',
            lookup_key: 'id',
            lookup_format: '{name}',
          }}
        />
      </div>
    );
  }
}
export default AffiliateSelect2js;
