/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import Nav from './Nav';
class Sidebar extends Component {
  render() {
    if ($(window).width() < 560) {
      $('body').on(
        'click',
        'ul.sidebar-menu li, ul.treeview-menu li',
        function() {
          if ($(this).hasClass('treeview') == false) {
            $('body').removeClass('sidebar-open');
          }
        },
      );
    }
    return (
      <aside className="main-sidebar">
        <section className="sidebar">
          <Nav />
        </section>
      </aside>
    );
  }
}
export default Sidebar;
