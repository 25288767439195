export default {
  items: [
    {
      name: 'Dashboard',
      url: '/',
      icon: 'fa fa-dashboard',
    },
    {
      name: 'Bikes',
      url: '#',
      icon: 'fa fa-motorcycle',
      children: [
        {
          name: 'Bikes',
          url: '/bikes',
          icon: 'fa  fa-motorcycle',
        },
        {
          name: 'Bikes (Deleted)',
          url: '/deleted-bikes',
          icon: 'fa  fa-motorcycle',
        },
        {
          name: 'Bike Checkins',
          url: '/bike-checkins',
          icon: 'fa fa-rocket',
        },
        {
          name: 'Bike Lookup',
          url: '/bike-lookup',
          icon: 'fa fa-map-marker',
        },
        {
          separate: true,
        },
        {
          name: 'Manufacturers',
          url: '/bike-manufacturers',
          icon: 'fa fa-hashtag',
        },
        {
          name: 'Models',
          url: '/bike-models',
          icon: 'fa fa-hashtag',
        },
        {
          name: 'Transmissions',
          url: '/bike-transmissions',
          icon: 'fa fa-hashtag',
        },
        {
          name: 'Types',
          url: '/bike-types',
          icon: 'fa fa-hashtag',
        },
      ],
    },
    {
      name: 'Customers',
      url: '#',
      icon: 'fa fa-address-book',
      children: [
        {
          name: 'Customers',
          url: '/customers',
          icon: 'fa fa-address-book',
        },
        {
          name: 'Contract Requests',
          url: '/contract-requests',
          icon: 'fa fa-bullhorn',
        },
        {
          name: 'Travel Contracts',
          url: '/contracts',
          icon: 'fa fa-book',
        },
        {
          name: 'City Rental',
          url: 'cityrental',
          icon: 'fa fa-building-o',
        },
      ],
    },
    {
      name: 'Referal Company',
      url: '/referal-company',
      icon: 'fa fa-book',
    },
    {
      name: 'Locations',
      url: '#',
      icon: 'fa fa-map-marker',
      children: [
        {
          name: 'Locations',
          url: '/locations',
          icon: 'fa fa-map-marker',
        },
        {
          name: 'Routes',
          url: '/routes',
          icon: 'fa fa-bullhorn',
        },
      ],
    },
    {
      name: 'Payments',
      url: '/payments',
      icon: 'fa fa-money',
    },
    {
      name: 'Inventory',
      url: '#',
      icon: 'fa fa-barcode',
      children: [
        {
          name: 'Inventory',
          url: '/inventory',
          icon: 'fa fa-barcode',
        },
        {
          name: 'Items',
          url: '/inventory-items',
          icon: 'fa fa-sliders',
        },
        {
          name: 'Suppliers',
          url: '/suppliers',
          icon: 'fa fa-share-alt',
        },
        {
          name: 'Category',
          url: '/category',
          icon: 'fa fa-share-alt',
        },
      ],
    },
    {
      name: 'Report',
      url: '#',
      icon: 'fa fa-bar-chart',
      children: [
        {
          name: 'Bike Location',
          url: '/report-bike-location',
          icon: 'fa fa-adjust',
        },
        {
          name: 'Returns',
          url: '/report-returns',
          icon: 'fa fa-random',
        },
        {
          name: 'Workshop',
          url: '/workshop',
          icon: 'fa fa-random',
        },
        {
          name: 'Multiple Active Contracts',
          url: '/active-contracts',
          icon: 'fa fa-random',
        },
        {
          name: 'Stock prediction',
          url: '/stock-prediction',
          icon: 'fa fa-random',
        },
      ],
    },
    {
      name: 'Users',
      url: '/',
      icon: 'fa fa-user-o',
      children: [
        {
          name: 'Users',
          url: '/users',
          icon: 'fa fa-user-o',
        },
        {
          name: 'User Groups',
          url: '/users-group',
          icon: 'fa fa-user-o',
        },
      ],
    },

    {
      name: 'Transport',
      url: '#',
      icon: 'fa fa-truck',
      children: [
        {
          name: 'Transport type',
          url: '/transport-type',
          icon: 'fa fa-adjust',
        },
        {
          name: 'Transport Plan',
          url: '/transport-plan',
          icon: 'fa fa-random',
        },
        {
          name: 'Transport',
          url: '/transport',
          icon: 'fa fa-truck',
        },
      ],
    },
    {
      name: 'Logout',
      url: '/logout',
      icon: 'fa fa-sign-out',
    },
  ],
};
