import * as React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

export const Btn = ({
  children,
  clickHandler,
  onClick,
  linkTo,
  title,
  type = 'default',
  loading,
  disabled,
  className,
}) =>
  React.createElement(
    linkTo ? Link : 'button',
    {
      to: linkTo,
      onClick: onClick || clickHandler,
      title,
      disabled: disabled || loading,
      className: classnames(
        `btn btn-sm`,
        {
          loading,
          disabled: disabled || loading,
        },
        type && `btn-${type}`,
        className,
      ),
      type: 'button',
    },
    children,
  );

export const BtnAdd = props => (
  <Btn title="Add New" type="success" {...props}>
    <i className="fa fa-plus" /> Add New
  </Btn>
);

export const BtnParts = props => (
  <Btn title="Waiting for parts" type="success" {...props}>
    Waiting for parts
  </Btn>
);

export const BtnAdd3 = props => (
  <Btn title="Add New Customer" type="success" {...props}>
    <i className="fa fa-plus" /> Add Customer
  </Btn>
);

export const CustomerEditBtn = props => (
  <Btn title="Edit Customer" type="success" {...props}>
    <i className="fa fa-plus" /> Edit Customer
  </Btn>
);

export const BtnEdit = props => (
  <Btn title="Edit item" type="primary" {...props}>
    <i className="fa fa-edit" />
  </Btn>
);

export const BtnContract = props => (
  <Btn title="Update Contract" type="primary" {...props}>
    <i className="fa fa-edit" />
  </Btn>
);

export const BikeUndeleteBtn = props => (
  <Btn title="Undelete" type="success" {...props}>
    Undelete
  </Btn>
);

export const BikeComBtn = props => (
  <Btn title="Record New Communication" type="envelope" {...props}>
    <i className="fa fa-envelope" /> <i className="fa fa-plus" />
  </Btn>
);

export const BtnHire = props => (
  <Btn title="Ready For Hire" type="primary" {...props}>
    Ready For Hire
  </Btn>
);

export const BtnWorkshop = props => (
  <Btn title="Workshop" type="primary" {...props}>
    Workshop
  </Btn>
);

export const BtnWorkshopPriority = props => (
  <Btn title="Workshop" type="primary" {...props}>
    Workshop (Urgent)
  </Btn>
);

export const ViewNote = ({ visible, ...props }) => {
  const message = `${visible ? 'Hide' : 'Show'} Notes`;

  return (
    <Btn title={message} type="primary" {...props}>
      {message}
    </Btn>
  );
};

export const BtnCon = props => (
  <Btn title="Confirm" type="primary" {...props}>
    Confirm
  </Btn>
);

export const BtnDelete = props => (
  <Btn title="Delete item" type="danger" {...props}>
    <i className="fa fa-trash-o" />
  </Btn>
);
export const BtnView = props => (
  <Btn title="View item" type="info" {...props}>
    <i className="fa fa-eye" />
  </Btn>
);
export const BtnSetPass = props => (
  <Btn title="Set password" type="default" {...props}>
    <i className="fa fa-key" />
  </Btn>
);
