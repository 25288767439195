/* eslint-disable eqeqeq */

import React, { Component } from 'react';

class Username extends Component {
  render() {
    const { config, data } = this.props;
    const { label, key, required, className } = config;
    const value = data != null ? data[key] : '';
    return [
      <label key="1">{label}</label>,
      <input
        key="2"
        type="text"
        name={key}
        readOnly={value != ''}
        defaultValue={value}
        required={required}
        className={'form-control ' + (className || '')}
      />,
    ];
  }
}
export default Username;
