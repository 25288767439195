/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import { AppendUrl, GetArgs } from '../../modules/Helper';
import { Form, FormGroup } from '../../components/Form';
import Select2js from '../../components/Fields/Select2js';
import Checkbox from '../../components/Fields/Checkbox';
import Input from '../../components/Fields/Input';
import Select from '../../components/Fields/Select';
import Hidden from '../../components/Fields/Hidden';
export class BikeFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitLoading: false,
      listLimitType: {
        older: 'Older than year',
        newer: 'Newer than year',
      },
      listLimitdistance: {
        less_than: 'Less than',
        greater_than: 'Greater than',
      },

      listLimitYear: this.years(),
    };
  }
  componentDidMount() {
    $('body').on('click', '.clearfilter', function(ev) {
      $('.clearfilterfrom').trigger('click');
    });
    setTimeout(function() {
      jQuery('body').on(
        'keyup',
        'div.dataTables_wrapper div.dataTables_filter input',
        function(ev) {
          jQuery('input[name="data-table-search"]').val(jQuery(this).val());
        },
      );
    }, 1500);
  }
  submitForm(ev) {
    ev.preventDefault();
    const { parentThis } = this.props;
    var formData = $(ev.target).serializeArray();
    formData = formData.filter(item => {
      if (item.name == 'f-ready_for_hire' && item.value == 'false') {
        return formData.filter(itm => {
          return itm.name == 'f-ready_for_hire' && itm.value == 'true';
        }).length > 0
          ? false
          : true;
      }
      return true;
    });
    parentThis.props.history.push(AppendUrl(formData));
  }

  years(startYear) {
    var years = {};
    startYear = startYear || 1980;

    while (startYear <= 2050) {
      var Year = startYear++;
      // years.test = test;
      years[Year] = Year;
    }

    return years;
  }

  clearFilters(ev) {
    ev.preventDefault();
    const { parentThis } = this.props;
    parentThis.props.history.push('/bikes');
  }
  render() {
    const {
      submitLoading,
      listLimitType,
      listLimitYear,
      listLimitdistance,
    } = this.state;
    return (
      <div>
        <Form submitHandler={e => this.submitForm(e)} className="filter row">
          <FormGroup className="col-lg-2 col-sm-3">
            <Select2js
              config={{
                label: 'Model',
                key: 'bike_type_id',
                default_value: GetArgs('bike_type_id'),
                lookup_url: '/bike_type/list',
                lookup_key: 'id',
                lookup_format: '{model_name}',
              }}
            />
          </FormGroup>

          <FormGroup className="col-lg-2 col-sm-3">
            <Select
              config={{
                label: 'Distance(in KM)',
                key: 'distance',
                default_value: GetArgs('distance'),
                options: listLimitdistance,
              }}
            />
          </FormGroup>

          <FormGroup className="col-lg-2 col-sm-3">
            <Input
              config={{
                label: 'Kilometer ',
                key: 'km',
                default_value: GetArgs('km'),
              }}
            />
          </FormGroup>

          <FormGroup className="col-lg-2 col-sm-3">
            <Select
              config={{
                label: 'Model Type',
                key: 'f-model_type',
                default_value: GetArgs('f-model_type'),
                options: listLimitType,
              }}
            />
          </FormGroup>

          <FormGroup className="col-lg-2 col-sm-3">
            <Select
              config={{
                label: 'Motorbike Year',
                key: 'f-year',
                default_value: GetArgs('f-year'),
                options: listLimitYear,
              }}
            />
            <Hidden
              config={{
                label: 'Search',
                key: 'data-table-search',
                default_value: GetArgs('data-table-search'),
              }}
            />
          </FormGroup>

          <FormGroup className="col-lg-2 col-sm-3">
            <label>Ready for Hire ?</label>
            <br />
            <Checkbox
              config={{
                label: '',
                key: 'f-ready_for_hire',
                default_value: GetArgs('f-ready_for_hire') == 'true',
              }}
            />
          </FormGroup>
          <FormGroup className="col-lg-2 col-sm-3">
            <button
              type="submit"
              className="btn btn-primary btn-sm"
              disabled={submitLoading}
            >
              <i className="fa fa-filter" />{' '}
              {submitLoading ? 'Loading' : 'Filter'}
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm clearfilter"
            >
              {' '}
              {submitLoading ? 'Loading' : 'Clear Filter'}
            </button>
          </FormGroup>
        </Form>
        <Form
          submitHandler={e => this.clearFilters(e)}
          className="ContratRequest filter row contract removeborder right displaynone"
        >
          <FormGroup className="col-lg-2 col-sm-3">
            <button
              type="submit"
              className="btn btn-primary btn-sm clearfilterfrom"
            >
              {' '}
              {submitLoading ? 'Loading' : 'Clear Filter'}
            </button>
          </FormGroup>
        </Form>{' '}
      </div>
    );
  }
}
