import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { AppendUrl, GetArgs } from '../../modules/Helper';
import { Form, FormGroup } from '../../components/Form';
import Select2js from '../../components/Fields/Select2js';
import Input from '../../components/Fields/Input';

class BikeLocationFilter extends Component {
  submitForm(ev) {
    ev.preventDefault();
    const formData = $(ev.target).serializeArray();
    this.props.history.push(AppendUrl(formData));
  }

  clearFilters(ev) {
    ev.preventDefault();
    this.props.history.push('/report-bike-location');
    jQuery("input[name='bike_numberplate']").val('');
    jQuery('select[name=bike_type_id]')
      .val(null)
      .trigger('change');
  }

  render() {
    const { submitLoading } = this.props;
    return (
      <div>
        <Form submitHandler={e => this.submitForm(e)} className="filter row">
          <FormGroup className="col-lg-2 col-sm-3" />
          <FormGroup className="col-lg-2 col-sm-3" />
          <FormGroup className="col-lg-2 col-sm-3">
            <Input
              config={{
                label: 'Numberplate',
                key: 'bike_numberplate',
                default_value: GetArgs('bike_numberplate'),
              }}
            />
          </FormGroup>

          <FormGroup className="col-lg-2 col-sm-3">
            <Select2js
              config={{
                label: 'Model',
                key: 'bike_type_id',
                default_value: GetArgs('bike_type_id'),
                lookup_url: '/bike_type/list',
                lookup_key: 'id',
                lookup_format: '{model_name}',
                select2Options: {
                  allowClear: true,
                },
              }}
            />
          </FormGroup>

          <FormGroup className="col-lg-2 col-sm-3 checkinrow">
            <button
              type="submit"
              className="btn btn-primary btn-sm"
              disabled={submitLoading}
            >
              <i className="fa fa-filter" />{' '}
              {submitLoading ? 'Loading' : 'Filter'}
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm clearfilter"
              disabled={submitLoading}
              onClick={e => this.clearFilters(e)}
            >
              {submitLoading ? 'Loading' : 'Clear Filters'}
            </button>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

export default withRouter(BikeLocationFilter);
