import * as React from 'react';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';

import MContract from '../../models/MContract';
import MBikeType from '../../models/MBikeType';
import List from '../../components/List';
import { getFullURL } from '../../modules/Service';
import ContractFilters from './ContractFilter';
import ContractForm from './ContractForm';
import ChangeBike from './ChangeBikeForm';
import ContractFinish from './ContractFinish';

export const useFinishing = () => {
  const [finishing, setFinishing] = React.useState(null);

  const { contractId: finishContractId } = useParams();
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    if (finishContractId) {
      const xhr = MContract.get(finishContractId);

      xhr.then(contract => {
        new Promise(resolve => {
          setFinishing(prev => {
            if (prev) {
              prev.resolve();
            }

            return { contract, resolve };
          });
        });
      });

      return () => xhr.abort();
    }
  }, [finishContractId]);

  return [
    finishing,
    nextFinishing => {
      setFinishing(nextFinishing);

      if (!nextFinishing && location.pathname.includes('finish')) {
        history.replace(
          [location.pathname.replace(/\/finish.*/, ''), location.search]
            .filter(Boolean)
            .join('?'),
        );
      }
    },
  ];
};

const Contracts = () => {
  const [swapping, setSwapping] = React.useState(null);
  const [finishing, setFinishing] = useFinishing();

  return (
    <>
      <List
        model={MContract}
        add
        edit
        delete
        filters={ContractFilters}
        editForm={ContractForm}
        numActionsPerRow={4}
        modalSize="lg"
        beforeActions={[
          ({ row }) =>
            !row.return_date && {
              id: 'finish-contract',
              title: 'Finish Contract',
              onClick: () =>
                new Promise(resolve =>
                  setFinishing({ contract: row, resolve }),
                ),
              type: 'success',
              icon: 'fa-check white',
            },
          ({ row }) =>
            !row.return_date && {
              id: 'change-bike',
              type: 'warning',
              title: 'Swap Bike',
              icon: 'fa-motorcycle',
              onClick: () =>
                new Promise(resolve => setSwapping({ contract: row, resolve })),
            },
        ]}
        columns={[
          { key: 'user_username', label: 'Created By' },
          {
            key: 'start_date_and_location',
            label: 'Start Date & Location',
            toText: row => `${row.start_date} (${row.start_location_name})`,
          },
          {
            key: 'return_date_and_location',
            label: 'Return Date & Location',
            toText: row =>
              `${row.return_date_estimated} (${row.return_location_name})`,
          },
          {
            key: 'contract_end_date',
            toText: row =>
              row.return_date == null ? 'Not Finished' : row.return_date,
          },
          {
            key: 'customer',
            toText: row => row.customer_email,
            cellRenderer: ({ row }) => (
              <Link to={`/customers?customer=${row.customer_id}`}>
                {row.customer_email}
              </Link>
            ),
          },
          {
            key: 'Bike',
            toText: row => `${row.bike_numberplate} (${MBikeType.label(row)})`,
          },
          { key: 'return_location_firm', type: 'check' },
          { key: 'return_date_firm', type: 'check' },
          {
            key: 'contract_photo_id',
            disableSearch: true,
            cellRenderer: ({ cell }) =>
              cell != null && (
                <a
                  href={getFullURL(`/photo/get/${cell}`)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="thumbnail"
                    alt="contract"
                    width={100}
                    height="auto"
                    src={getFullURL(`/photo/get/100px/${cell}`)}
                  />
                </a>
              ),
          },
          { key: 'timestamp', type: 'datetime' },
        ]}
      />
      {finishing && (
        <ContractFinish {...finishing} afterClose={() => setFinishing(null)} />
      )}
      {swapping && (
        <ChangeBike {...swapping} afterClose={() => setSwapping(null)} />
      )}
    </>
  );
};

export default Contracts;
