import * as React from 'react';

import { FilterForm, Field } from '../../components/FinalForm';

export default props => (
  <FilterForm {...props}>
    <Field
      name="is_hidden"
      label="Location Types"
      placeholder="Current Locations"
      select={{
        options: [
          { value: 'true', label: 'Deleted Locations' },
          { value: 'all', label: 'All Locations' },
        ],
        isClearable: true,
      }}
    />
  </FilterForm>
);
