import { pick, keyBy, isEqual } from 'lodash';
import { FORM_ERROR } from 'final-form';

import { xhrHelper } from './Helper';

const defaultTransform = (values, form) =>
  pick(values, ['id', ...form.getRegisteredFields()]);

export const saveItem = ({
  model,
  transform = defaultTransform,
  method,
}) => async (allValues, form, updateData) => {
  const values = transform(allValues, form);

  try {
    if (method) {
      await xhrHelper(model[method](values));
    } else if (values.id) {
      await xhrHelper(model.update(values));
    } else {
      const id = await xhrHelper(model.add(values));
      if (updateData) updateData({ id });
    }
  } catch (error) {
    return {
      [FORM_ERROR]: error.message,
    };
  }
};

export const saveChildren = ({
  parentModel,
  parentChildKey,
  childModel,
  childIdKey,
  childValuesIdKey = childIdKey,
  mapItem,
}) => async (values, form, updateData) => {
  const id = parentModel.getId ? parentModel.getId(values) : values.id;
  const currentItems = (await parentModel.get(id))[parentChildKey];

  const prevItems = keyBy(currentItems, childIdKey);
  const nextItems = keyBy(values[parentChildKey] || [], childValuesIdKey);

  const deletedItems = Object.values(prevItems)
    .filter(item => !nextItems[item[childIdKey]])
    .map(item => mapItem(id, item));

  const addedItems = Object.values(nextItems)
    .filter(item => !prevItems[item[childIdKey]])
    .map(item => mapItem(id, item));

  const updatedItems = Object.values(prevItems)
    .map(item => {
      if (!childModel.update || !nextItems[item[childIdKey]]) return null;

      const prev = mapItem(id, item);
      const next = mapItem(id, nextItems[item[childIdKey]]);

      if (isEqual(prev, next)) return null;

      return {
        ...prev,
        ...next,
      };
    })
    .filter(Boolean);

  const ids = {};

  try {
    await Promise.all([
      ...deletedItems.map(item =>
        xhrHelper(
          childModel.delete(
            childModel.getId ? childModel.getId(item) : item.id,
          ),
        ),
      ),
      ...updatedItems.map(item => xhrHelper(childModel.update(item))),
      ...addedItems.map(async item => {
        ids[item[childIdKey]] = await xhrHelper(childModel.add(item));
      }),
    ]);

    updateData({
      [parentChildKey]: (values[parentChildKey] || []).map(item => ({
        id: ids[item[childIdKey]],
        ...item,
      })),
    });
  } catch (error) {
    return { [FORM_ERROR]: error.message };
  }
};
