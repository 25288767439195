import React, { Component } from 'react';
import { InitIcheck } from '../../modules/Event';
class Checkbox extends Component {
  componentDidMount() {
    InitIcheck();
  }
  render() {
    const { config, data } = this.props;
    const { label, key, checked, required, className, default_value } = config;
    var ischecked = data != null ? data[key] : checked;
    if (ischecked === undefined) {
      ischecked = default_value;
    }
    return (
      <label>
        <input type="hidden" name={key} value="false" />
        <input
          type="checkbox"
          name={key}
          required={required}
          defaultValue="true"
          defaultChecked={ischecked}
          className={'form-control icheck ' + className}
        />{' '}
        {label}
      </label>
    );
  }
}
export default Checkbox;
