import { Api } from './Service';
import Cache from './Cache';

export const Auth = () => {
  const refreshSession = 2 * 60 * 1000; // 2 mins
  Api('/auth/ping', {}, 'GET').done(resp => {
    $('#app-body').addClass('loaded');
    Cache.set('userLogin', resp);
  });
  setInterval(() => {
    Api('/auth/ping', {}, 'GET').done(resp => {
      Cache.set('userLogin', resp);
    });
  }, refreshSession);
};
