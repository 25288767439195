/* eslint-disable eqeqeq */

export const ApiUrl = process.env.REACT_APP_API_URL;
export const getFullURL = (endpoint, api = ApiUrl) =>
  `${api}/${endpoint}`.replace(
    /(\w)\/+(\w?)/g,
    (match, left, right) => `${left}/${right}`,
  );

export const Api = (endpoint, myData, myMethod, myType) => {
  myData = typeof myData === 'undefined' ? {} : myData;
  myMethod = typeof myMethod === 'undefined' ? 'GET' : myMethod;
  myType = typeof myType === 'undefined' ? 'JSON' : myType;
  return $.ajax({
    url: getFullURL(endpoint),
    data: myData,
    method: myMethod,
    dataType: myType,
    cache: false,
    crossDomain: true,
    xhrFields: {
      withCredentials: true,
    },
    error: errorHanler,
  });
};
export const ApiUpload = (endpoint, myData, myMethod) => {
  myMethod = typeof myMethod === 'undefined' ? 'POST' : myMethod;
  return $.ajax({
    type: myMethod,
    url: getFullURL(endpoint),
    data: myData,
    contentType: false,
    processData: false,
    dataType: 'text',
    cache: false,
    crossDomain: true,
    xhrFields: {
      withCredentials: true,
    },
    error: errorHanler,
  });
};
const errorHanler = (jqXHR, textStatus, errorThrown) => {
  switch (jqXHR.status) {
    case 504:
      alert('Gateway time out! Please refresh your browser');
      break;
    case 500:
      console.log('Internal server error !');
      // alert('Internal server error !');
      break;
    case 0: // aborted
    case 404:
      // alert('Data not found !');
      break;
    case 401:
      const pathname = window.location.pathname;
      if (pathname !== '/login') {
        window.location.replace(
          '/login?redirect=' + pathname + window.location.search,
        );
      }
      break;
    default:
      // $("#tgt-root").html(textStatus);
      console.log('unknown error :' + jqXHR.status);
      break;
  }
};
export const FormToObject = formData => {
  const Obj = {};
  try {
    formData.forEach(item => {
      const { name, value } = item;
      switch (true) {
        case value == '' && name == 'id':
          Obj[name] = '';
          break;
        case value == 'true' || value == 'false':
          Obj[name] = JSON.parse(value);
          break;
        case value === '':
          Obj[name] = null;
          break;
        default:
          Obj[name] = value;
          break;
      }
    });
    return Obj;
  } catch (err) {
    console.log(err.message);
  }
};
