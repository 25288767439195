import * as React from 'react';
import { keyBy, startCase } from 'lodash';
import { useField } from 'react-final-form';

import { Field, validators } from '../../components/FinalForm';
import { useOptions } from '../../modules/Hooks';

const customerToEmailOption = customer =>
  customer &&
  customer.email && {
    value: customer,
    label: customer.name
      ? `${customer.name} (${customer.email})`
      : customer.email,
  };

const SearchField = () => {
  const customerField = useField('customer');
  const originalCustomerField = useField('originalCustomer');

  const option = customerToEmailOption(customerField.input.value);
  const readonly = Boolean(useField('id').input.value);

  const options = useOptions(
    !readonly && '/customer/list',
    customerToEmailOption,
  );

  const emails = React.useMemo(
    () => (readonly ? [option] : keyBy(options, 'customer.email')),
    [readonly, options, option],
  );

  const changeCustomer = customer =>
    [customerField, originalCustomerField].forEach(field => {
      field.input.onChange(customer);
    });

  return (
    <Field
      className="col-md-8 col-md-offset-2"
      name="customer"
      readOnly={readonly}
      select={{
        isClearable: !readonly,
        creatable: !readonly,
        options: readonly ? [] : options,
        value: option,
        onCreateOption: email => changeCustomer({ email }),
        isValidNewOption: email => !emails[email] && !validators.email(email),
        onChange: selected =>
          changeCustomer(selected ? selected.value : undefined),
      }}
    />
  );
};

const CustomerField = ({ name, ...props }) => (
  <Field label={startCase(name)} name={`customer.${name}`} {...props} />
);

const ContractCustomerWizardPage = () => (
  <>
    <SearchField />
    <h5 className="col-xs-12">
      <b>Note:</b>{' '}
      <i>The following fields will directly edit the customer's record</i>
    </h5>
    <CustomerField className="col-sm-6" name="name" />
    <CustomerField className="col-sm-6" name="phone_number" />
  </>
);

export default ContractCustomerWizardPage;
