import * as React from 'react';
import { useForm } from 'react-final-form';

import { FilterForm, Field } from '../../components/FinalForm';

const locationToOption = ({ id, name }) => ({ value: id, label: name });

const bikeTypeToOption = ({ id, model_name }) => ({
  value: id,
  label: model_name,
});

const LocationField = () => {
  const form = useForm();

  return (
    <Field
      name="start_location_id"
      label="Show Records"
      select={{
        isClearable: true,
        endpoint: '/location/list',
        toOption: locationToOption,
        onChange: () => {
          form.submit();
        },
      }}
    />
  );
};

const stateOptions = [
  { value: 'planned', label: 'Planned' },
  { value: 'allocated', label: 'Allocated' },
  { value: 'all', label: 'All' },
];

export default props => (
  <FilterForm {...props}>
    <Field label="Date Range" name={['from_date', 'to_date']} dateRangePicker />
    <LocationField />
    <Field
      name="state"
      select={{ options: stateOptions }}
      defaultValue={stateOptions[0].value}
    />
    <Field
      name="bike_type_id"
      label="Model"
      select={{
        isClearable: true,
        endpoint: '/bike_type/list',
        toOption: bikeTypeToOption,
      }}
    />
  </FilterForm>
);
