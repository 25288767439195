import * as React from 'react';

export default props => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 619.885 258.509"
    enableBackground="new 0 0 619.885 258.509"
    {...props}
  >
    <title>Tigit Motorbikes</title>
    <g>
      <g>
        <g>
          <path
            fill="#ffffff"
            d="M33.978,11.992L46.55,26.704c18.959-10.746,43.469-8.059,59.62,8.087
				c19.423,19.395,19.423,50.865,0.006,70.26c-19.391,19.405-50.845,19.405-70.252,0c-15.66-15.662-18.683-39.174-9.051-57.858
				L12.022,34.652C-3.848,61.161-0.379,96.008,22.459,118.84c26.953,26.953,70.647,26.953,97.584,0.011
				c26.948-26.94,26.953-70.645,0-97.575C96.677-2.114,60.699-5.172,33.978,11.992z M160.483,21.063h40.082V138.23h22.275V21.063
				h40.081V1.955H160.483V21.063z M282.141,138.23h22.274V1.955h-22.274V138.23z M393.313,83.419h28.232v35.14
				c-8.198,1.809-15.899,2.703-23.115,2.703c-14.286,0-25.241-4.336-32.854-13.002c-7.612-8.667-11.418-21.328-11.418-37.98
				c0-15.849,4.225-28.337,12.675-37.472c8.452-9.136,19.948-13.701,34.489-13.701c11.055,0,22.121,2.546,33.187,7.644l7.822-18.55
				C429.599,2.733,416.117,0,401.885,0c-22.12,0-39.496,6.196-52.107,18.593c-12.61,12.399-18.922,29.566-18.922,51.5
				c0,22.246,5.594,39.476,16.781,51.687c11.176,12.208,27.086,18.315,47.716,18.315c8.772,0,16.925-0.563,24.471-1.684
				c7.548-1.113,15.424-3.006,23.633-5.68V64.317h-50.143V83.419z M473.808,138.23h22.273V1.955h-22.273V138.23z M515.189,1.955
				v19.108h40.082V138.23h22.275V21.063h40.082V1.955H515.189z M88.507,70.049c0-9.514-7.724-17.25-17.25-17.25
				c-9.527-0.006-17.243,7.724-17.243,17.246c0,9.533,7.716,17.248,17.238,17.263C80.783,87.301,88.501,79.578,88.507,70.049z"
          />
        </g>
      </g>
      <g>
        <path
          fill="#ffffff"
          d="M34.367,257.441L5.239,185.284H4.811c0.285,4.419,0.429,8.944,0.429,13.575v58.582H0v-78.145h8.444
			l27.688,68.738h0.321l27.688-68.738h8.231v78.145h-5.508v-59.223c0-4.133,0.218-8.409,0.642-12.828h-0.424l-29.239,72.051H34.367z
			"
        />
        <path
          fill="#ffffff"
          d="M157.225,218.261c0,12.401-3.101,22.21-9.302,29.426c-6.198,7.215-14.749,10.822-25.656,10.822
			c-10.866,0-19.399-3.607-25.6-10.822c-6.202-7.216-9.304-17.06-9.304-29.533c0-12.434,3.127-22.225,9.384-29.369
			c6.254-7.144,14.794-10.717,25.627-10.717c10.906,0,19.437,3.59,25.604,10.77C154.141,196.019,157.225,205.827,157.225,218.261z
			 M93.298,218.261c0,11.191,2.494,19.84,7.482,25.951c4.99,6.111,12.152,9.166,21.486,9.166c9.408,0,16.597-3.027,21.568-9.086
			c4.97-6.059,7.458-14.734,7.458-26.031c0-11.259-2.488-19.891-7.458-25.895c-4.972-6.005-12.124-9.008-21.461-9.008
			c-9.299,0-16.47,3.029-21.513,9.088C95.819,198.503,93.298,207.111,93.298,218.261z"
        />
        <path
          fill="#ffffff"
          d="M190.195,257.441h-5.505v-73.012h-25.39v-5.133h56.285v5.133h-25.391V257.441z"
        />
        <path
          fill="#ffffff"
          d="M287.476,218.261c0,12.401-3.1,22.21-9.301,29.426c-6.199,7.215-14.752,10.822-25.652,10.822
			c-10.87,0-19.404-3.607-25.606-10.822c-6.197-7.216-9.3-17.06-9.3-29.533c0-12.434,3.126-22.225,9.382-29.369
			c6.254-7.144,14.798-10.717,25.63-10.717c10.9,0,19.438,3.59,25.6,10.77C284.396,196.019,287.476,205.827,287.476,218.261z
			 M223.549,218.261c0,11.191,2.496,19.84,7.483,25.951c4.99,6.111,12.152,9.166,21.49,9.166c9.404,0,16.594-3.027,21.563-9.086
			c4.974-6.059,7.458-14.734,7.458-26.031c0-11.259-2.484-19.891-7.458-25.895c-4.97-6.005-12.122-9.008-21.458-9.008
			c-9.3,0-16.475,3.029-21.513,9.088C226.071,198.503,223.549,207.111,223.549,218.261z"
        />
        <path
          fill="#ffffff"
          d="M307.887,223.392v34.049h-5.451v-78.145h18.603c9.691,0,16.853,1.79,21.485,5.373
			c4.632,3.58,6.948,8.971,6.948,16.168c0,5.238-1.382,9.657-4.143,13.256c-2.762,3.6-6.958,6.183-12.588,7.75l21.221,35.598h-6.52
			l-20.152-34.049H307.887z M307.887,218.691h14.914c6.594,0,11.706-1.471,15.339-4.41c3.636-2.939,5.452-7.278,5.452-13.016
			c0-5.949-1.782-10.281-5.345-12.988c-3.563-2.708-9.371-4.063-17.424-4.063h-12.937V218.691z"
        />
        <path
          fill="#ffffff"
          d="M365.076,179.296h21.434c9.656,0,16.748,1.641,21.272,4.918c4.525,3.279,6.788,8.232,6.788,14.859
			c0,4.526-1.379,8.294-4.143,11.305c-2.761,3.012-6.798,4.943-12.105,5.8v0.32c6.233,0.927,10.824,2.896,13.764,5.906
			s4.408,7.206,4.408,12.589c0,7.197-2.388,12.736-7.162,16.621c-4.776,3.884-11.543,5.826-20.309,5.826h-23.947V179.296z
			 M370.528,214.093h17.21c7.342,0,12.678-1.221,16.011-3.662c3.329-2.44,4.996-6.262,4.996-11.465c0-5.201-1.88-8.979-5.639-11.331
			c-3.76-2.352-9.362-3.528-16.809-3.528h-15.77V214.093z M370.528,218.904v33.727h18.39c14.466,0,21.697-5.879,21.697-17.637
			c0-10.727-7.625-16.09-22.877-16.09H370.528z"
        />
        <path
          fill="#ffffff"
          d="M431.431,257.441v-78.145h5.449v78.145H431.431z"
        />
        <path
          fill="#ffffff"
          d="M508.137,257.441h-6.682l-29.985-41.263l-10.423,9.192v32.07h-5.452v-78.145h5.452v40.623l8.659-8.66
			l30.628-31.963h6.947l-32.017,33.033L508.137,257.441z"
        />
        <path
          fill="#ffffff"
          d="M560.898,257.441h-42.706v-78.145h42.706v5.025h-37.255v29.558h35.173v5.024h-35.173v33.513h37.255
			V257.441z"
        />
        <path
          fill="#ffffff"
          d="M619.885,237.291c0,6.521-2.394,11.688-7.188,15.5c-4.795,3.814-11.16,5.719-19.107,5.719
			c-9.551,0-16.872-1.05-21.969-3.152v-5.453c5.632,2.389,12.811,3.582,21.542,3.582c6.413,0,11.498-1.47,15.26-4.41
			c3.759-2.939,5.638-6.796,5.638-11.571c0-2.958-0.625-5.407-1.87-7.35c-1.249-1.943-3.278-3.715-6.095-5.319
			c-2.813-1.603-6.949-3.329-12.398-5.184c-7.982-2.744-13.497-5.71-16.545-8.9c-3.045-3.188-4.568-7.437-4.568-12.748
			c0-5.843,2.288-10.609,6.867-14.297c4.582-3.688,10.468-5.532,17.667-5.532c7.341,0,14.233,1.391,20.685,4.169l-1.978,4.705
			c-6.487-2.708-12.686-4.063-18.601-4.063c-5.773,0-10.371,1.337-13.789,4.011c-3.422,2.67-5.133,6.306-5.133,10.902
			c0,2.887,0.525,5.256,1.576,7.109c1.052,1.854,2.772,3.52,5.159,4.996c2.387,1.479,6.485,3.252,12.292,5.318
			c6.093,2.104,10.672,4.145,13.737,6.121c3.063,1.977,5.3,4.223,6.706,6.734C619.184,230.689,619.885,233.727,619.885,237.291z"
        />
      </g>
    </g>
  </svg>
);
