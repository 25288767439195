import * as React from 'react';

const priorities = {
  1: {
    title: 'Urgent',
    iconClassnames: 'text-danger fa fa-exclamation-circle',
    className: 'text-danger',
    number: 1,
  },
  2: {
    title: 'High',
    iconClassnames: 'text-warning fa fa-arrow-circle-up',
    number: 2,
  },
  3: {
    title: 'Normal',
    iconClassnames: 'text-success fa fa-minus-circle',
    number: 3,
  },
  4: { title: 'Low', iconClassnames: 'fa fa-arrow-circle-down', number: 4 },
};

const BikeCheckinPriority = ({ value, includeTitle = false }) => {
  const priority = priorities[value];

  if (!priority) return null;

  const { title, iconClassnames } = priority;

  return (
    <React.Fragment>
      <i className={iconClassnames} title={title} />
      {includeTitle ? ` ${title}` : ''}
    </React.Fragment>
  );
};

export const column = {
  label: 'Priority',
  key: 'priority_numeric',
  toText: row => {
    const priority = priorities[row.priority_numeric];
    return priority ? `${priority.title} (${priority.number})` : '';
  },
  cellRenderer: ({ cell, row }) => <BikeCheckinPriority value={cell} />,
};

export const options = [1, 2, 3, 4].map(value => ({
  value,
  label: <BikeCheckinPriority value={value} includeTitle />,
}));

export default BikeCheckinPriority;
