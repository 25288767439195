import { Api } from '../modules/Service';
import { GetArgs } from '../modules/Helper';
const MReport = {
  bikeLocation: () => {
    var url = '/bike_location_report/summary';
    const bike_numberplate = GetArgs('bike_numberplate');
    const bike_type = GetArgs('bike_type_id');
    var query = [];
    if (bike_numberplate) query.push('bike_numberplate=' + bike_numberplate);
    if (bike_type) query.push('type_id=' + bike_type);
    return Api(url + '?' + query.join('&'));
  },
  returns: params => {
    let limit;
    if (params !== '') {
      limit = 14;
    }
    if (GetArgs('date') !== '') {
      limit = 0;
    } else {
      limit = 14;
    }
    const model = GetArgs('bike_type_id');

    return Api(
      '/contract/list?' +
        params +
        '&state=running&by=return_estimated&limit_future=' +
        limit +
        '&bike_type_id=' +
        model +
        '&order=asc',
    );
  },
  upcomingRentals: () => {
    return Api('/contract_request/list');
  },
  confrimReturn: id => {
    return Api('/contract/finish/' + id, 'POST', 'text');
  },
  ActiveContracts: () => {
    return Api('/bike_multiuse_report/list');
  },
  stockPrediction: ({ limit_future = 30, date }) => {
    const search = new URLSearchParams();
    search.set('date', date);
    search.set('limit_future', limit_future);

    return Api(
      ['/bike_availability_report/summary', search.toString()]
        .filter(Boolean)
        .join('?'),
    );
  },
};
export default MReport;
